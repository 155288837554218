import React from 'react'
import PropTypes from 'prop-types'

import ErrorDialog from '../../../components/commons/error-dialog/ErrorDialog'

import './PromotionsErrorDialog.css'
import i18n from '../../../utils/i18n/i18n'

function getSkuEffectValue(sku, commissionRules) {
  const promoCommissionRule = commissionRules.filter(commissionRule => commissionRule.sku === sku)
  return promoCommissionRule[0].effect.value
}

function getConflictErrorData(errorIdentifier) {
  const errors = errorIdentifier.split('|')
  return {
    sku: errors[0],
    conflictCampaign: errors[1],
    conflictStartDate: errors[2],
    conflictEndDate: errors[3]
  }
}

const PromotionsErrorDialog = (props) => {

  const {
    isOpen, onClose, dialogId, errors, commissionRules
  } = props

  const errorStartDateInPast = errors.filter(error => error.field === 'campaign.startDateInPast')
  const errorInvalidDuration = errors.filter(error => error.field === 'campaign.invalidDuration')
  const errorEndDateBeforeStart = errors.filter(error => error.field === 'campaign.endDateBeforeStart')
  const errorPromoSkuDuplicated = errors.filter(error => error.field === 'promoSku.duplicated')
  const errorPromoSkuConflicted = errors.filter(error => error.field === 'promoSku.conflicted')

  return (
    <ErrorDialog
      title={i18n.t(`${errors.length > 1 ? 'Problems' : 'Problem'} on create Promotion Grid`)}
      open={isOpen}
      onClose={() => onClose()}
      id={dialogId}
      icon="icon-warning2"
    >
      <div id="error-introduction" className="error-text error-node">
        {i18n.t(`The following ${errors.length > 1 ? 'problems' : 'problem'} must be resolved to create the new promotion grid.`)}
      </div>

      {errorStartDateInPast.length > 0 && (
        <div id="error-campaign-date-past" className="error-title error-group">
          {i18n.t('The PROMOTION START DATE must not be less than the CURRENT DATE.')}
        </div>
      )}

      {errorInvalidDuration.length > 0 && (
        <div id="error-campaign-invalid-duration" className="error-title error-group">
          {i18n.t('The promotion max allowed time is six months.')}
        </div>
      )}

      {errorEndDateBeforeStart.length > 0 && (
        <div id="error-campaign-end-before" className="error-title error-group">
          {i18n.t('The PROMOTION END DATE is less than START DATE.')}
        </div>
      )}

      {errorPromoSkuDuplicated.length > 0 && (
        <div id="error-promo-sku-duplicated" className="error-group">
          <div className="error-title">{i18n.t('The following SKUs are duplicates.')}</div>
          <div className="error-promo-sku-list">
            <div className="error-promo-sku-list-header">
              <div className="item-sku">
                {`SKU (${errorPromoSkuDuplicated.length})`}
              </div>
            </div>
            <div className="error-promo-sku-list-items">
              {errorPromoSkuDuplicated.map((error) => {
                const sku = error.identifier
                return (
                  <div className="error-promo-sku-list-item" key={sku}>
                    <div className="item-sku">
                      <span className="item-sku-value">{sku}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}

      {errorPromoSkuConflicted.length > 0 && (
        <div id="error-promo-sku-conflicted" className="error-group">
          <div className="error-title conflict-error-message">
            {i18n.t('The following SKUs are in another promotional commission in the same selected period.')}
          </div>
          <div className="error-title conflict-error-message">
            {i18n.t('So that there is no competition of values, one same SKU can not be in the same period or inside another.')}
          </div>
          <div className="error-text conflict-error-message">
            <strong>{i18n.t('Please, remove from the list the following SKUs before continue.')}</strong>
          </div>
          <div className="error-promo-sku-list">
            <div className="error-promo-sku-list-header">
              <div className="item-sku">
                {`SKU (${errorPromoSkuConflicted.length})`}
              </div>
              <div className="item-period">
                {i18n.t('Period')}
              </div>
              <div className="item-campaign">
                {i18n.t('Promotional Action')}
              </div>
            </div>
            <div className="error-promo-sku-list-items">
              {errorPromoSkuConflicted.map((error) => {
                const {
                  sku, conflictCampaign, conflictStartDate, conflictEndDate
                } = getConflictErrorData(error.identifier)
                const promoSkuEffectValue = getSkuEffectValue(sku, commissionRules)

                return (
                  <div className="error-promo-sku-list-item" key={error.identifier}>
                    <div className="item-sku">
                      <span className="item-sku-value">{sku}</span>
                      <span className="item-sku-effect">{`${promoSkuEffectValue}%`}</span>
                    </div>
                    <div className="item-period">
                      <span className="item-period-from">{`${i18n.t('from')} ${conflictStartDate}`}</span>
                      <span className="item-period-to">{`${i18n.t('to')} ${conflictEndDate}`}</span>
                    </div>
                    <div className="item-campaign">
                      {conflictCampaign}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </ErrorDialog>
  )
}

PromotionsErrorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogId: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  })),
  commissionRules: PropTypes.arrayOf(PropTypes.shape({
    promoSku: PropTypes.string,
    effect: PropTypes.shape({
      value: PropTypes.number
    })
  })).isRequired
}

export default PromotionsErrorDialog