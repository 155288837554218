import React from 'react'

/** components */
import Title from './title/Title'
import Breadcrumb from '../breadcrumb/Breadcrumb'

export default function Header() {
  const locationPathName = window.location.pathname
  return (
    <div className="header">
      <Title />
      <Breadcrumb locationPathName={locationPathName} />
    </div>
  )
}
