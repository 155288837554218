import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../utils/i18n/i18n'

import './PlansGridInfo.css'
import { CredentialManager } from '../../../../commons/CredentialManager'

const createPlanButton = openPlanDialog => (
  <span className="table-plans-info-options-add" onClick={openPlanDialog}>
    <i className="icon-add" />
    {i18n.t('Create Plan')}
  </span>
)
/**
 * **************************************************************************************
 * Renders Plans Grid Info
 * This component renders the info of the plans table
 * **************************************************************************************
*/
function PlansGridInfo(props) {
  const { actual, total, openPlanDialog } = props

  const hasRoleCreatePlan = CredentialManager.userHasRole('ROLE_CREATE_PLAN')

  return (
    <div className="plans-grid">
      <div className="plans-grid-header">
        { hasRoleCreatePlan && createPlanButton(openPlanDialog) }
        { hasRoleCreatePlan && <span className="table-plans-info-options-separator">.</span> }
        <div className="table-plans-info-summary">
          {` ${i18n.t('Results')}: `}
          <span className="text-bold" id="actual_results">{actual}</span>
          {` ${i18n.t('Out of')} `}
          <span className="text-bold" id="total_results">{total}</span>
        </div>
      </div>
    </div>
  )
}
PlansGridInfo.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  openPlanDialog: PropTypes.func.isRequired
}

export default PlansGridInfo