import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../components/form-components/select/Select'
import i18n from '../../utils/i18n/i18n'

/**
 * **************************************************************************************
 * Render a select with auditTypes options - <AuditTypeSelect>
 * **************************************************************************************
 */
const AuditTypeSelect = (props) => {
  const {
    id, auditTypes, value, onChangeValue
  } = props

  const options = auditTypes.map(reason => ({ value: reason.auditTypeName, text: reason.name }))

  return (
    <Select
      id={id}
      value={value}
      options={options}
      onChangeValue={onChangeValue}
      placeholder={i18n.t('Select audit type')}
    />
  )
}

AuditTypeSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  auditTypes: PropTypes.arrayOf(PropTypes.shape({
    auditTypeName: PropTypes.string,
    name: PropTypes.string
  })),
  onChangeValue: PropTypes.func
}

AuditTypeSelect.defaultProps = {
  auditTypes: []
}

export default AuditTypeSelect