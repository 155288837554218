import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CurrencyInput from 'react-currency-input'

import './InputPercentage.css'

const MAX_VALUE = 100.0

/**
 * **************************************************************************************
 * Render a input with percentage mask - <PercentageField>
 * **************************************************************************************
 */
export class InputPercentage extends Component {
  constructor(props) {
    super(props)
    this.handleCurrencyInput = this.handleCurrencyInput.bind(this)
  }

  handleCurrencyInput(event, maskedValue, receivedValue) {
    const { id, onChangeValue } = this.props

    const { value } = this.props
    const shouldUpdate = receivedValue <= MAX_VALUE

    if (shouldUpdate) {
      onChangeValue(id, receivedValue)
    } else {
      onChangeValue(id, value)
    }
  }

  render() {
    const {
      id,
      className,
      label,
      value
    } = this.props

    return (
      <div className={`wrap-input-text ${className}`}>
        <label htmlFor={id}>{label}</label>
        <div className="input-box">
          <CurrencyInput
            id={id}
            value={value}
            decimalSeparator=","
            onChangeEvent={this.handleCurrencyInput}
            precision={2}
          />
          <span className="percentage-icon">%</span>
        </div>
      </div>
    )
  }
}

InputPercentage.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  onChangeValue: PropTypes.func
}

export default InputPercentage