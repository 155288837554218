import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import InputText from '../../../../components/form-components/InputText'
import Select from '../../../../components/form-components/select/Select'
import InputTextArea from '../../../../components/form-components/input-text-area/InputTextArea'
import i18n from '../../../../utils/i18n/i18n'
import InputPercentage from '../../../../components/form-components/input-percentage/InputPercentage'

import StepFooter from './StepFooter'

import './StepPlan.css'

class StepPlan extends Component {

  constructor(props) {
    super(props)
    const { currentPlan } = this.props
    this.state = {
      stepPlan: currentPlan,
      isValid: false
    }

    bindAll(this, ['onChangeValue', 'onChangeName', 'isNextStepEnabled'])
  }

  componentWillMount() {
    this.setState({
      isValid: this.isNextStepEnabled()
    })
  }

  onChangeValue(key, value) {
    const { stepPlan } = this.state
    const { updatePlan } = this.props

    stepPlan[key] = value

    updatePlan({ stepPlan })

    this.setState({
      stepPlan
    }, () => {
      this.setState({
        isValid: this.isNextStepEnabled()
      })
    })
  }

  onChangeName(event) {
    const { value } = event.target
    this.onChangeValue('name', value)
  }

  isNextStepEnabled() {
    const { stepPlan } = this.state
    return (
      stepPlan !== undefined && stepPlan.name !== undefined && stepPlan.name.trim() !== ''
      && stepPlan.area != null
    )
  }

  render() {
    const { isValid, stepPlan } = this.state
    const { jumpToStep, closeAction } = this.props

    const areaOptions = [
      { text: 'Hunting', value: 'HUNTING' },
      { text: 'Farming', value: 'FARMING' },
      { text: 'Marketing', value: 'MARKETING' },
      { text: 'Outros', value: 'OTHERS' }
    ]

    function PlanNameAlert() {
      return (
        <span className="plan-name-info">
          <i className="icon-info" />
          {i18n.t('Grids with the same name are not allowed')}
        </span>
      )
    }

    return (
      <Fragment>
        <div>
          <div className="form-group">
            <PlanNameAlert />
            <InputText
              id="name"
              value={stepPlan.name || ''}
              placeholder={i18n.t('Enter the name of the new grid')}
              label={i18n.t('Grid Name')}
              isMandatory
              maxLength={70}
              onChange={this.onChangeName}
            />
          </div>

          <div className="form-group">
            <div className="col area pull-left">
              <Select
                id="area"
                placeholder={i18n.t('Select the area')}
                label={i18n.t('Requesting Area')}
                options={areaOptions}
                value={stepPlan.area}
                isMandatory
                onChangeValue={this.onChangeValue}
              />
            </div>
            <div className="col default-rule pull-right">
              <InputPercentage
                id="defaultRule"
                value={stepPlan.defaultRule}
                label={i18n.t('Standard Plan Commission')}
                onChangeValue={this.onChangeValue}
              />
            </div>
          </div>

          <div className="form-group">
            <InputTextArea
              id="description"
              className="details"
              placeholder={i18n.t('Enter details here to help others understand this grid.')}
              label={i18n.t('Details')}
              onChangeValue={this.onChangeValue}
              value={stepPlan.description}
            />
          </div>
        </div>
        <StepFooter
          currentStepIdex={0}
          jumpToStep={jumpToStep}
          closeAction={closeAction}
          isFirst
          isValid={isValid}
        />
      </Fragment>
    )
  }
}

export default StepPlan
StepPlan.propTypes = {
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  updatePlan: PropTypes.func,
  currentPlan: PropTypes.shape({
    name: PropTypes.string,
    area: PropTypes.string,
    defaultRule: PropTypes.number,
    description: PropTypes.string
  })
}