import React, { Component } from 'react'
import { bindAll } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import BillingAdjustmentInsertDialog from './billing-adjustment-insert-dialog/BillingAdjustmentInsertDialog'
import BillingAdjustmentGrid from './billing-adjustment-grid/BillingAdjustmentGrid'
import getBillingAdjustments from '../../services/api/billing-adjustments/BillingAdjustmentAction'
import BillingAdjustmentDescriptionDialog from './billing-adjustment-description/BillingAdjustmentDescriptionDialog'
import Closable from '../../components/closable/Closable'
import i18n from '../../utils/i18n/i18n'

import './BillingAdjustment.css'

/** Tab styles */
const styles = () => ({
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    color: '#156992',
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#156992'
  },
  tabRoot: {},
  label: {
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: [
      'RobotoCondensed'
    ].join(',')
  }
})
class BillingAdjustment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      billingAdjustments: [],
      total: 0,
      openDialogDescription: false,
      openDialogInsert: false,
      currentSortingField: 'billingAdjustmentId',
      currentSortingDirection: 'DESC',
      hasMoreItems: true,
      selectedBillingAdjustment: {},
      showBillingAdjustmentSuccessMessage: false
    }

    bindAll(this, ['getBillingAdjustmentList', 'updateBillingAdjustments', 'setBillingAdjustments', 'onSorting', 'onOpenDescription', 'onOpenInsert',
      'onClose', 'onCloseInsertDialog', 'closeBillingAdjustmentSuccessMessage', 'openBillingAdjustmentSuccessMessage'])
  }

  componentDidMount() {
    this.getBillingAdjustmentList(0)
  }

  getBillingAdjustmentList(page) {
    const { currentSortingField, currentSortingDirection } = this.state
    const params = {
      limit: 10
    }
    params.offset = page || 0
    params.sortField = currentSortingField
    params.sortDirection = currentSortingDirection

    getBillingAdjustments(params).then((response) => {
      this.updateBillingAdjustments(response, params.offset)
    })
  }

  updateBillingAdjustments(response, page) {
    if (response && response.billingAdjustmentList && response.paging) {
      const newBillingAdjustments = []
      if (page === 0) {
        this.setState({
          billingAdjustments: []
        }, () => {
          response.billingAdjustmentList.map(billingAdjustment => newBillingAdjustments.push(billingAdjustment))
          this.setBillingAdjustments(newBillingAdjustments, response.paging.total)
        })
      } else {
        const { billingAdjustments } = this.state
        billingAdjustments.map(billingAdjustment => newBillingAdjustments.push(billingAdjustment))
        response.billingAdjustmentList.map(billingAdjustment => newBillingAdjustments.push(billingAdjustment))
        this.setBillingAdjustments(newBillingAdjustments, response.paging.total)
      }
    }
  }

  setBillingAdjustments(billingAdjustments, total) {
    this.setState({
      billingAdjustments,
      hasMoreItems: (billingAdjustments.length < total),
      total
    })
  }

  onSorting(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.getBillingAdjustmentList(0)
    })
  }

  onOpenDescription(adjustment) {
    this.setState({
      openDialogDescription: true,
      selectedBillingAdjustment: adjustment
    })
  }

  onOpenInsert() {
    this.setState({
      openDialogInsert: true
    })
  }

  onClose() {
    this.setState({
      openDialogDescription: false
    })
  }

  onCloseInsertDialog() {
    this.setState({
      openDialogInsert: false
    })
  }

  closeBillingAdjustmentSuccessMessage() {
    this.setState({
      showBillingAdjustmentSuccessMessage: false
    })
  }

  openBillingAdjustmentSuccessMessage() {
    this.setState({
      showBillingAdjustmentSuccessMessage: true
    }, () => {
      setTimeout(() => { this.getBillingAdjustmentList(0) }, 1500)
    })
  }

  render() {
    const {
      billingAdjustments,
      hasMoreItems,
      currentSortingField,
      currentSortingDirection,
      total,
      openDialogDescription,
      openDialogInsert,
      selectedBillingAdjustment,
      showBillingAdjustmentSuccessMessage
    } = this.state

    return (
      <div className="content billing-adjusment">
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={showBillingAdjustmentSuccessMessage}
            onClose={this.closeBillingAdjustmentSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="billing-adjustment-save-success">
              <i className="icon-ok-check" />
              {i18n.t('Billing adjustment inserted successfully.')}
            </span>
          </Closable>
        </section>
        <BillingAdjustmentGrid
          billingAdjustments={billingAdjustments}
          hasMore={hasMoreItems}
          loadItems={this.getBillingAdjustmentList}
          onSorting={this.onSorting}
          currentSortingField={currentSortingField}
          currentSortingDirection={currentSortingDirection}
          total={total}
          onOpenDescription={this.onOpenDescription}
          onOpenInsert={this.onOpenInsert}
        />

        <BillingAdjustmentDescriptionDialog
          onClose={this.onClose}
          billingAdjustment={selectedBillingAdjustment}
          open={openDialogDescription}
        />

        <BillingAdjustmentInsertDialog
          open={openDialogInsert}
          onClose={this.onCloseInsertDialog}
          openBillingAdjustmentSuccessMessage={this.openBillingAdjustmentSuccessMessage}
        />

      </div>
    )
  }
}

export default withStyles(styles)(BillingAdjustment)