import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../utils/i18n/i18n'
import InputPercentage from '../form-components/input-percentage/InputPercentage'

import CategoriesTree from './CategoriesTree'
import './CustomizeCategories.css'

// ===========================================================================================
// Selected Items Component
// ===========================================================================================
const Item = (props) => {
  const { category, onChangeValue, onRemove } = props
  const {
    id, code, name, planEffectValue, partnerEffectValue
  } = category
  const effectValue = partnerEffectValue || planEffectValue

  return (
    <Fragment>
      <li id={`category-${code}`} className={`custom-category-item custom-category-${id}`}>
        <div className="custom-category-title">
          <span>{name}</span>
        </div>
        <div className="custom-category-actions">
          <InputPercentage
            id={id.toString()}
            className={`custom-category-input-${id}`}
            value={effectValue}
            onChangeValue={onChangeValue}
          />
          <i id={`btn-close-${id}`} className="icon-close" onClick={() => onRemove(category)} />
        </div>
      </li>
      <div className="separator" />
    </Fragment>
  )
}

Item.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    planEffectValue: PropTypes.number,
    partnerEffectValue: PropTypes.number
  }),
  onChangeValue: PropTypes.func,
  onRemove: PropTypes.func
}
// ===========================================================================================

// ===========================================================================================
// Selected Items Component
// ===========================================================================================
const SelectedItems = (props) => {
  const {
    selectedItems, removeCategory, changePercentValue
  } = props

  return (
    <Fragment>
      <h3 id="title">{ i18n.t('Selected Categories') }</h3>
      <div id="description">{ i18n.t('Apply the commission for each selected category') }</div>
      <div className="separator" />
      <div id="selected-categories">
        <ul>
          {selectedItems.map(category => (<Item
            key={category.code}
            category={category}
            onRemove={removeCategory}
            onChangeValue={changePercentValue}
          />
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

SelectedItems.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      planEffectValue: PropTypes.number
    })
  ),
  removeCategory: PropTypes.func,
  changePercentValue: PropTypes.func
}
// ===========================================================================================

const CustomizeCategories = (props) => {
  const {
    treeList,
    selectedItems,
    selectCategory,
    removeCategory,
    changePercentValue
  } = props

  return (
    <div id="customize-categories">
      <div id="categories-tree">
        <CategoriesTree
          treeList={treeList}
          selectCategory={selectCategory}
        />
      </div>
      <div id="selected-items">
        <SelectedItems
          selectedItems={selectedItems}
          removeCategory={removeCategory}
          changePercentValue={changePercentValue}
        />
      </div>
    </div>
  )
}

CustomizeCategories.propTypes = {
  treeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      children: PropTypes.arrayOf
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      planEffectValue: PropTypes.number,
      partnerEffectValue: PropTypes.number
    })
  ).isRequired,
  selectCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  changePercentValue: PropTypes.func
}

export default CustomizeCategories
