import React from 'react'
import PropTypes from 'prop-types'

/** css */
import './User.css'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)

const Admin = (
  <div className="user-admin">ADMIN . </div>
)

const User = (props) => {
  const {
    className, userName, isAdmin, id
  } = props
  return (
    <div className={getClassName('user', className)} id={`user_${id}`}>
      <div className="user-container">
        <div className="avatar"><i className="icon-user" /></div>
        {isAdmin === true ? Admin : null}
        <div className="user-name">
          {userName}
        </div>
      </div>
    </div>
  )
}
User.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  id: PropTypes.string.isRequired
}

export default User