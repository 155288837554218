import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import i18n from '../../../../utils/i18n/i18n'

import './LoadingDialog.css'

export default function LoadingDialog(props) {
  const { isOpen, onClose } = props
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="loading-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="loading-dialog-title" style={{ 'textAlign': 'center' }}>{i18n.t('PROCESSING')}</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  )
}
LoadingDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}