import React from 'react'
import PropTypes from 'prop-types'

import './PageTitle.css'

const PageTitle = (props) => {
  const { title, description } = props
  return (
    <div>
      <div className="page-title__title">{title}</div>
      <div className="page-title__title-description">{description}</div>
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default PageTitle