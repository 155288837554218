import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import CustomizeCategories from '../../../../components/customize-categories/CustomizeCategories'

import StepFooter from './StepFooter'
import StepPartnerInfo from './StepPartnerInfo'
import getCategories from '../../../../services/api/categories/CategoriesAction'

const createCustomCategory = category => ({
  id: category.id,
  code: category.code,
  name: category.name,
  partnerEffectValue: category.planEffectValue || 0,
  partnerCustom: true
})

/*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["category"] }]*/
const setPartnerCustom = (categories, selectedCategory, partnerCustom) => {
  categories.forEach((category) => {
    if (category.id === selectedCategory.id) {
      category.partnerCustom = partnerCustom
    } else if (category.children) {
      setPartnerCustom(category.children, selectedCategory, partnerCustom)
    }
  })
}

function addAllCategories(currentList, newList) {
  const newResults = []
  newList.forEach((itemList) => {
    const index = currentList.map(category => category.id).indexOf(itemList.id)
    if (index === -1) {
      newResults.push(itemList)
    }
  })
  return currentList.concat(newResults)
}

class StepCategories extends Component {

  constructor(props) {
    super(props)

    const { categories, customCategories, selectedCategories } = this.props

    this.state = {
      categories,
      customCategories,
      selectedCategories
    }

    bindAll(this, ['handleUpdate', 'selectCategory', 'removeCustomCategory', 'changePercentValue'])
  }

  handleUpdate(state) {
    const { updateCategories } = this.props

    const newState = {
      ...this.state,
      state
    }

    this.setState(newState, () => updateCategories({
      stepCategories: {
        ...newState
      }
    }))
  }

  selectCategory(categorySelected) {
    if (categorySelected.id) {
      const { categories, customCategories, selectedCategories } = this.state

      const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(categorySelected.id)
      if (indexCustomCategories === -1) {
        const category = createCustomCategory(categorySelected)
        customCategories.push(category)
        selectedCategories.push(category)
      }

      const indexSelectedCategories = selectedCategories.map(selectedCategory => selectedCategory.id).indexOf(categorySelected.id)
      if (indexSelectedCategories === -1) {
        const category = createCustomCategory(categorySelected)
        selectedCategories.push(category)
      }

      setPartnerCustom(categories, categorySelected, true)

      this.handleUpdate({
        categories,
        customCategories,
        selectedCategories
      })
    }
  }

  removeCustomCategory(category) {
    const { categories, customCategories, selectedCategories } = this.state

    const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(category.id)
    if (indexCustomCategories !== -1) {
      customCategories.splice(indexCustomCategories, 1)
    }

    const indexSelectedCategories = selectedCategories.map(selectedCategory => selectedCategory.id).indexOf(category.id)
    if (indexSelectedCategories !== -1) {
      selectedCategories.splice(indexSelectedCategories, 1)
    }

    setPartnerCustom(categories, category, false)

    this.handleUpdate({
      categories,
      customCategories,
      selectedCategories
    })
  }

  changePercentValue(id, percentage) {
    const { customCategories, selectedCategories } = this.state

    const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(Number(id))
    if (indexCustomCategories !== -1) {
      customCategories[indexCustomCategories].partnerEffectValue = percentage
    }

    const indexSelectedCategories = selectedCategories.map(selectedCategory => selectedCategory.id).indexOf(Number(id))
    if (indexSelectedCategories !== -1) {
      selectedCategories[indexSelectedCategories].partnerEffectValue = percentage
    }

    this.handleUpdate({
      customCategories,
      selectedCategories
    })
  }

  componentDidMount() {
    const filter = {}
    const { currentPartner, stepInfo } = this.props
    const { planId } = currentPartner
    const { sellerId } = stepInfo

    if (planId !== null && Number(planId) > 0) {
      filter.planId = planId
    }

    if (sellerId !== null) {
      filter.partnerId = typeof sellerId === 'string' ? Number(sellerId) : sellerId
    }

    let { selectedCategories } = this.state

    getCategories(filter).then((resultCategories) => {
      const { categories } = resultCategories
      let { customCategories } = resultCategories

      const partnerCustomCategories = customCategories
        .filter(customCategory => customCategory.partnerCustom)
        .concat(selectedCategories)

      selectedCategories = addAllCategories(selectedCategories, partnerCustomCategories)
      customCategories = addAllCategories(selectedCategories, customCategories)

      selectedCategories.forEach((selectedCategory) => {
        setPartnerCustom(categories, selectedCategory, true)
      })

      this.setState({
        categories,
        customCategories,
        selectedCategories
      }, () => {
        this.handleUpdate({
          categories,
          customCategories,
          selectedCategories
        })
      })
    })
  }

  render() {
    const { categories, selectedCategories } = this.state
    const {
      jumpToStep, closeAction, saveAction, stepInfo
    } = this.props

    return (
      <Fragment>
        <StepPartnerInfo
          ecommerceId={stepInfo.ecommerceId}
          tradingName={stepInfo.tradingName}
        />

        <div id="categories-step">
          <CustomizeCategories
            treeList={categories}
            selectedItems={selectedCategories}
            selectCategory={this.selectCategory}
            removeCategory={this.removeCustomCategory}
            changePercentValue={this.changePercentValue}
          />
        </div>

        <StepFooter
          currentStepIdex={1}
          jumpToStep={jumpToStep}
          closeAction={closeAction}
          saveAction={saveAction}
          isLast
        />
      </Fragment>
    )
  }

}
StepCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      planEffectValue: PropTypes.number,
      partnerEffectValue: PropTypes.number,
      children: PropTypes.arrayOf
    })
  ).isRequired,
  customCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      planEffectValue: PropTypes.number,
      partnerEffectValue: PropTypes.number,
      children: PropTypes.arrayOf
    })
  ).isRequired,
  selectedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      planEffectValue: PropTypes.number,
      partnerEffectValue: PropTypes.number,
      children: PropTypes.arrayOf
    })
  ).isRequired,
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  saveAction: PropTypes.func,
  updateCategories: PropTypes.func,
  stepInfo: PropTypes.shape({
    sellerId: PropTypes.number,
    ecommerceId: PropTypes.string,
    tradingName: PropTypes.string
  }),
  currentPartner: PropTypes.shape({
    defaultRule: PropTypes.number,
    planId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  })
}

export default StepCategories