import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import SortingDirection from '../../commons/Enums'
import AuditGrid from './report-grid/AuditGrid'
import AuditDescriptionDialog from './audit-description/AuditDescriptionDialog'
import AuditTypeSelect from './AuditTypeSelect'
import { getCommissioningAudit, getOrderAudit, getSellerAudit } from '../../services/api/audit/AuditAction'
import emitter from '../../commons/Emitter'

function formatAudit(audit) {
  const newAudit = audit
  let op
  switch (audit.operation) {
    case 'I':
      op = 'Inserção'
      break
    case 'D':
      op = 'Remoção'
      break
    case 'U':
      op = 'Atualização'
      break
    default:
      op = ''
  }
  newAudit.operation = op
  return newAudit
}

class Audit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      audits: [],
      currentSortingField: 'endDate',
      currentSortingDirection: SortingDirection.DESC,
      hasMoreItems: true,
      total: 0,
      openDialogDescription: false,
      selectedBillingAdjustment: {},
      audit: {
        sellerId: null,
        type: null,
        value: 0,
        description: '',
        cycleInstanceId: null,
        auditTypeName: 'seller'
      },
      auditTypes: [
        { 'auditTypeName': 'seller', 'name': 'SELLER' },
        { 'auditTypeName': 'order', 'name': 'ORDER' },
        { 'auditTypeName': 'order_item', 'name': 'ORDER_ITEM' },
        { 'auditTypeName': 'order_status', 'name': 'ORDER_STATUS' },
        { 'auditTypeName': 'billing', 'name': 'BILLING' },
        { 'auditTypeName': 'billing_adjustment_reason', 'name': 'BILLING_ADJUSTMENT_REASON' },
        { 'auditTypeName': 'billing_adjustment', 'name': 'BILLING_ADJUSTMENT' },
        { 'auditTypeName': 'billing_commission_item', 'name': 'BILLING_COMMISSION_ITEM' },
        { 'auditTypeName': 'commission_item', 'name': 'COMMISSION_ITEM' },
        { 'auditTypeName': 'commission_rule', 'name': 'COMMISSION_RULE' },
        { 'auditTypeName': 'commission_transaction', 'name': 'COMMISSION_TRANSACTION' },
        { 'auditTypeName': 'cycle', 'name': 'CYCLE' },
        { 'auditTypeName': 'cycle_instance', 'name': 'CYCLE_INSTANCE' },
        { 'auditTypeName': 'partner_cycle', 'name': 'PARTNER_CYCLE' },
        { 'auditTypeName': 'partner_plan', 'name': 'PARTNER_PLAN' },
        { 'auditTypeName': 'period', 'name': 'PERIOD' },
        { 'auditTypeName': 'plan', 'name': 'PLAN' },
        { 'auditTypeName': 'promo_commission_rule', 'name': 'PROMO_COMMISSION_RULE' },
        { 'auditTypeName': 'promotion', 'name': 'PROMOTION' }
      ]
    }

    bindAll(this, ['updateAudits', 'onSorting', 'getAuditList', 'setAudits', 'onChangeValue', 'onOpenDescription', 'onClose'])
  }

  componentWillUnmount() {
    emitter.removeAllListeners('error')
  }

  componentDidMount() {
    this.getAuditList(0)
  }

  onChangeValue(key, value) {
    const { audit } = this.state
    audit[key] = value
    this.setState({
      audit
    })

    this.getAuditList(0)
  }

  getAuditList(page, id) {
    const { currentSortingField, currentSortingDirection } = this.state
    const params = {
      id,
      limit: 10,
      offset: page || 0,
      sortField: currentSortingField,
      sortDirection: currentSortingDirection
    }
    const { audit } = this.state
    const auditType = audit.auditTypeName

    let action = getSellerAudit

    switch (auditType) {
      case 'seller':
        action = getSellerAudit
        break
      case 'order':
      case 'order_item':
      case 'order_status':
        action = getOrderAudit
        break
      case 'billing':
      case 'billing_adjustment_reason':
      case 'billing_adjustment':
      case 'billing_commission_item':
      case 'commission_item':
      case 'commission_rule':
      case 'commission_transaction':
      case 'cycle':
      case 'cycle_instance':
      case 'partner_cycle':
      case 'partner_plan':
      case 'period':
      case 'plan':
      case 'promo_commission_rule':
      case 'promotion':
        action = getCommissioningAudit
        break
      default:
        action = getSellerAudit
    }

    action(auditType, params).then((response) => {
      this.updateAudits(response, params.offset)
    })
  }

  updateAudits(response, page) {
    if (response && response.audits && response.paging) {
      const newAudits = []
      const { paging } = response
      if (page === 0) {
        this.setState({
          audits: []
        }, () => {
          response.audits.map(audit => newAudits.push(formatAudit(audit)))
          this.setAudits(newAudits, paging.total)
        })
      } else {
        const { audits } = this.state
        audits.map(audit => newAudits.push(audit))
        response.audits.map(audit => newAudits.push(formatAudit(audit)))
        this.setAudits(newAudits, paging.total)
      }
    }
  }

  setAudits(audits, total) {
    this.setState({
      audits,
      hasMoreItems: (audits.length < total),
      total
    })
  }

  onSorting(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.getAuditList(0)
    })
  }

  onOpenDescription(audit) {
    this.setState({
      openDialogDescription: true,
      selectedBillingAdjustment: audit
    })
  }

  onClose() {
    this.setState({
      openDialogDescription: false
    })
  }

  renderAudits() {
    const {
      audits, currentSortingField, currentSortingDirection, hasMoreItems, total, audit, auditTypes, openDialogDescription, selectedBillingAdjustment
    } = this.state
    return (
      <div className="wrap-audit-content">
        <div id="seller-filter" className="auto-complete-seller-filter">
          <AuditTypeSelect
            id="auditTypeName"
            value={audit.auditTypeName}
            onChangeValue={this.onChangeValue}
            auditTypes={auditTypes}
          />
        </div>
        <AuditGrid
          audits={audits}
          hasMore={hasMoreItems}
          loadItems={this.getAuditList}
          onSorting={this.onSorting}
          currentSortingField={currentSortingField}
          currentSortingDirection={currentSortingDirection}
          total={total}
          onOpenDescription={this.onOpenDescription}
        />

        <AuditDescriptionDialog
          onClose={this.onClose}
          audit={selectedBillingAdjustment}
          open={openDialogDescription}
          data={selectedBillingAdjustment.data}
          onFilterById={this.getAuditList}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="content comission-reports">
        {this.renderAudits()}
      </div>
    )
  }

}

Audit.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tabsRoot: PropTypes.string,
    tabsIndicator: PropTypes.string,
    tabRoot: PropTypes.string,
    label: PropTypes.string
  }
  )
}

export default Audit
