import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Trans as Translator } from 'react-i18next'

/** css */
import './Breadcrumb.css'

function Chamfer() {
  return (
    <div className="chamfer">
      <div className="chamfer-border" />
    </div>
  )
}
export default class Breadcrumb extends Component {
  constructor(props) {
    super(props)
    this.window = window
  }

  generateNavigationHistory() {
  // const activeRoutes = Router.getActiveRoute()
    const { locationPathName } = this.props
    const paths = locationPathName.split('/')

    const breadcrumb = paths.map((path, index) => {
      let url = paths.slice(0, index + 1).join('/')
      if (index === 0) {
        url = (url === '' ? '/' : url)
        if (paths.length === 1) {
          return (
            <li key={index.toString()} className="active">
              <NavLink to={url}>
                <Translator>Home</Translator>
              </NavLink>
            </li>
          )
        }

        return (
          <li key={index.toString()}>
            <NavLink to={url}>
              <Translator>Home</Translator>
            </NavLink>
          </li>
        )
      }


      if (index === paths.length - 1) {
        return (
          <li key={index.toString()} className="active">
            <NavLink to={url}>
              <Translator>{path}</Translator>
            </NavLink>
          </li>
        )
      }

      return (
        <li key={index.toString()}>
          <NavLink to={url}>
            <Translator>{path}</Translator>
          </NavLink>
        </li>
      )
    })
    return breadcrumb
  }

  render() {
    // console.log(this)
    return (
      <div>
        <ol className="breadcrumb">
          <i className="icon home" />
          {this.generateNavigationHistory()}
        </ol>
        <Chamfer />
      </div>
    )
  }
}
Breadcrumb.propTypes = {
  locationPathName: PropTypes.string
}
