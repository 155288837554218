/** components */
import { CredentialManager } from './CredentialManager'
import emitter from './Emitter'

function executeDownload(data, headers) {
  const filename = headers.get('Content-Disposition').split('"')[1]
  const fileSource = new Blob([data], { type: headers.get('Content-Type') })
  const fileUrl = window.URL.createObjectURL(fileSource)

  const downloadEvent = document.createEvent('MouseEvents')
  downloadEvent.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)

  const saveLink = document.createElement('a')
  saveLink.href = fileUrl
  saveLink.download = filename
  saveLink.dispatchEvent(downloadEvent)
}

export const getDownloadRequestConfig = (tokenType, accessToken) => ({
  method: 'GET',
  headers: {
    'Authorization': `${tokenType} ${accessToken}`,
    'Content-Type': 'application/json'
  }
})

let headers

export function doDownload(url, loading){

  validLoading(loading, true)

  fetch(url, getDownloadRequestConfig(CredentialManager.getUserToken().token_type, CredentialManager.getUserToken().access_token))
    .then((response) => {
      // eslint-disable-next-line prefer-destructuring
      headers = response.headers
      return response.blob()
    })
    .then((data) => {
      executeDownload(data, headers)
      validLoading(loading, false)
    })
    .catch(() => {
      validLoading(loading, false)
      emitter.emit('download error', 'Error on do download')
    })
}

export function validLoading(loading, active){
  if (loading)
    loading(active)
}