import { Component } from 'react'
import PropTypes from 'prop-types'
import configs from '../../../settings/application'

const DEFAULT_LOCALE = configs.locale.default
const DEFAULT_CURRENCY = configs.locale.defaultCurrency

/**
 * Format number into currency of a locale.
 *
 * @param value number value
 * @param locale locale to use on format
 * @param currency currency to use on format
 */
const toLocaleCurrency = (value, locale, currency) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency
}).format(value)


export class FormattedCurrency extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formattedValue: toLocaleCurrency(props.value, props.locale, props.currency)
    }
  }

  render() {
    const { formattedValue } = this.state
    return formattedValue
  }
}

FormattedCurrency.propTypes = {
  value: PropTypes.number.isRequired,
  locale: PropTypes.string,
  currency: PropTypes.string
}

FormattedCurrency.defaultProps = {
  locale: DEFAULT_LOCALE,
  currency: DEFAULT_CURRENCY
}

export default FormattedCurrency