import React, { Component } from 'react'
import { bindAll } from 'lodash'
import { Redirect } from 'react-router-dom'
import Trans from 'react-i18next/dist/commonjs/Trans'

/** components */
import Logo from '../../components/header/title/logo/Logo'
import InputText from '../../components/form-components/InputText'
import ActionButton from '../../components/buttons/ActionButton'
import { CredentialManager } from '../../commons/CredentialManager'
import { ToggleManager } from '../../commons/ToggleManager'
import authenticate from '../../services/api/authentication/AuthenticationAction'
import getUserInfo from '../../services/api/user-info/UserInfo'
import i18n from '../../utils/i18n/i18n'
import { getToggles } from '../../services/api/toggle/ToggleAction'
import loaderIcon from '../../resources/images/loaderComplete.gif'

/** css */
import './Authentication.css'

// Renders the message for incorrect user or password
const InvalidUserPassword = () => (
  <div className="invalid-user-password">
    <div className="message">
      <i className="icon-warning" />
      <span><Trans>User or password is incorrect</Trans></span>
    </div>
  </div>
)

export default class Authentication extends Component {
  constructor(props) {
    super(props)

    this.state = {
      input_text_user: '',
      input_text_password: '',
      validUser: true,
      validPassword: true,
      validCredentials: true,
      loader: false
    }
    bindAll(this, ['validateUser', 'validatePassword', 'handleChange', 'click'])
  }

  validateUser() {
    // eslint-disable-next-line camelcase
    const { input_text_user } = this.state
    return input_text_user.length > 0
  }

  validatePassword() {
    // eslint-disable-next-line camelcase
    const { input_text_password } = this.state
    return input_text_password.length > 0
  }

  // Update the state with the current value of the inputs
  handleChange(event) {
    const value = (event.target.type =='text')? event.target.value.replace(/( )+/g, ''): event.target.value
    this.setState({
      [event.target.id]: value
    })
  }

  click(event) {
    event.preventDefault()
    // eslint-disable-next-line camelcase
    const { input_text_user, input_text_password } = this.state
    const validUser = this.validateUser()
    const validPassword = this.validatePassword()
    this.setState({
      validUser,
      validPassword
    })
    // Checks if the user and password are filled in before calling the API for authentication
    if (validUser && validPassword) {
      this.setState({ loader: true })
      // Calls the API for authenticate the user
      authenticate(input_text_user, input_text_password)
        .then((response) => {
          //console.log('authenticate response')
          //console.log(response)
          // Sets the token got from the authetication API
          CredentialManager.setUserToken(response)

          CredentialManager.setUserTokenCallback(response, (() => {
            getUserInfo().then((data) => {
              // Sets the user info
              //console.log('getUserInfo response')
              //console.log(data)
              CredentialManager.setAuthenticatedUser({ name: data.username, type: data.customData.userType })
              CredentialManager.setUserRoles(data.roles)

              //getToggles get all of existing toggles
              const activeToggles = []
              activeToggles.push('CREATE-STONE-SELLER-ACCOUNT')

              //SetToggle recording the active toggles
              ToggleManager.setToggle(activeToggles) 
              
              this.setState({ validCredentials: true, loader:false})

            })
          }))

        }).catch((error) => {
          if (error) {
            const { status, data } = error.response ? error.response : { status: '', data: '' }
            // Checks if the status is 400 and error is invalid_grant (Unauthorized) in order to change the inputs' appeareance
            if (status === 400 && data.error === 'invalid_grant') {
              this.setState({
                validCredentials: false
              })
            }
          }
          this.setState({loader: false})            
        })
    }
  }

  componentWillMount() {
    CredentialManager.logout()
    ToggleManager.logout()
  }

  render() {
    const { validUser, validPassword, validCredentials, loader } = this.state

    // Checks if the inputs are filled in, othwerwise, sets error classes
    const errorUser = validUser ? '' : 'error error-text'
    const errorPassword = validPassword ? '' : 'error error-text'
    let errorLogin = ''
    if (validUser && validPassword) {
      errorLogin = validCredentials ? '' : 'error '
    }

    if (CredentialManager.userIsAuthenticated()) {
      return <Redirect to="/commission-management-reports" />
    }

    return (
      <div className="login-background">
        <div className="wrap-login">
          <Logo />
          <InputText
            label={i18n.t('User')}
            placeholder={i18n.t('youremail@domain.com.br')}
            onChange={this.handleChange}
            errorMessage={` - ${i18n.t('Required')}`}
            className={errorLogin + errorUser}
            id="user"
            value={this.state.input_text_user}
          />
          <InputText
            label={i18n.t('Password')}
            placeholder="* * * * * * * *"
            type="password"
            onChange={this.handleChange}
            errorMessage={`- ${i18n.t('Required')}`}
            id="password"
            className={errorLogin + errorPassword}
          />
          {validCredentials ? '' : <InvalidUserPassword />}
          <ActionButton type="submit" componentClass="btn-action btn-login" text={i18n.t('Login')} onClickAction={this.click} />
        </div>
        {loader ? <div className='loader'><div className='loader-img'><img src={loaderIcon}/></div></div> : ''}
      </div>
    )
  }
}
