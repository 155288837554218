import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import CurrencyInput from 'react-currency-input'

import './InputMoney.css'

const MAX_VALUE = 9999999.99

const getFloatValue = value => Number(value.toString().replace(/R\$|\./g, '').replace(',', '.'))
/**
 * **************************************************************************************
 * Render a input with money mask - <MoneyField>
 * **************************************************************************************
 */
export class InputMoney extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value
    }

    bindAll(this, ['onChange'])
  }

  onChange(event, maskedvalue, floatvalue) {
    const { value } = this.state
    const { id, onChangeValue } = this.props


    const shouldUpdate = floatvalue <= MAX_VALUE
    if (shouldUpdate) {
      this.setState({
        value: maskedvalue
      })
      onChangeValue(id, floatvalue)
    } else {
      onChangeValue(id, getFloatValue(value))
    }
  }

  render() {
    const {
      id,
      className,
      label,
      placeholder
    } = this.props

    const { value } = this.state

    return (
      <div className={`wrap-input-text ${className}`}>
        <label htmlFor={id}>{label}</label>
        <CurrencyInput
          id={id}
          placeholder={placeholder}
          value={value}
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          onChangeEvent={this.onChange}
        />
      </div>
    )
  }
}

InputMoney.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  onChangeValue: PropTypes.func
}

export default InputMoney