import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import i18n from '../../../utils/i18n/i18n'
import InputText from '../InputText'
import InputPercentage from '../input-percentage/InputPercentage'
import ActionButton from '../../buttons/ActionButton'

import './SkuCommissionInputList.css'
import FormattedPercentage from '../../commons/formater/FormattedPercentage'

export const SkuRule = (props) => {
  const { item, className, onRemove } = props

  return (
    <div className={`selected-sku-and-commission-item ${className}`}>
      <div className="info">
        <span className="sku">{item.sku}</span>
        <span className="commission">
          <FormattedPercentage value={item.effect.value} />
        </span>
      </div>
      <i className="icon-close" onClick={() => onRemove(item)} />
    </div>
  )
}

SkuRule.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    effect: PropTypes.shape({
      effect: PropTypes.string,
      value: PropTypes.number.isRequired
    })
  }).isRequired,
  className: PropTypes.string,
  onRemove: PropTypes.func.isRequired
}

export default class SkuCommissionInputList extends Component {

  constructor(props) {
    super(props)

    this.state = this.getInitialState()

    bindAll(this, [
      'handleChangeSkuCommission',
      'handleChangeSku',
      'getCommissionedSkuGrid',
      'handleIncludeSkuRule',
      'getInitialState'
    ])
  }

  getInitialState() {
    return {
      sku: '',
      effect: {
        type: 'PERCENT',
        value: 0
      }
    }
  }

  handleChangeSku(event) {
    const { value } = event.target

    this.setState({
      sku: value
    })
  }

  handleChangeSkuCommission(key, value) {
    this.setState({
      effect: {
        type: 'PERCENT',
        value
      }
    })
  }

  handleIncludeSkuRule(sku, effect) {
    const { onInclude } = this.props
    const canInclude = sku && sku.trim()

    if (canInclude) {
      onInclude(sku.trim(), effect)
      this.setState(this.getInitialState())
    }
  }

  getCommissionedSkuGrid() {
    const { commissionedSkuList, onRemove } = this.props

    const rows = []
    const rowCount = commissionedSkuList.length / 2

    for (let row = 0; row < rowCount; row += 1) {
      const left = commissionedSkuList[2 * row]
      const right = commissionedSkuList[2 * row + 1]

      const odd = row % 2
      const rowElement = (
        <div key={left.sku} className="selected-sku-and-commission-row">
          {left && (
            <SkuRule
              key={left.sku}
              className={odd ? 'selected-sku-and-commission-odd' : ''}
              item={left}
              onRemove={onRemove}
            />
          )}

          {right && (
            <SkuRule
              key={right.sku}
              className={odd ? 'selected-sku-and-commission-odd' : ''}
              item={right}
              onRemove={onRemove}
            />
          )}
        </div>
      )

      rows.push(rowElement)
    }

    return rows
  }

  render() {
    const { commissionedSkuList } = this.props
    const { sku, effect } = this.state
    let isValid = false

    if (sku.trim()) {
      isValid = true
    }

    const commissionedSkuGrid = commissionedSkuList.length > 0
      ? this.getCommissionedSkuGrid()
      : <div className="message-without-sku-and-commission-list">{i18n.t('No SKU added.')}</div>

    return (
      <Fragment>
        <div className="form-group wrap-input-text sku-commission-add-container">
          <div id="skuContainer" className="col-sku pull-left">
            <InputText
              id="inputSku"
              value={sku}
              placeholder={i18n.t('Insert the Seller SKU')}
              label="SKU"
              maxLength={100}
              onChange={this.handleChangeSku}
            />
          </div>
          <div id="skuCommissionContainer" className="col-sku-commission pull-right">
            <InputPercentage
              id="inputSkuCommission"
              value={effect.value}
              label={i18n.t('Commission')}
              className="input-sku-commission"
              onChangeValue={this.handleChangeSkuCommission}
            />
            <ActionButton
              id="add-sku"
              icon="icon-add"
              componentClass="btn btn-action btn-add-sku-commission"
              onClickAction={() => this.handleIncludeSkuRule(sku, effect)}
              text={i18n.t('Add')}
              disable={!isValid}
            />
          </div>
        </div>
        <section id="commissionedSkuList" className="commissioned-sku-list">
          <header className="step-sku-and-commission-list-header">
            <div id="title">
              <h3 className="title">{i18n.t('Added SKUs')}</h3>
              <span className="counter">{ `(${commissionedSkuList.length})` }</span>
            </div>
          </header>
          <div className="selected-sku-and-commission-list">
            {commissionedSkuGrid}
          </div>
        </section>
      </Fragment>
    )
  }
}

SkuCommissionInputList.propTypes = {
  commissionedSkuList: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      effect: PropTypes.shape({
        effect: PropTypes.string,
        value: PropTypes.number.isRequired
      })
    })
  ).isRequired,
  onRemove: PropTypes.func,
  onInclude: PropTypes.func
}