import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import i18n from '../../../utils/i18n/i18n'
import Closable from '../../../components/closable/Closable'
import SellerAutoComplete from '../../../components/auto-complete/seller-auto-complete/SellerAutoComplete'
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid, TableContent
} from '../../../components/table/Table'
import GroupedList, { PERCENTAGE } from '../../../components/group/GroupedList'
import FormattedPercentage from '../../../components/commons/formater/FormattedPercentage'

import SellerInsertWizard from '../seller-insert-dialog/SellerInsertWizard'
import SellersGridInfo from './seller-grid-info/SellersGridInfo'

import './SellersGrid.css'
import { CredentialManager } from '../../../commons/CredentialManager'

/**
 * Renders the message that there are not sellers to be shown.
 */
function buildNoResultMessage() {
  return (
    <div className="no-result-message">
      <i className="icon-common-file-warning" />
      {i18n.t('There are no sellers to be shown')}
    </div>
  )
}

/**
 * Builds an array of category item objects to be used with the grouped list component
 */
function buildItems(customCategories) {
  return customCategories.map((category) => {
    const { name, code } = category
    let displayName = name || code
    if (displayName.length > 20) {
      displayName = `${displayName.substring(0, 17)}...`
    }
    return ({
      text: displayName,
      badgeValue: category.effect.value,
      badgeFormat: category.effect.type === 'PERCENT' ? PERCENTAGE : ''
    })
  })
}

/**
 * Renders the partner grid's lines
 */
function renderSellers(sellerDataList, openCategoriesDialog, openEditSellerDialog) {
  if (!sellerDataList || sellerDataList.length === 0) {
    return null
  }
  const emptyValue = <span>—</span>

  const hasRoleEditPartnerPlan = CredentialManager.userHasRole('ROLE_EDIT_PARTNER_PLAN')

  return (sellerDataList.map((sellerData) => {
    const {
      effect, customCategories, plan
    } = sellerData

    const {
      sellerId, tradingName, ecommerceId
    } = sellerData.seller

    let categoryItems = []
    if (customCategories) {
      categoryItems = buildItems(customCategories, sellerId)
    }
    let categories = emptyValue
    if (customCategories && customCategories.length > 0) {
      categories = (
        <GroupedList
          items={categoryItems}
          limit={3}
          className="categories-list"
          onClick={() => openCategoriesDialog(categoryItems)}
        />
      )
    }
    const row = { key: sellerId, className: 'tr' }
    let effectValue = emptyValue
    if (effect && effect.value) {
      effectValue = <FormattedPercentage value={effect.value} />
    }

    const columns = [{
      className: 'tr-name',
      value: (
        <span id={`name_${sellerId}`}>
          {`${ecommerceId} - ${tradingName}`}
        </span>
      )
    },
    {
      className: 'tr-standard-commission',
      value: (<span id={`standard-commission_${sellerId}`}>{effectValue}</span>)
    },
    {
      className: 'tr-categories',
      value: (<span id={`categories_${sellerId}`}>{categories}</span>)
    },
    {
      className: 'tr-plan-name',
      value: (<span id={`plan-name_${sellerId}`}>{plan ? plan.name : '—'}</span>)
    },
    {
      className: 'tr-edit',
      value: (
        <span id={`edit_${sellerId}`}>
          { hasRoleEditPartnerPlan
            && <i id={`icon-edit-${sellerId}`} className="icon-text-edit" onClick={() => openEditSellerDialog(sellerData)} />}
        </span>
      )
    }]

    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}


class SellersGrid extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isEditSellerDialogOpen: false,
      showPartnerSuccessMessage: false
    }

    bindAll(this, [
      'openEditSellerDialog',
      'closeSellerDialog',
      'openPartnerSuccessMessage',
      'closePartnerSuccessMessage',
      'onSuggestionSelected',
      'onSuggestionsClearRequested',
      'handleLoadItems'
    ])
  }

  openEditSellerDialog(seller) {
    this.setState({
      editingSeller: seller,
      isEditSellerDialogOpen: true
    })
  }

  closeSellerDialog() {
    this.setState({ isEditSellerDialogOpen: false })
  }

  openPartnerSuccessMessage() {
    this.setState({ showPartnerSuccessMessage: true })
  }

  closePartnerSuccessMessage() {
    this.setState({ showPartnerSuccessMessage: false })
  }

  onSuggestionSelected(seller) {
    const { loadItems } = this.props
    this.setState({
      selectedSeller: seller
    }, () => {
      loadItems(0, seller)
    })
  }

  onSuggestionsClearRequested() {
    const { loadItems } = this.props
    this.setState({
      selectedSeller: {}
    }, () => {
      loadItems(0)
    })
  }

  handleLoadItems() {
    const { loadItems } = this.props
    const { selectedSeller } = this.state
    loadItems(0, selectedSeller != null ? selectedSeller : null)
  }

  render() {
    const {
      isEditSellerDialogOpen, showPartnerSuccessMessage, selectedSeller, editingSeller
    } = this.state

    const {
      sellers, hasMore, loadItems, onSorting, total, openCategoriesDialog, currentSortingField, currentSortingDirection
    } = this.props

    const lines = renderSellers(sellers, openCategoriesDialog, this.openEditSellerDialog)

    const sortingFields = [
      'tradingName',
      null,
      null,
      null,
      null]

    const titles = [
      i18n.t('Store'),
      i18n.t('Commission'),
      i18n.t('Categories'),
      i18n.t('Plan'),
      null]

    const styleClasses = [
      'th-left th-name',
      'th-commission',
      'th-categories',
      'th-plan-name',
      'th-edit']

    const descriptions = [
      null,
      i18n.t('Contractual'),
      null,
      null,
      null]

    return (
      <Fragment>
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={showPartnerSuccessMessage}
            onClose={this.closePartnerSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-partner-save-success">
              <i className="icon-ok-check" />
              {i18n.t('Partner Commission Grid successfully edited')}
            </span>
          </Closable>
        </section>
        <div id="seller-filter" className="auto-complete-seller-filter">
          <SellerAutoComplete
            onSuggestionSelected={seller => this.onSuggestionSelected(seller)}
            onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
            selectedSeller={selectedSeller || {}}
          />
        </div>
        <SellersGridInfo
          actual={sellers.length > total ? total : sellers.length}
          total={total}
        />
        <TableContainer>
          <Table className="sellers-table">
            <TableHeader
              sortingFields={sortingFields}
              titles={titles}
              styleClasses={styleClasses}
              descriptions={descriptions}
              onSorting={onSorting}
              currentSortingField={currentSortingField}
              currentSortingDirection={currentSortingDirection}
            />
            {lines
              ? (
                <TableBodyInfinite
                  hasMore={hasMore}
                  loadItems={loadItems}
                  loadingColSpan={12}
                >
                  {lines}
                </TableBodyInfinite>
              )
              : (
                <RenderEmptyReportGrid
                  displayMessage={buildNoResultMessage()}
                  colSpan={titles.length}
                />
              )}
            <HasMore actual={sellers.length > total ? total : sellers.length} total={total} />
          </Table>
        </TableContainer>

        {editingSeller && (
          <SellerInsertWizard
            isOpen={isEditSellerDialogOpen}
            onClose={() => this.closeSellerDialog()}
            openSuccessMessage={() => this.openPartnerSuccessMessage()}
            partner={editingSeller}
            loadItems={() => this.handleLoadItems()}
          />
        )}

      </Fragment>
    )
  }

}

SellersGrid.propTypes = {
  sellers: PropTypes.arrayOf(
    PropTypes.shape({
      sellerId: PropTypes.number,
      ecommerceId: PropTypes.string,
      tradingName: PropTypes.string,
      plan: PropTypes.shape({
        planId: PropTypes.number,
        effect: PropTypes.shape({
          value: PropTypes.number
        })
      }),
      customCategories: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          name: PropTypes.string,
          effect: PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.number
          })
        })
      )
    })
  ),
  hasMore: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  openCategoriesDialog: PropTypes.func.isRequired
}

export default SellersGrid