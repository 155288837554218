import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindAll } from 'lodash'
import i18n from '../../../utils/i18n/i18n'


/** components */
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid, TableContent
} from '../../../components/table/Table'
import GroupedList, { PERCENTAGE } from '../../../components/group/GroupedList'
import { FormattedDate, FormattedTime } from '../../../components/commons'
import SwitchOption from '../../../components/switch-option/SwitchOption'
import configs from '../../../settings/application'
import PromotionsGridInfo from './promotions-grid-info/PromotionsGridInfo'
import { PromotionType } from '../../../commons/Enums'
import { getPromotionDownload } from '../../../services/api/download/DownloadActions'

/** style */
import './PromotionsGrid.css'
import SellerAutoComplete from '../../../components/auto-complete/seller-auto-complete/SellerAutoComplete'
import CredentialManager from '../../../commons/CredentialManager'

const DEFAULT_TIMESTAMP = configs.format.timeStamp
/**
 * Renders the message that there are not plans to be shown.
 */
function buildNoResultMessage() {
  return (
    <div className="no-result-message">
      <i className="icon-common-file-warning" />
      {i18n.t('There are no promotions to be shown.')}
    </div>
  )
}

/**
 * Builds an array of category item objects to be used with the grouped list component
 */
function buildItems(detailsList, promotionType) {
  return detailsList.map((detail) => {
    let text = ''
    const {
      categoryName, promoSku, promoCategory
    } = detail
    if (promotionType === PromotionType.CATEGORY) {
      text = categoryName || promoCategory
    }

    if (promotionType === PromotionType.SKU) {
      text = promoSku
    }

    if (promotionType === PromotionType.PARTNER) {
      text = i18n.t('Seller')
    }

    if (text.length > 20) {
      text = `${text.substring(0, 17)}...`
    }

    return ({
      text,
      badgeValue: detail.effect.value,
      badgeFormat: detail.effect.type === 'PERCENT' ? PERCENTAGE : ''
    })
  })
}

function getStatus(startDate, endDate) {
  const actualDate = moment().utc().format(DEFAULT_TIMESTAMP)
  if (startDate < actualDate && endDate > actualDate) {
    return 'current'

  } if (startDate > actualDate) {
    return 'scheduled'

  } if (endDate < actualDate) {
    return 'expired'
  }
  return ''
}

function hasOpacityCollumns(status, enabled) {
  const isExpired = (status === 'expired')
  const notEnabled = (!enabled && (status === 'current' || 'scheduled'))

  return isExpired || notEnabled
}

function hasStatusCollumnOpacity(status) {
  const isExpired = (status === 'expired')
  return isExpired
}

function getPromotionTypeBadge(promotionType) {
  if (promotionType === PromotionType.CATEGORY) {
    return (
      <div className="promotion-type category">
        CAT
      </div>
    )
  }

  if (promotionType === PromotionType.SKU) {
    return (
      <div className="promotion-type sku">
        SKU
      </div>
    )
  }
  return null
}

/**
 * Renders the promotion grid's lines
 */
function renderPromotions(promotions, openCommissionDetailsDialog,
  isTooltipOpen, onConfirm, onTooltipOpen, onTooltipClose, onDownload) {
  if (!promotions || promotions.length === 0) {
    return null
  }

  const hasRoleEditPromotion = CredentialManager.userHasRole('ROLE_EDIT_PROMOTION')

  const emptyValue = <span>—</span>

  return (promotions.map((promotion) => {
    const {
      promotionId, partner, promoCommissionRules, startDate, endDate, campaign, changedOn, changedBy, enabled, promotionType
    } = promotion
    let rulesDetails = []
    if (promoCommissionRules) {
      rulesDetails = buildItems(promoCommissionRules, promotionType)
    }

    let rules = emptyValue
    if (promoCommissionRules && promoCommissionRules.length > 0) {
      rules = (
        <GroupedList
          items={rulesDetails}
          limit={3}
          className="details-list"
          onClick={() => openCommissionDetailsDialog(rulesDetails)}
        />
      )
    }

    const status = getStatus(startDate, endDate)
    const opacity = hasOpacityCollumns(status, enabled) ? '-opacity' : ''
    const statusOpacity = hasStatusCollumnOpacity(status, enabled) ? '-dimmed' : ''
    const badgePromotion = getPromotionTypeBadge(promotionType)
    const row = { key: promotionId, className: 'promotion-tr' }

    const columns = [{
      className: `promotion-tr-partner${opacity}`,
      value: (
        <span id={`partner${promotionId}`}>
          {`${partner.ecommerceId} - ${partner.tradingName}`}
        </span>
      )
    }, {
      className: `promotion-tr-rules${opacity}`,
      value: (
        <span id={`rules_${promotionId}`}>
          {badgePromotion}
          {rules}
        </span>
      )
    }, {
      className: `promotion-tr-validity${opacity}`,
      value: (
        <Fragment>
          <div className="promotion-tr-validity-from" id={`from_${promotionId}`}>
            <strong>{i18n.t('from')}</strong>
            &nbsp;
            <FormattedDate value={startDate} />
            -
            <FormattedTime value={startDate} />
            h
          </div>
          <div className="promotion-tr-validity-to" id={`to_${promotionId}`}>
            <strong>{i18n.t('to')}</strong>
            &nbsp;
            <FormattedDate value={endDate} />
            -
            <FormattedTime value={endDate} />
            h
          </div>
        </Fragment>
      )
    }, {
      className: `promotion-tr-status${statusOpacity}`,
      value: (<span id={`status_${promotionId}`}>{i18n.t(status)}</span>)
    }, {
      className: `promotion-tr-campaign${opacity}`,
      value: (<span id={`campaign_${promotionId}`}>{campaign}</span>)
    }, {
      className: `promotion-tr-edit-at${opacity}`,
      value: (
        <Fragment>
          <div className="promotion-tr-changed-on" id={`changed_on_${promotionId}`}>
            <FormattedDate value={changedOn} />
            -
            <FormattedTime value={changedOn} />
            h
            &nbsp;
            <i>{i18n.t('by')}</i>
          </div>
          <div className="promotion-tr-changed-by" id={`changed_by_${promotionId}`}>
            {changedBy}
          </div>
        </Fragment>
      )
    }, {
      className: `promotion-tr-switch${opacity}`,
      value: (
        <div className="promotion-options">
          <span id={`download_promotion_${promotionId}`} className="download-promotion" onClick={() => onDownload(promotionId)}>
            <i className="icon-download" alt={i18n.t('Download Promotion')} title={i18n.t('Download Promotion')} />
          </span>
          <span id={`switch_${promotionId}`} className="switch-option">
            {hasRoleEditPromotion && (
              <SwitchOption
                identifier={promotionId}
                status={enabled}
                hasOpenTooltip={isTooltipOpen}
                disable={status === 'expired'}
                onConfirm={(resultPromotionId, resultStatus) => { onConfirm(resultPromotionId, resultStatus) }}
                onTooltipClose={() => { onTooltipClose() }}
                onTooltipOpen={() => onTooltipOpen()}
                onClickAway={() => {}}
              >
                {i18n.t('Do you really wish')}
                {' '}
                <strong className="switch-option-popper-tooltip-message-action">{i18n.t(enabled ? 'inactivate' : 'activate')}</strong>
                {' '}
                {i18n.t('this promotion?')}
              </SwitchOption>
            )}
          </span>
        </div>
      )
    }]

    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}

/**
 * Renders the promotions grid
 */
class PromotionsGrid extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isTooltipOpen: false
    }

    bindAll(this, [
      'onTooltipOpen',
      'onTooltipClose',
      'onSwitchConfirm'
    ])
  }

  onTooltipOpen() {
    this.setState({ isTooltipOpen: true })
  }

  onTooltipClose() {
    this.setState({ isTooltipOpen: false })
  }

  onSwitchConfirm(promotionId, newStatus) {
    const { onUpdatePromotionState } = this.props
    onUpdatePromotionState(promotionId, newStatus)
  }

  render() {
    const {
      promotions,
      hasMore,
      loadItems,
      onSorting,
      currentSortingField,
      currentSortingDirection,
      total,
      openCommissionDetailsDialog,
      openInsertDialog,
      openMassiveInsertDialog,
      onSelectFilter,
      onClearFilter,
      selectedFilter
    } = this.props
    const {
      isTooltipOpen
    } = this.state

    const lines = renderPromotions(promotions, openCommissionDetailsDialog,
      isTooltipOpen,
      (identifier, newStatus) => { this.onSwitchConfirm(identifier, newStatus) },
      () => { this.onTooltipOpen() },
      () => { this.onTooltipClose() },
      (promotionId) => { getPromotionDownload(promotionId) }
    )
    const sortingFields = ['partner.tradingName', null, 'startDate', null, 'campaign', 'changedOn', null]
    const titles = [i18n.t('Seller'), i18n.t('Promotion Commission'), i18n.t('Validity'), 'Status', i18n.t('Campaign'),
      i18n.t('Edit at'), null]
    const styleClasses = ['promotion th-seller', 'promotion th-promotional-commission',
      'promotion th-validity', 'promotion th-status', 'promotion th-campaign', 'promotion th-edit-at', 'promotion th-edit-actions']
    const descriptions = [null, null, null, null, null, null]

    return (
      <Fragment>
        <div className="promotion-grid__filter-area">
          <SellerAutoComplete
            onSuggestionSelected={onSelectFilter}
            onSuggestionsClearRequested={onClearFilter}
            selectedSeller={selectedFilter.selectedSeller}
            className="seller-filter-auto-complete"
          />
        </div>
        <PromotionsGridInfo
          actual={promotions.length > total ? total : promotions.length}
          total={total}
          openInsertDialog={() => openInsertDialog()}
          openMassiveInsertDialog={() => openMassiveInsertDialog()}
        />
        <TableContainer>
          <Table className="promotions-table">
            <TableHeader
              sortingFields={sortingFields}
              titles={titles}
              styleClasses={styleClasses}
              descriptions={descriptions}
              onSorting={onSorting}
              currentSortingField={currentSortingField}
              currentSortingDirection={currentSortingDirection}
            />
            {lines
              ? (
                <TableBodyInfinite
                  hasMore={hasMore}
                  loadItems={loadItems}
                  loadingColSpan={12}
                >
                  {lines}
                </TableBodyInfinite>
              )
              : (
                <RenderEmptyReportGrid
                  displayMessage={buildNoResultMessage()}
                  colSpan={titles.length}
                />
              )}
            <HasMore actual={promotions.length > total ? total : promotions.length} total={total} />
          </Table>
        </TableContainer>
      </Fragment>
    )
  }
}

PromotionsGrid.propTypes = {
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      promotionList: PropTypes.arrayOf(
        PropTypes.shape({
          promotionId: PropTypes.number,
          promoCommissionRules: PropTypes.arrayOf(
            PropTypes.shape({
              categoryName: PropTypes.string,
              promoSku: PropTypes.string,
              effect: PropTypes.shape({
                type: PropTypes.string,
                value: PropTypes.number
              })
            })),
          partner: PropTypes.shape({
            ecommerceId: PropTypes.number,
            tradingName: PropTypes.string
          }),
          stardDate: PropTypes.string,
          endDate: PropTypes.string,
          campaign: PropTypes.string,
          changedOn: PropTypes.string,
          changedBy: PropTypes.string,
          enabled: PropTypes.bool
        }))
    })),
  selectedFilter: PropTypes.shape({
    selectedSeller: PropTypes.shape({
      tradingName: PropTypes.string,
      ecommerceId: PropTypes.string
    })
  }),
  hasMore: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  onSorting: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  openCommissionDetailsDialog: PropTypes.func.isRequired,
  onUpdatePromotionState: PropTypes.func.isRequired,
  openInsertDialog: PropTypes.func.isRequired,
  openMassiveInsertDialog: PropTypes.func.isRequired
}

PromotionsGrid.defaultProps = {
  selectedFilter: {
  }
}

export default PromotionsGrid