import React from 'react'
import PropTypes from 'prop-types'
import { CustomDialogHeader, CustomDialog, CustomDialogFooter } from '../dialog/CustomDialog'
import i18n from '../../../utils/i18n/i18n'

import './ErrorDialog.css'

/**
 * **************************************************************************************
 * Renders ErrorDialogHeader - <ErrorDialogHeader>
 * **************************************************************************************
 */
export const ErrorDialogHeader = (props) => {
  const {
    title,
    classes,
    id,
    onClose,
    icon
  } = props

  return (
    <CustomDialogHeader
      title={title}
      icon={icon}
      classes={classes}
      onClose={onClose}
      id={id}
    />
  )
}
ErrorDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string
}

ErrorDialogHeader.defaultProps = {
  icon: 'icon-warning'
}

/**
 * **************************************************************************************
 * Renders ErrorDialogFooter - <ErrorDialogFooter>
 * **************************************************************************************
 */
export const ErrorDialogFooter = (props) => {
  const {
    classes, onClose, id, hasExportButton, onExport
  } = props

  const buttons = []

  if (hasExportButton) {
    buttons.push({
      id: 'error-dialog-btn-export',
      class: 'btn error-dialog-footer-btn-export',
      name: i18n.t('Export errors list'),
      action: onExport,
      key: 'export-button',
      icon: 'icon-download'
    })
  }

  buttons.push({
    id: 'error-dialog-btn-back',
    class: 'btn error-dialog-footer-btn-back',
    name: i18n.t('Back'),
    action: onClose,
    key: 'back-button'
  })

  return (
    <CustomDialogFooter
      classes={classes}
      buttons={buttons}
      id={id}
    />
  )
}

ErrorDialogFooter.propTypes = {
  classes: PropTypes.string,
  onClose: PropTypes.func,
  id: PropTypes.string.isRequired,
  hasExportButton: PropTypes.bool,
  onExport: PropTypes.func
}

/**
 * **************************************************************************************
 * Renders ErrorDialog - <ErrorDialog>
 * **************************************************************************************
 */
export const ErrorDialog = (props) => {
  const {
    title,
    children,
    open,
    onClose,
    id,
    icon,
    scroll,
    hasExportButton,
    onExport
  } = props

  const header = <ErrorDialogHeader id={`${id}_header`} title={title} classes="error-dialog-header" onClose={onClose} icon={icon} />

  const footer = (
    <ErrorDialogFooter
      id={`${id}_footer`}
      classes="error-dialog-footer"
      onClose={onClose}
      hasExportButton={hasExportButton}
      onExport={onExport}
    />
  )

  return (
    <CustomDialog
      header={header}
      footer={footer}
      open={open}
      onClose={onClose}
      classes="error-dialog"
      id={id}
      scroll={scroll}
    >
      <span className="error-dialog-message">
        {children}
      </span>
    </CustomDialog>
  )
}

ErrorDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  scroll: PropTypes.string,
  hasExportButton: PropTypes.bool,
  onExport: PropTypes.func
}

ErrorDialog.defaultProps = {
  open: false,
  icon: 'icon-warning',
  scroll: 'paper'
}
export default ErrorDialog