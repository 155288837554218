import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ActionButton from '../../../../components/buttons/ActionButton'
import i18n from '../../../../utils/i18n/i18n'

const StepFooter = (props) => {
  const {
    jumpToStep, closeAction, saveAction, currentStepIdex, isLast, isFirst, isValid
  } = props
  return (
    <Fragment>
      <div className="plans-wizard-navigation">
        <ActionButton
          id="wizard-navigation-cancel"
          componentClass="btn plans-wizard-navigation-cancel"
          onClickAction={() => closeAction()}
          text={i18n.t('Cancel')}
        />

        {!isFirst && (
          <ActionButton
            id="wizard-navigation-back"
            componentClass="btn plans-wizard-navigation-back"
            onClickAction={() => jumpToStep(currentStepIdex - 1)}
            text={i18n.t('Back')}
            icon="icon-arrow-left"
          />
        )}

        {isLast ? (
          <ActionButton
            id="wizard-navigation-save"
            componentClass="btn plans-wizard-navigation-save"
            onClickAction={() => saveAction()}
            text={i18n.t('Save Plan')}
            disable={!isValid}
          />
        ) : (
          <ActionButton
            id="wizard-navigation-continue"
            componentClass={`btn plans-wizard-navigation-continue ${''}`}
            onClickAction={() => jumpToStep(currentStepIdex + 1)}
            text={i18n.t('Continue')}
            icon="icon-arrow-right icon-left"
            disable={!isValid}
          />
        )}
      </div>
    </Fragment>
  )
}
StepFooter.propTypes = {
  currentStepIdex: PropTypes.number,
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  saveAction: PropTypes.func,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isValid: PropTypes.bool
}

StepFooter.defaultProps = {
  isLast: false,
  isFirst: false,
  isValid: true
}

export default StepFooter