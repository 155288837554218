import React, { Component } from 'react'
import PropTypes from 'prop-types'

/** css */
import './Tooltip.css'

export default class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.window = window
  }

  render() {
    const { className, children, source } = this.props
    return (
      <div className={`tooltip ${className}`}>
        <div className="tooltip__container">
          <div className="tooltip__content">
            {children}
          </div>
          <div className="tooltip__tip" />
        </div>
        <div className="tooltip__source">
          {source}
        </div>
      </div>
    )
  }
}
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  source: PropTypes.object.isRequired
}

Tooltip.defaultProps = {
  className: ''
}
