import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prefer-stateless-function
export default class InputDatePicker extends Component {
  render() {
    const { onClick, value, idName } = this.props
    return (
      <Fragment>
        <i className="icon-calendar" />
        <input
          type="text"
          id={idName}
          placeholder="dd / mm / aaaa"
          onClick={onClick}
          defaultValue={value}
          value={value}
          autoComplete="off"
          tabIndex="-1"
        />
      </Fragment>

    )
  }
}
InputDatePicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  idName: PropTypes.string
}
