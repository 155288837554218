const SortingDirection = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC'
})

export const PromotionType = Object.freeze({
  CATEGORY: 'CATEGORY',
  PARTNER: 'PARTNER',
  SKU: 'SKU'
})

export default SortingDirection
