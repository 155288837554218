/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { t } from 'i18next'
import { withStyles } from '@material-ui/core/styles'
import { bindAll } from 'lodash'

/** components */
import PlansGrid from './plans-grid/PlansGrid'
import SellersGrid from './seller-grid/SellersGrid'
import SortingDirection from '../../commons/Enums'
import PlanInsertWizard from './plans-insert-dialog/PlanInsertWizard'
import {
  getPlans, getPlan, postPlan, putPlan
} from '../../services/api/plans/PlansAction'
import { getPartnerPlan } from '../../services/api/sellers/SellersAction'
import getCategories from '../../services/api/categories/CategoriesAction'
import emitter from '../../commons/Emitter'
import FormattedPercentage from '../../components/commons/formater/FormattedPercentage'
import CommissionDetailListDialog from '../../components/dialogs/CommissionDetailListDialog'
import ErrorDialog from '../../components/commons/error-dialog/ErrorDialog'
import Closable from '../../components/closable/Closable'
import PageTitle from '../../components/page-title/PageTitle'

/** style */
import './Plans.css'
import i18n from '../../utils/i18n/i18n'

/** Tab styles */
const styles = () => ({
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    color: '#156992',
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#156992'
  },
  tabRoot: {},
  label: {
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: [
      'RobotoCondensed'
    ].join(',')
  }
})

/**
 * Renders the title for the Plans scene
 */
const PlansTitle = () => (
  <PageTitle
    title={t('Plans Grid')}
    description={t('Plans management for Marketplace\'s sellers')}
  />
)

/**
 * Renders the marketplace's standard commission
 */
const StandardMarketplaceCommission = (props) => {
  const { value } = props
  return (
    <div className="standard-commission">
      <span className="standard-commission-label">{t('Marketplace Standard Commission')}</span>
      <div className="standard-commission-separator" />
      <span className="standard-commission-value">
        {value ? <FormattedPercentage value={value} /> : <span>—</span>}
      </span>
    </div>
  )
}
StandardMarketplaceCommission.propTypes = {
  value: PropTypes.number
}

/**
 * Convert Plan to PlanRequest format
 * @param {*} wizardPlan
 */
const convertWizardPlanToPlan = (wizardPlan) => {

  const { stepPlan, stepCategories, stepPartners } = wizardPlan

  let planCustomCategories = []
  if (stepCategories != null && stepCategories.customCategories != null) {
    planCustomCategories = stepCategories.customCategories.map(customCategory => ({
      code: customCategory.code,
      name: customCategory.name,
      effect: {
        type: 'PERCENT',
        value: customCategory.planEffectValue
      }
    }))
  }

  let planPartners = []
  if (stepPartners != null && stepPartners.selectedPartners != null) {
    planPartners = stepPartners.selectedPartners.map(partner => (
      partner.sellerId
    ))
  }

  let planEffect = {}
  if (stepPlan != null) {
    planEffect = stepPlan.defaultRule != null ? ({
      type: 'PERCENT',
      value: stepPlan.defaultRule
    }) : null
  }

  const plan = {
    planId: wizardPlan.planId !== 0 ? wizardPlan.planId : null,
    name: stepPlan != null ? stepPlan.name : null,
    area: stepPlan != null ? stepPlan.area : null,
    effect: planEffect,
    description: stepPlan != null ? stepPlan.description : null,
    customCategories: planCustomCategories,
    partners: planPartners
  }
  return plan
}

const ErrorContent = (props) => {
  const { errors, plan } = props
  const nameError = errors.filter(e => e.field === 'name')
  const partnerErrors = errors.filter(e => e.field === 'partner')

  let isEdit = false
  if (plan != null && plan.planId != null && plan.planId > 0) {
    isEdit = true
  }

  return (
    <div className="commission-plans-dialog-error-content">
      <div className="commission-plans-dialog-error-text">
        {i18n.t(`The following problems must be fixed before that ${isEdit ? 'edit' : 'create'} grid`)}
      </div>

      {nameError.length > 0 && (
        <Fragment>
          <div className="commission-plans-dialog-error-title">
            {/* eslint-disable react/no-danger */}
            <span
              dangerouslySetInnerHTML={{ __html: i18n.t('The grid already exists', { plan: `<strong>"${nameError[0].identifier}"</strong>` }) }}
            />
          </div>

          <div className="commission-plans-dialog-error-text">
            {i18n.t('Every created grid must have a unique name that helps to define yours creations purpose')}
          </div>
          <div className="commission-plans-dialog-error-text bold">
            {i18n.t('Please, choose a name that is not in use.')}
          </div>
        </Fragment>
      )}

      {partnerErrors.length > 0 && (
        <Fragment>
          <div className="commission-plans-dialog-error-title bold">
            {i18n.t('The following partners already in use by another plan')}
          </div>
          <div className="commission-plans-dialog-error-text">
            {i18n.t('Every partner must be in once Plan. You should set that plan on partner profile in Partner Tab')}
          </div>
          <div className="commission-plans-dialog-error-text bold">
            {i18n.t('Please, remove the following partners on Add Partners Tab')}
          </div>
          <div className="commission-plans-dialog-error-partners">
            <div className="commission-plans-dialog-error-partners-title">{i18n.t('Seller')}</div>

            <div className="commission-plans-dialog-error-partners-content">
              {partnerErrors.map((partnerError) => {
                const partner = plan.stepPartners.selectedPartners
                  .find(p => p.sellerId === partnerError.identifier)
                return (
                  <div className="commission-plans-dialog-error-partners-item" key={partnerError.identifier}>
                    <strong>{partner.ecommerceId}</strong>
                    -
                    {partner.tradingName}
                  </div>
                )
              })}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}
ErrorContent.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  })),
  plan: PropTypes.shape({
    stepPlan: PropTypes.shape({
      name: PropTypes.string,
      area: PropTypes.string,
      defaultRule: PropTypes.number,
      description: PropTypes.string
    }),
    stepCategories: PropTypes.shape({
      categories: PropTypes.array,
      customCategories: PropTypes.array
    }),
    stepPartners: PropTypes.shape({
      selectedPartners: PropTypes.array
    }),
    planId: PropTypes.number
  })
}

/**
 * Renders the Plans scene
 */
class Plans extends Component {
  constructor(props) {
    super(props)

    this.state = {
      plansList: [],
      platformEffectValue: null,
      currentSortingField: 'createdOn',
      currentSortingDirection: SortingDirection.DESC,
      currentPartnerSortingField: 'tradingName',
      currentPartnerSortingDirection: SortingDirection.ASC,
      hasMorePlans: true,
      hasMoreSellers: true,
      totalOfPlans: 0,
      totalOfSellers: 0,
      tabSelected: 0,
      isOpenDialog: false,
      plan: {
        stepCategories: {
          categories: [],
          customCategories: []
        }
      },
      isCategoriesDialogOpen: false,
      isErrorInserDialogOpen: false,
      categoriesList: [],
      errorsList: [],
      showPlanSuccessMessage: false,
      sellers: [],
      isSaving: true
    }

    bindAll(this, [
      'updatePlans',
      'onSortingPlans',
      'onSortingSellers',
      'getPlansList',
      'getSellers',
      'setPlans',
      'openCategoriesDialog',
      'closeCategoriesDialog',
      'openInsertDialog',
      'closeInsertDialog',
      'savePlan',
      'openErrorInserDialog',
      'closeErrorInserDialog',
      'handleSaveError',
      'openPlanSuccessMessage',
      'closePlanSuccessMessage',
      'renderSellerGrid',
      'changeIsSaving'])
  }

  componentWillMount() {
    emitter.addListener('error', () => {
      this.setState({
        hasMorePlans: false,
        hasMoreSellers: false
      })
    })
  }

  componentWillUnmount() {
    emitter.removeAllListeners('error')
  }

  componentDidMount() {
    this.getPlansList(0)
  }

  /**
   * Calls the API for getting the list of plans
   */
  getPlansList(page) {
    const { currentSortingField, currentSortingDirection } = this.state
    const params = {
      limit: 10
    }
    params.offset = page || 0
    params.sortField = currentSortingField
    params.sortDirection = currentSortingDirection
    getPlans(params).then((response) => {
      this.updatePlans(response, params.offset)
    })
  }

  changeIsSaving(isSaving) {
    this.setState({
      isSaving
    })
  }

  /**
   * Calls the API for getting the list of partner plans
   */
  getSellers(page, filteredSeller) {
    const { currentPartnerSortingField, currentPartnerSortingDirection } = this.state
    const params = {
      limit: 10,
      offset: page || 0,
      sortField: currentPartnerSortingField,
      sortDirection: currentPartnerSortingDirection
    }
    if (filteredSeller) {
      params.partnerId = filteredSeller.sellerId
    }
    getPartnerPlan(params).then((response) => {
      this.updateSellers(response, params.offset)
    })
  }

  /**
   * Updates the state with the Plans API response
   */
  updatePlans(response, page) {
    if (response && response.plansList && response.paging) {
      const newPlansList = []
      const { paging } = response
      if (page === 0) {
        this.setState({
          plansList: []
        }, () => {
          response.plansList.map(plan => newPlansList.push(plan))
          this.setPlans(newPlansList, paging.total)
        })
      } else {
        const { plansList } = this.state
        plansList.map(plan => newPlansList.push(plan))
        response.plansList.map(plan => newPlansList.push(plan))
        this.setPlans(newPlansList, paging.total)
      }
    }
    if (response && response.platformEffectValue) {
      const { platformEffectValue } = response
      this.setPlatformEffectValue(platformEffectValue)
    }
  }

  /**
   * Updates the state with the Sellers API response
   */
  updateSellers(response, page) {
    if (response && response.sellers && response.paging) {
      const newSellers = []
      const { paging } = response
      if (page === 0) {
        this.setState({
          sellers: []
        }, () => {
          response.sellers.map(s => newSellers.push(s))
          this.setSellers(newSellers, paging.total)
        })
      } else {
        const { sellers } = this.state
        sellers.map(s => newSellers.push(s))
        response.sellers.map(s => newSellers.push(s))
        this.setSellers(newSellers, paging.total)
      }
    }
    if (response && response.platformEffectValue) {
      const { platformEffectValue } = response
      this.setPlatformEffectValue(platformEffectValue)
    }
  }

  /**
   * Sets the state values for plans
   */
  setPlans(plansList, total) {
    this.setState({
      plansList,
      hasMorePlans: (plansList.length < total),
      totalOfPlans: total
    })
  }

  /**
   * Sets the state values for sellers
   */
  setSellers(sellers, total) {
    this.setState({
      sellers,
      hasMoreSellers: (sellers.length < total),
      totalOfSellers: total
    })
  }

  /**
   * Sets the state value for the platformEffectValue
   */
  setPlatformEffectValue(platformEffectValue) {
    this.setState({
      platformEffectValue
    })
  }

  onSortingPlans(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.getPlansList(0)
    })
  }

  onSortingSellers(sortingField, sortingDirection) {
    this.setState({
      currentPartnerSortingField: sortingField,
      currentPartnerSortingDirection: sortingDirection
    }, () => {
      this.getSellers(0)
    })
  }

  openCategoriesDialog(categoriesList) {
    this.setState({
      isCategoriesDialogOpen: true,
      categoriesList
    })
  }

  closeCategoriesDialog() {
    this.setState({
      isCategoriesDialogOpen: false
    })
  }

  openInsertDialog(planId) {
    const plan = {}
    const filter = {}

    if (typeof planId === 'number') {
      filter.planId = planId
    }

    getCategories(filter).then((resultCategories) => {
      plan.stepCategories = {
        categories: resultCategories.categories,
        customCategories: resultCategories.customCategories
      }

      if (typeof planId === 'number') {
        getPlan(planId).then((resultPlan) => {
          plan.stepPartners = {
            selectedPartners: resultPlan.partners
          }
          plan.stepPlan = {
            name: resultPlan.name,
            area: resultPlan.area,
            defaultRule: resultPlan.effectValue,
            description: resultPlan.description
          }
          plan.planId = resultPlan.planId

          this.openDialogForInsertOrEditPlan(plan)
        })
      } else {
        this.openDialogForInsertOrEditPlan(plan)
      }
    })
  }

  openDialogForInsertOrEditPlan(plan) {
    this.setState({
      plan
    }, () => {
      this.setState({
        isOpenDialog: true
      })
    })
  }

  closeInsertDialog() {
    this.setState({
      isOpenDialog: false
    })
  }

  savePlan(plan) {
    this.setState({ plan })
    this.setState({
      isSaving: false
    }, () => {
      if (!plan.planId) {
        postPlan(convertWizardPlanToPlan(plan)).then((_response) => {
          this.setState({ isOpenDialog: false }, () => {
            this.changeIsSaving(true)
            this.openPlanSuccessMessage()
            this.getPlansList(0)
          })
        }).catch((error) => {
          this.changeIsSaving(true)
          this.handleSaveError(error)
        })
      } else {
        putPlan(convertWizardPlanToPlan(plan)).then((_response) => {
          this.setState({ isOpenDialog: false }, () => {
            this.changeIsSaving(true)
            this.openPlanSuccessMessage()
            this.getPlansList(0)
          })
        }).catch((error) => {
          this.handleSaveError(error)
          this.changeIsSaving(true)
        })
      }
    })
  }

  handleSaveError(error) {
    const { errors } = error.data.meta
    this.setState({
      errorsList: errors
    }, () => {
      this.openErrorInserDialog()
    })
  }

  openErrorInserDialog() {
    this.setState({ isErrorInserDialogOpen: true })
  }

  closeErrorInserDialog() {
    this.setState({ isErrorInserDialogOpen: false })
  }

  renderPlansGrid() {
    const {
      currentSortingField, currentSortingDirection, hasMorePlans, plansList, totalOfPlans
    } = this.state
    return (
      <PlansGrid
        loadItems={this.getPlansList}
        openCategoriesDialog={this.openCategoriesDialog}
        hasMore={hasMorePlans}
        total={totalOfPlans}
        plans={plansList}
        onSorting={this.onSortingPlans}
        currentSortingField={currentSortingField}
        currentSortingDirection={currentSortingDirection}
        openPlanDialog={plan => this.openInsertDialog(plan)}
      />
    )
  }

  renderSellerGrid() {
    const {
      currentPartnerSortingField, currentPartnerSortingDirection, sellers, hasMoreSellers, totalOfSellers
    } = this.state
    return (
      <SellersGrid
        loadItems={this.getSellers}
        openCategoriesDialog={this.openCategoriesDialog}
        hasMore={hasMoreSellers}
        total={totalOfSellers}
        sellers={sellers}
        onSorting={this.onSortingSellers}
        currentSortingField={currentPartnerSortingField}
        currentSortingDirection={currentPartnerSortingDirection}
      />
    )
  }

  closePlanSuccessMessage() {
    this.setState({
      showPlanSuccessMessage: false
    })
  }

  openPlanSuccessMessage() {
    this.setState({
      showPlanSuccessMessage: true
    })
  }

  render() {
    const {
      tabSelected, isOpenDialog, plan, platformEffectValue, isCategoriesDialogOpen, categoriesList,
      isErrorInserDialogOpen, errorsList, showPlanSuccessMessage, isSaving
    } = this.state
    const { classes } = this.props
    const gridLabel = t('Grid Plan')
    const sellerLabel = t('Grid Seller')

    let isEdit = false
    if (plan != null && plan.planId != null && plan.planId > 0) {
      isEdit = true
    }

    const tabHandleChange = (event, selected) => {
      this.setState({ tabSelected: selected })
      switch (selected) {
        case 0:
          this.getPlansList(0)
          break
        case 1:
          this.getSellers(0)
          break
        default:
          this.getPlansList(0)
      }
    }
    return (
      <div className="content commission-plans">
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={showPlanSuccessMessage}
            onClose={this.closePlanSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="commission-plans-save-success">
              <i className="icon-ok-check" />
              {i18n.t(`Commission Grid successfully ${isEdit ? 'edited' : 'created'}`)}
            </span>
          </Closable>
        </section>
        <PlansTitle />
        <StandardMarketplaceCommission value={platformEffectValue} />
        <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }} value={tabSelected} onChange={tabHandleChange}>
          <Tab classes={{ label: classes.label }} label={gridLabel} href="#plan-grid" />
          <Tab classes={{ label: classes.label }} label={sellerLabel} href="#seller-grid" />
        </Tabs>
        { tabSelected === 0 && this.renderPlansGrid() }
        { tabSelected === 1 && this.renderSellerGrid() }
        <CommissionDetailListDialog
          id="plans-categories-dialog"
          icon="icon-category"
          title={i18n.t('Plan categories')}
          items={categoriesList}
          isOpen={isCategoriesDialogOpen}
          onClose={() => this.closeCategoriesDialog()}
        />
        <PlanInsertWizard
          isOpen={isOpenDialog}
          onClose={() => this.closeInsertDialog()}
          onSave={(planToSave) => { this.savePlan(planToSave) }}
          editPlan={plan}
          isSaving={isSaving}
        />
        <ErrorDialog
          title={i18n.t(`Problems on ${isEdit ? 'Edit' : 'Create'} Grid`)}
          open={isErrorInserDialogOpen}
          onClose={() => this.closeErrorInserDialog()}
          id="commission-plans-dialog-error"
        >
          <ErrorContent errors={errorsList} plan={plan} />
        </ErrorDialog>
      </div>
    )
  }
}

Plans.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tabsRoot: PropTypes.string,
    tabsIndicator: PropTypes.string,
    tabRoot: PropTypes.string,
    label: PropTypes.string
  })
}

export default withStyles(styles)(Plans)