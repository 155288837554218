import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { t } from 'i18next'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PaymentReportsSummary from './summary/PaymentReportsSummary'
import SortingDirection from '../../../commons/Enums'
import PaymentReportsGrid from './report-grid/PaymentReportsGrid'
import getCycleInstances from '../../../services/api/cycle-instances/CurrentCycleInstanceAction'
import emitter from '../../../commons/Emitter'

/** Tab styles */
const styles = () => ({
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    color: '#156992',
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#156992'
  },
  tabRoot: {},
  label: {
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: [
      'RobotoCondensed'
    ].join(',')
  }
})

class PaymentReports extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cycleInstances: [],
      summary: {
        cycleInstance: null,
        error: null,
        type: null
      },
      isSummaryFetching: true,
      currentSortingField: 'endDate',
      currentSortingDirection: SortingDirection.DESC,
      hasMoreItems: true,
      total: 0,
      tabSelected: 0
    }

    bindAll(this, ['updateCycleInstances', 'onSorting', 'getCycleInstancesList', 'getSumary', 'setCycleInstances', 'setLoading'])
  }

  componentWillMount() {
    emitter.addListener('error', () => {
      this.setState({ isSummaryFetching: false })
    })
  }

  componentWillUnmount() {
    emitter.removeAllListeners('error')
  }

  componentDidMount() {
    this.getSumary()
    this.getCycleInstancesList(0)
  }

  getCycleInstancesList(page) {
    const { currentSortingField, currentSortingDirection } = this.state
    const params = {
      status: 'CLOSED',
      partnerCycleType: 'PAYMENT',
      limit: 10,
      withoutCurrent: true
    }
    params.offset = page || 0
    params.sortField = currentSortingField
    params.sortDirection = currentSortingDirection
    getCycleInstances(params).then((response) => {
      this.updateCycleInstances(response, params.offset)
    })
  }

  getSumary() {
    this.setState({
      isSummaryFetching: true
    }, () => {
      const params = {
        status: 'CLOSED',
        partnerCycleType: 'PAYMENT',
        offset: 0,
        limit: 1,
        sortField: 'endDate',
        sortDirection: 'DESC'
      }
      getCycleInstances(params).then((data) => {
        const response = data
        this.setState({
          summary: {
            cycleInstance: response.cycleInstancesList[0],
            type: data.type
          },
          isSummaryFetching: false
        })
      })
    })
  }

  updateCycleInstances(response, page) {
    if (response && response.cycleInstancesList && response.paging) {
      const newCycleInstances = []
      if (page === 0) {
        this.setState({
          cycleInstances: []
        }, () => {
          response.cycleInstancesList.map(cycleInstance => newCycleInstances.push(cycleInstance))
          this.setCycleInstances(newCycleInstances, response.paging.total)
        })
      } else {
        const { cycleInstances } = this.state
        cycleInstances.map(cycleInstance => newCycleInstances.push(cycleInstance))
        response.cycleInstancesList.map(cycleInstance => newCycleInstances.push(cycleInstance))
        this.setCycleInstances(newCycleInstances, response.paging.total)
      }
    }
  }

  setCycleInstances(cycleInstances, total) {
    this.setState({
      cycleInstances,
      hasMoreItems: (cycleInstances.length < total),
      total
    })
  }

  onSorting(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.getCycleInstancesList(0)
    })
  }

  setLoading(isSummaryFetching){
    this.setState({isSummaryFetching: isSummaryFetching})    
  }

  renderClosedCycles() {
    const {
      cycleInstances, currentSortingField, currentSortingDirection, hasMoreItems, total, summary, isSummaryFetching
    } = this.state
    return (
      <div>
        <PaymentReportsSummary isSummaryFetching={isSummaryFetching} summary={summary} loading={this.setLoading}/>
        <PaymentReportsGrid
          cycleInstances={cycleInstances}
          hasMore={hasMoreItems}
          loadItems={this.getCycleInstancesList}
          onSorting={this.onSorting}
          currentSortingField={currentSortingField}
          currentSortingDirection={currentSortingDirection}
          total={total}
          loading={this.setLoading}
        />
      </div>
    )
  }

  renderOpenCycles() {
    const { isSummaryFetching, cycleInstances } = this.state

    const summaryList = cycleInstances.map((cycleInstance) => {
      const summary = {
        cycleInstance,
        error: null,
        type: null
      }
      return summary
    })

    return summaryList.map((summary) => {
      const key = summary.cycleInstance.cycleInstanceId
      return (
        <PaymentReportsSummary
          isSummaryFetching={isSummaryFetching}
          summary={summary}
          disableReport
          key={key}
          loading={this.setLoading}
        />
      )
    })
  }

  getOpenCycles() {
    const params = {
      status: 'OPEN',
      partnerCycleType: 'PAYMENT',
      offset: 0,
      limit: 50,
      sortField: 'endDate',
      sortDirection: 'DESC'
    }

    getCycleInstances(params).then((response) => {
      this.setCycleInstances(response.cycleInstancesList, response.cycleInstancesList.length)
    })
  }

  getClosedCycles() {
    this.getSumary()
    this.getCycleInstancesList(0)
  }

  render() {
    const { tabSelected } = this.state
    const { classes } = this.props
    const closeLabel = t('Closed Cycles')
    const openLabel = t('Open Cycles')

    const tabHandleChange = (event, selected) => {
      this.setState({ tabSelected: selected })
      switch (selected) {
        case 0:
          this.getClosedCycles()
          break
        case 1:
          this.getOpenCycles()
          break
        default:
          this.getClosedCycles()
      }
    }

    return (
      <div className="content payment-reports">
        <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }} value={tabSelected} onChange={tabHandleChange}>
          <Tab classes={{ label: classes.label }} label={closeLabel} href="#closed-cycles" />
          <Tab classes={{ label: classes.label }} label={openLabel} href="#open-cycles" />
        </Tabs>
        { tabSelected === 0 && this.renderClosedCycles() }
        { tabSelected === 1 && this.renderOpenCycles() }
      </div>
    )
  }

}
PaymentReports.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tabsRoot: PropTypes.string,
    tabsIndicator: PropTypes.string,
    tabRoot: PropTypes.string,
    label: PropTypes.string
  }
  )
}

export default withStyles(styles)(PaymentReports)
