import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { bindAll } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import './SwitchOption.css'

function arrowGenerator(color) {
  return {
    opacity: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: '-34px',
      left: '-23px',
      marginTop: '23px',
      width: '21px',
      height: '20px',
      color: '#f5f5f9',
      backgroundColor: '#f5f5f9',
      position: 'absolute',
      transform: 'translateX(-50%) rotate(45deg)',
      boxShadow: '0 0 7px 3px rgba(0, 0, 0, 0.15)',
      zIndex: '3',
      '&::before': {
        borderWidth: '1.5em 1.5em 58px 1.5em',
        borderColor: `${color} transparent transparent transparent`,
        position: 'absolute',
        zIndex: '4',
        left: '14px',
        top: '1px',
        transform: 'rotate(-44deg)'
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: '50% !important',
      width: '20px',
      height: '20px',
      marginBottom: '-11px',
      color: '#f5f5f9',
      backgroundColor: '#f5f5f9',
      position: 'absolute',
      transform: 'translateX(-50%) rotate(45deg)',
      boxShadow: '0 0 7px 3px rgba(0, 0, 0, 0.15)',
      zIndex: '2',
      '&::before': {
        borderWidth: '0 1.5em 1.5em 1.5em',
        borderColor: `transparent transparent ${color} transparent`,
        position: 'absolute',
        zIndex: '1',
        left: '-23px',
        top: '-12px',
        transform: 'rotate(-45deg)'
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  }
}

const styles = theme => ({
  tooltipButton: {
    color: '#156992',
    fontFamily: 'RobotoCondensed',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    margin: theme.spacing.unit,
    textTransform: 'uppercase'
  },
  tooltipPopper: arrowGenerator('#f5f5f9'),
  tooltipContainer: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.2)',
    color: '#156992',
    fontFamily: 'RobotoCondensed',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: 215,
    padding: '16px 25px 15px 25px',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: theme.typography.pxToRem(16),
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  switchBar: {
    backgroundColor: '#A5CCEB !important',
    height: '5px',
    marginTop: '0px',
    marginBottom: '15px'
  },
  switchIcon: {
    width: '14px',
    height: '14px',
    color: '#2f9bd4',
    marginLeft: '-6px',
    marginRight: '6px'
  },
  switchIconChecked: {
    width: '14px',
    height: '14px',
    color: '#2f9bd4',
    marginLeft: '6px',
    marginRight: '-6px',
    marginTop: '2px'
  }
})

/**
 * This component provide a Switch select field with a confirmation message using Tooltip
 */
class SwitchOption extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      currentTooltipIsOpen: false,
      arrowRef: null
    }

    bindAll(this, [
      'openTooltip',
      'closeTooltip',
      'handleConfirm',
      'handleArrowRef'
    ])
  }

  handleArrowRef(node) {
    this.setState({
      arrowRef: node
    })
  }

  openTooltip() {
    const { onTooltipOpen, hasOpenTooltip, disable } = this.props
    if (!hasOpenTooltip && !disable) {
      this.setState({ currentTooltipIsOpen: true }, () => onTooltipOpen())
    }
  }

  closeTooltip() {
    const { onTooltipClose } = this.props
    this.setState({ currentTooltipIsOpen: false }, () => onTooltipClose())
  }

  async handleConfirm(identifier, newStatus) {
    const { onConfirm } = this.props
    return await this.closeTooltip() + await onConfirm(identifier, newStatus)
  }

  render() {
    const {
      identifier,
      status,
      disable,
      onClickAway,
      children,
      classes
    } = this.props

    const {
      currentTooltipIsOpen,
      arrowRef
    } = this.state

    return (
      <div>
        <ClickAwayListener onClickAway={onClickAway}>
          <Tooltip
            onClose={() => { this.closeTooltip() }}
            open={currentTooltipIsOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top"
            id={`tooltip_value_${identifier}`}
            classes={{
              popper: classes.tooltipPopper,
              tooltip: classes.tooltipContainer
            }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(arrowRef),
                    element: arrowRef
                  }
                }
              }
            }}
            title={(
              <div className="switch-option-popper-tooltip-message">
                {children}
                <div className="switch-option-popper-tooltip-buttons">
                  <Button className={[classes.tooltipButton, 'button-cancel'].join(' ')} onClick={() => { this.closeTooltip() }}>Não</Button>
                  <span className="button-separator">-</span>
                  <Button className={[classes.tooltipButton, 'button-confirm'].join(' ')} onClick={() => { this.handleConfirm(identifier, !status) }}>
                    Sim
                  </Button>
                  <span className={classes.arrow} ref={this.handleArrowRef} />
                </div>
              </div>
            )}
          >
            <div id={`switch_option_${identifier}`}>
              <Switch
                checked={status}
                onChange={() => { this.openTooltip() }}
                value={String(identifier)}
                disabled={disable}
                className="switch-option"
                classes={{
                  bar: classes.switchBar,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  disabled: classes.switchDisabled
                }}
                id={`switch_option_value_${identifier}`}
              />
            </div>
          </Tooltip>
        </ClickAwayListener>
      </div>
    )
  }
}
SwitchOption.propTypes = {
  /** The identifier of object that will send onConfirm */
  identifier: PropTypes.number.isRequired,
  /** The initial status of Switch */
  status: PropTypes.bool.isRequired,
  /** Exist another Tooltip open */
  hasOpenTooltip: PropTypes.bool.isRequired,
  /** The status of disable attribute on Switch */
  disable: PropTypes.bool.isRequired,
  /**
   * The function that will be call on confirm action
   * @param {*} identifier The identifier of changed option
   */
  onConfirm: PropTypes.func.isRequired,
  /** The function that will be call when Tooltip is closed */
  onTooltipClose: PropTypes.func.isRequired,
  /** The function that will be call when Tooltip is opened */
  onTooltipOpen: PropTypes.func.isRequired,
  /** The function that will be call click away of Switch */
  onClickAway: PropTypes.func.isRequired,
  /** The material-ui styles */
  classes: PropTypes.shape({}),
  /** The node with a Tooltip message */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default withStyles(styles)(SwitchOption)