import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import DatePicker from 'react-datepicker'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment-timezone'
import { CustomDialog } from '../../../components/commons'
import { CustomDialogFooter, CustomDialogHeader } from '../../../components/commons/dialog/CustomDialog'
import InputDatePicker from '../../../components/commons/input-date-picker/InputDatePicker'
import DropZone from '../../../components/dropzone/DropZone'
import i18n from '../../../utils/i18n/i18n'
import configs from '../../../settings/application'
import helperTextsPromoRules from './helperTextsPromoRules'
import { uploadPromotion } from '../../../services/api/promotions/PromotionsAction'

import MassivePromotionsErrorDialog from '../massive-promotions-error-dialog/MassivePromotionsErrorDialog'

import 'react-datepicker/dist/react-datepicker.css'
import './MassivePromotionInsertDialog.css'

const DEFAULT_LOCALE = configs.locale.default
const DEFAULT_TIMESTAMP = configs.format.timeStampParam

function createHelperLines(helperText) {
  const textLines = (
    <ol className="expansion-panel-helper">

      {helperText.texts.map((text, index) => {
        const line = [
          <li key={index.toString()} className={`expansion-panel-helper-text${helperText.isBold[index] ? ' bold' : ''}`}>
            <i className="icon-arrow-right" />
            {i18n.t(text)}
          </li>
        ]
        return line
      })}
    </ol>
  )
  return textLines
}
class MassivePromotionInsertDialog extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()

    bindAll(this, [
      'getInitialState',
      'isSaveEnabled',
      'handleClose',
      'handleStartDateChange',
      'handleEndDateChange',
      'onUpload',
      'onFileAdded',
      'getUploadRequest',
      'openErrorDialog',
      'closeErrorDialog',
      'changeIsSaving'
    ])
  }

  getInitialState() {
    return {
      startDate: undefined,
      endDate: undefined,
      isErrorInsertDialogOpen: false,
      errors: [],
      fileUpload: undefined,
      isSaving: false
    }
  }

  isSaveEnabled() {
    const {
      startDate, endDate, fileUpload, isSaving
    } = this.state

    return (
      startDate
      && endDate
      && fileUpload
      && !isSaving
    )
  }

  handleClose() {
    const { onClose } = this.props
    this.setState(this.getInitialState(), () => onClose())
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date
    })
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date
    })
  }

  openErrorDialog() {
    this.setState({ isErrorInsertDialogOpen: true })
  }

  closeErrorDialog() {
    this.setState({ isErrorInsertDialogOpen: false })
  }

  onFileAdded(file) {
    this.setState({
      fileUpload: file
    })
  }

  changeIsSaving(isSaving) {
    this.setState({ isSaving })
  }

  onUpload() {
    if (this.isSaveEnabled()) {
      this.setState({
        isSaving: true
      }, () => {
        const uploadRequest = this.getUploadRequest()
        const { showPromotionInsertMessage } = this.props
        uploadPromotion(uploadRequest.params, uploadRequest.file).then(() => {
          this.handleClose()
          showPromotionInsertMessage()
          this.changeIsSaving(false)
        }).catch((error) => {
          this.changeIsSaving(false)
          this.setState({
            errors: error.data.meta.errors
          }, () => this.openErrorDialog())
        })
      })
    }
  }

  getUploadRequest() {
    const { startDate, endDate, fileUpload } = this.state
    const formattedStartDate = moment(startDate).tz('GMT').format(DEFAULT_TIMESTAMP)
    const formattedendDate = moment(endDate).tz('GMT').format(DEFAULT_TIMESTAMP)
    const params = {
      startDate: formattedStartDate,
      endDate: formattedendDate
    }
    const file = new FormData()
    file.append('file', fileUpload)
    return {
      params,
      file
    }
  }

  render() {
    const {
      startDate, endDate, fileUpload, isErrorInsertDialogOpen, errors
    } = this.state
    const { isOpen } = this.props
    const startDateInput = <InputDatePicker idName="start_date" />
    const endDateInput = <InputDatePicker idName="end_date" />
    const header = (
      <CustomDialogHeader
        title={i18n.t('Create/Edit Promotional Commission')}
        icon="icon-promotional-comission"
        id="massive-promotion-insert-dialog-header"
        classes="massive-promotion-insert-dialog-header"
      />
    )

    const buttons = [{
      id: 'btnCancel',
      class: '',
      name: i18n.t('Cancel'),
      action: this.handleClose,
      key: 'cancelButton'
    }, {
      id: 'btnCreatePromotion',
      class: this.isSaveEnabled() ? 'btn-action' : 'disable',
      name: i18n.t('Add Promotion'),
      action: this.onUpload,
      key: 'createButton'
    }]

    const datePickerConfig = {
      dateFormat: 'dd/MM/yyyy HH:mm',
      timeFormat: 'HH:mm',
      timeIntervals: 30
    }
    return (
      <Fragment>
        <CustomDialog
          open={isOpen}
          onClose={this.handleClose}
          header={header}
          disableBackdropClick
          disableEscapeKeyDown
          scroll="body"
          id="massive-promotion-insert-dialog"
          className="massive-promotion-insert-dialog"
        >
          {i18n.t(helperTextsPromoRules.title)}
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<ExpandMoreIcon />}>
              <span>
                <i className="icon-light-on" />
                {i18n.t('See rules for creating/editing promotion via file upload.')}
              </span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {createHelperLines(helperTextsPromoRules.helperText)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="form-group wrap-input-text promotion-validity-container">
            <div id="startDateContainer" className="col-2 pull-left">
              <label htmlFor="startDate">{i18n.t('Promotion Start Date')}</label>
              <DatePicker
                selected={startDate}
                onChange={this.handleStartDateChange}
                showTimeSelect
                timeFormat={datePickerConfig.timeFormat}
                timeIntervals={datePickerConfig.timeIntervals}
                locale={DEFAULT_LOCALE}
                dateFormat={datePickerConfig.dateFormat}
                timeCaption={i18n.t('Time')}
                customInput={startDateInput}
              />
            </div>
            <div id="endDateContainer" className="col-2 pull-right">
              <label htmlFor="endDate">{i18n.t('Promotion End Date')}</label>
              <DatePicker
                selected={endDate}
                onChange={this.handleEndDateChange}
                showTimeSelect
                timeFormat={datePickerConfig.timeFormat}
                timeIntervals={datePickerConfig.timeIntervals}
                locale={DEFAULT_LOCALE}
                dateFormat={datePickerConfig.dateFormat}
                timeCaption={i18n.t('Time')}
                customInput={endDateInput}
              />
            </div>
          </div>
          <div className="form-group wrap-input-text">
            <DropZone onFileAdded={this.onFileAdded} file={fileUpload} accept=".xlsx" />
          </div>
          <CustomDialogFooter id="massivePromotionInsertDialogFooter" buttons={buttons} />
        </CustomDialog>

        <MassivePromotionsErrorDialog
          isOpen={isErrorInsertDialogOpen}
          onClose={() => { this.closeErrorDialog() }}
          dialogId="error-massice-promotion-upload"
          errors={errors}
        />
      </Fragment>
    )
  }
}
MassivePromotionInsertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showPromotionInsertMessage: PropTypes.func.isRequired
}
export default MassivePromotionInsertDialog
