import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

/** css */
import './CommissionReportsSummary.css'

/** components */
import LoadingLogo from '../../../../components/logos'
import ActionLink from '../../../../components/link/ActionLink'
import { CredentialManager } from '../../../../commons/CredentialManager'

import {
  CURRENCY, PERCENTAGE, TotalValueBox, DownloadBox, SummaryHeader
} from '../../commons/ReportInfo'
import { getConsololidatedReportDownload, getDetailedReportDownload } from '../../../../services/api/download/DownloadActions'

/**
 * This component renders the link for downloading the Consolidated Report
 */
const ConsolidatedDownloadLink = (props) => {
  const { cycleInstanceId, loading } = props

  const icon = <i className="icon-download" />

  const hasRoleDownloadConsolidated = CredentialManager.userHasRole('ROLE_DOWNLOAD_CONSOLIDATED')

  return hasRoleDownloadConsolidated ? (
    <ActionLink
      componentClass="report-link"
      text={t('Consolidated Commission')}
      icon={icon}
      id={`download_link_consolidated_${cycleInstanceId}`}
      onClickAction={
        () => getConsololidatedReportDownload(cycleInstanceId, loading)
      }
      url="#"
      disable={false}
    />
  ) : null
}
ConsolidatedDownloadLink.propTypes = {
  cycleInstanceId: PropTypes.number.isRequired
}

/**
 * This component renders the link for downloading the Detailed Report
 */
const DetailedDownloadLink = (props) => {
  const { cycleInstanceId, loading } = props

  const icon = <i className="icon-download" />

  const hasRoleDownloadDetailed = CredentialManager.userHasRole('ROLE_DOWNLOAD_FINANCIAL_CLOSE_ITEMS')

  return hasRoleDownloadDetailed && (
    <ActionLink
      componentClass="report-link"
      text={t('Detailed Report')}
      icon={icon}
      id={`download_link_detail_${cycleInstanceId}`}
      onClickAction={
        () => getDetailedReportDownload(cycleInstanceId, loading)
      }
      url="#"
      disable={false}
    />
  )
}
DetailedDownloadLink.propTypes = {
  cycleInstanceId: PropTypes.number.isRequired
}

// ------------------------------
// SUBCOMPONENT: SuccessGrid
// ------------------------------
const SuccessGrid = (props) => {
  const { cycleInstance, loading } = props
  const {
    cycleInstanceId, averageCommission, totalValue, saleCommission, returnCommission, totalCommission
  } = cycleInstance || {
    cycleInstanceId: 0, averageCommission: 0, totalValue: 0, saleCommission: 0, returnCommission: 0, totalCommission: 0
  }
  return (
    <div className="resume">
      <div className="summary-box">
        <div className="content-box">
          <div className="label-box">
            <h5 className="title"><Trans>Consolidated cycle in the period</Trans></h5>
          </div>
          <TotalValueBox
            className="average-commission"
            value={averageCommission}
            label="Average Commission"
            id={`average_commission_${cycleInstanceId}`}
            format={PERCENTAGE}
          />
          <TotalValueBox
            className="total-value"
            value={totalValue}
            label="Sales"
            info="SALES = Item + Frete"
            id={`sales_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <TotalValueBox
            className="sales-commission"
            value={saleCommission}
            label="Sales Commission"
            id={`sales_commission_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <TotalValueBox
            className="return-commission"
            value={returnCommission}
            label="Return Commission"
            id={`return_commission_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <TotalValueBox
            className="total-commission"
            value={totalCommission}
            label="Commission Balance"
            info="COMMISSION BALANCE = COMMISSION - RETURN"
            id={`commission_balance_${cycleInstanceId}`}
            format={CURRENCY}
          />

          <DownloadBox
            className="download-reports"
          >
            <ConsolidatedDownloadLink
              cycleInstanceId={cycleInstanceId}
              loading={loading}
            />
            <DetailedDownloadLink
              cycleInstanceId={cycleInstanceId}
              loading={loading}
            />
          </DownloadBox>
        </div>
      </div>
      {!cycleInstance
        && (
          <div className="grid_message_area">
            <div className="grid_message_box">
              <p><Trans>No display cycle</Trans></p>
            </div>
          </div>
        )
      }
    </div>
  )
}
SuccessGrid.propTypes = {
  cycleInstance: PropTypes.shape({
    cycleInstanceId: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    totalValue: PropTypes.number,
    totalCommission: PropTypes.number,
    totalPayment: PropTypes.number
  })
}

const Rows = (props) => {
  const { cycleInstance, isSummaryFetching, loading } = props
  if (isSummaryFetching) {
    return <LoadingLogo className="grid loading" />
  }
  return <SuccessGrid cycleInstance={cycleInstance} loading={loading} />
}
Rows.propTypes = {
  cycleInstance: PropTypes.shape({
    cycleInstanceId: PropTypes.number
  }),
  isSummaryFetching: PropTypes.bool.isRequired
}
Rows.defaultProps = {
  cycleInstance: undefined
}

// ------------------------------
// MAIN
// ------------------------------
const CommissionReportsSummary = (props) => {
  const { summary, isSummaryFetching, loading } = props
  const { cycleInstance } = summary
  const cycleInstanceId = cycleInstance ? cycleInstance.cycleInstanceId : undefined
  const startDate = cycleInstance ? cycleInstance.startDate : undefined
  const endDate = cycleInstance ? cycleInstance.endDate : undefined
  return (
    <div className="commission-management-report-summary">
      <SummaryHeader cycleInstanceId={cycleInstanceId} startDate={startDate} endDate={endDate} />
      <Rows cycleInstance={cycleInstance} isSummaryFetching={isSummaryFetching} loading={loading}/>
    </div>
  )
}

CommissionReportsSummary.propTypes = {
  isSummaryFetching: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    cycleInstance: PropTypes.shape({
      cycleInstanceId: PropTypes.number,
      cycle: PropTypes.shape({
        cycleId: PropTypes.number,
        name: PropTypes.string,
        enabled: PropTypes.bool
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
      totalValue: PropTypes.number,
      totalPayment: PropTypes.number,
      totalCommission: PropTypes.number,
      totalOrders: PropTypes.number
    })
  }).isRequired
}

export {
  SuccessGrid, Rows, CommissionReportsSummary, DetailedDownloadLink
}
export default CommissionReportsSummary
