import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../utils/i18n/i18n'

import './SellersGridInfo.css'

/**
 * **************************************************************************************
 * Renders Sellers Grid Info
 * This component renders the info of the sellers table
 * **************************************************************************************
*/
function SellersGridInfo(props) {
  const { actual, total } = props
  return (
    <div className="sellers-grid">
      <div className="sellers-grid-header">
        <div className="table-sellers-info-summary">
          {` ${i18n.t('Results')}: `}
          <span className="text-bold" id="actual_results">{actual}</span>
          {` ${i18n.t('Out of')} `}
          <span className="text-bold" id="total_results">{total}</span>
        </div>
      </div>
    </div>
  )
}
SellersGridInfo.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default SellersGridInfo