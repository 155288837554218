/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetCommissioning } from '../../../commons/Connection'
import { GET_CATEGORIES } from '../../../commons/Services'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

const getCategories = filter => doGetCommissioning(`${GET_CATEGORIES}${filter ? getParams(filter) : ''}`)

export default getCategories