const API_VERSION = 'v1'
//DOWNLOADS
export const GET_SAPHIRA_REPORT_DOWNLOAD = `${API_VERSION}/reports/financial-close/cycle-instance/`
export const GET_DETAILED_REPORT_DOWNLOAD = `${API_VERSION}/commissioning/reports/financial-close-items`
export const GET_CONSOLIDATED_REPORT_DOWNLOAD = `${API_VERSION}/commissioning/reports/consolidated`
export const GET_BILLING_ADJUSTMENTS_REPORT_DOWNLOAD = `${API_VERSION}/billing-adjustments/reports/detailed`
export const GET_PROMOTION_DOWNLOAD = `${API_VERSION}/promotions/{promotionId}/download`

//SERVICES
export const GET_CYCLE_INSTANCES = `${API_VERSION}/cycle-instances`
export const GET_BILLING_ADJUSTMENT_REASONS = `${API_VERSION}/billing-adjustment-reasons`
export const GET_BILLING_ADJUSTMENTS = `${API_VERSION}/billing-adjustments`
export const GET_SELLERS = `${API_VERSION}/sellers/search`
export const GET_PARTNER_PLAN = `${API_VERSION}/sellers/plans`
export const PUT_PARTNER_PLAN = `${API_VERSION}/partners/{partnerId}/plan`
export const POST_AUTHENTICATION = '/oauth/token'
export const GET_USER_INFO = 'v1/user-info'
export const GET_PLANS = `${API_VERSION}/plans`
export const GET_PLANS_NAMES = `${API_VERSION}/plans/summary`
export const GET_PLAN_BY_ID = `${API_VERSION}/plans`
export const POST_PLAN = `${API_VERSION}/plans`
export const PUT_PLAN = `${API_VERSION}/plans`
export const GET_CATEGORIES = `${API_VERSION}/categories`
export const GET_AUDIT = `${API_VERSION}/audits/{type}`
export const GET_PROMOTIONS = `${API_VERSION}/promotions`
export const UPLOAD_PROMOTION = `${API_VERSION}/promotions/upload-file`
export const GET_TOGGLES = `${API_VERSION}/toggles`
export const GET_SELLER_ACCOUNTS = `${API_VERSION}/sellers/accounts`
export const UPLOAD_SELLER_ACCOUNT = `${API_VERSION}/sellers/accounts/massive`
