import React, { Component } from 'react'
import { t } from 'i18next'
import { bindAll } from 'lodash'
import SortingDirection from '../../commons/Enums'
import emitter from '../../commons/Emitter'
import i18n from '../../utils/i18n/i18n'

import PageTitle from '../../components/page-title/PageTitle'
import SellerAccountGrid from './seller-account-grid/SellerAccountGrid'
import SellerAutoComplete from '../../components/auto-complete/seller-auto-complete/SellerAutoComplete'

import { getSellerAccounts } from '../../services/api/sellers/SellersAction'
import MassiveSellerAccountInsertDialog from './massive-seller-account-inser-dialog/MassiveSellerAccountInsertDialog'
import LoadingDialog from './massive-seller-account-inser-dialog/loading-dialog/LoadingDialog'
import Closable from '../../components/closable/Closable'

import './SellerAccount.css'

export const SellerAccountTitle = () => (
  <PageTitle
    title={t('Seller Account Creation')}
    description={t('Creation of seller account on payment splitter')}
  />
)

class SellerAccount extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sellerAccountList: [],
      currentSortingField: 'tradingName',
      currentSortingDirection: SortingDirection.ASC,
      hasMore: true,
      total: 0,
      selectedFilter: {},
      openMassiveInsertDialog: false,
      showSellerAccountInsertSuccessMessage: false,
      isProcessing: false
    }

    bindAll(this, [
      'setSellerAccounts',
      'updateSellersAccounts',
      'loadSellerAccountPage',
      'onSelectFilter',
      'onClearFilter',
      'onSorting',
      'openInsertDialog',
      'closeInsertDialog',
      'closeSellerAccountInsertSuccessMessage',
      'openSellerAccountInsertSuccessMessage',
      'openProcessingMessage',
      'closeProcessingMessage'
    ])
  }

  componentWillMount() {
    emitter.addListener('error', () => {
      this.setState({
        hasMore: false
      })
    })
  }

  componentDidMount() {
    this.loadSellerAccountPage(0)
  }

  /**
   * Function responsible for load seller accounts on API
   * @param {*} page Page to start read
   */
  loadSellerAccountPage(page) {
    const { currentSortingField, currentSortingDirection, selectedFilter } = this.state
    const params = {
      limit: 10
    }

    params.offset = page || 0
    params.sortField = currentSortingField
    params.sortDirection = currentSortingDirection

    if (selectedFilter.sellerId && selectedFilter.sellerId !== null) {
      params.partnerId = selectedFilter.sellerId
    }

    getSellerAccounts(params).then((response) => {
      this.updateSellersAccounts(response, params.offset)
    })
  }

  /**
   * Function responsible for update the current seller account list
   * @param {*} response API result response
   * @param {*} page current page
   */
  updateSellersAccounts(response, page) {
    if (response && response.sellerList && response.paging) {
      const newSellerAccountList = []
      const { paging } = response
      if (page === 0) {
        this.setState({
          sellerAccountList: []
        }, () => {
          response.sellerList.map(seller => newSellerAccountList.push(seller))
          this.setSellerAccounts(newSellerAccountList, paging.total)
        })
      } else {
        const { sellerAccountList } = this.state
        sellerAccountList.map(seller => newSellerAccountList.push(seller))
        response.sellerList.map(seller => newSellerAccountList.push(seller))
        this.setSellerAccounts(newSellerAccountList, paging.total)
      }
    }
  }

  /**
   * Function responsible for update seller account list and total on state
   * @param {*} sellerAccountList New list
   * @param {*} total New total
   */
  setSellerAccounts(sellerAccountList, total) {
    this.setState({
      sellerAccountList,
      hasMore: (sellerAccountList.length < total),
      total
    })
  }

  /**
    * Sets the state values for sorting and reload the promotions
    * @param {*} sortingField       The field used to sort list
    * @param {*} sortingDirection   The sorting direction
    */
  onSorting(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.loadSellerAccountPage(0)
    })
  }

  /**
   * Function responsible for filter sellers using seller's Autocomplete
   * @param {*} seller
   */
  onSelectFilter(seller) {
    this.setState({ selectedFilter: seller }, () => this.loadSellerAccountPage(0))
  }

  /**
   * Function responsible for clear seller's Autocomplete
   */
  onClearFilter() {
    this.setState({
      selectedFilter: {}
    }, () => {
      this.loadSellerAccountPage(0)
    })
  }

  /**
   * Function responsible to open de Insert Dialog
   */
  openInsertDialog() {
    this.setState({
      openMassiveInsertDialog: true
    })
  }

  /**
   * Function responsible to close de Insert Dialog
   */
  closeInsertDialog() {
    this.setState({
      openMassiveInsertDialog: false
    })
  }

  closeSellerAccountInsertSuccessMessage() {
    this.setState({
      showSellerAccountInsertSuccessMessage: false
    })
  }

  openSellerAccountInsertSuccessMessage() {
    this.setState({
      showSellerAccountInsertSuccessMessage: true
    }, () => this.loadSellerAccountPage(0))
  }

  closeProcessingMessage() {
    this.setState({
      isProcessing: false
    })
  }

  openProcessingMessage() {
    this.setState({
      isProcessing: true
    })
  }

  render() {
    const {
      selectedFilter, sellerAccountList, hasMore, total, currentSortingField,
      currentSortingDirection, openMassiveInsertDialog, showSellerAccountInsertSuccessMessage,
      isProcessing
    } = this.state

    return (
      <div className="content seller-account">
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={showSellerAccountInsertSuccessMessage}
            onClose={this.closeSellerAccountInsertSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="seller-account-insert-success">
              <i className="icon-ok-check" />
              {i18n.t('Processing file.')}
            </span>
          </Closable>
        </section>

        <section id="seller-title" className="seller-account-title">
          <SellerAccountTitle />
        </section>

        <section id="seller-filter" className="seller-account-filter">
          <SellerAutoComplete
            onSuggestionSelected={seller => this.onSelectFilter(seller)}
            onSuggestionsClearRequested={() => this.onClearFilter()}
            selectedSeller={selectedFilter || {}}
          />
        </section>

        <section id="seller-grid" className="seller-account-grid">
          <SellerAccountGrid
            loadItems={this.loadSellerAccountPage}
            openCommissionDetailsDialog={this.openCommissionDetailsDialog}
            hasMore={hasMore}
            total={total}
            sellerAccountList={sellerAccountList || []}
            onSorting={this.onSorting}
            currentSortingField={currentSortingField}
            currentSortingDirection={currentSortingDirection}
            openInsertDialog={() => { this.openInsertDialog() }}
          />
        </section>

        <MassiveSellerAccountInsertDialog
          isOpen={openMassiveInsertDialog}
          onClose={() => { this.closeInsertDialog() }}
          showSellerAccountInsertMessage={this.openSellerAccountInsertSuccessMessage}
          openProcessingMessage={this.openProcessingMessage}
          closeProcessingMessage={this.closeProcessingMessage}
        />

        <LoadingDialog
          isOpen={isProcessing}
          onClose={this.closeProcessingMessage}
        />
      </div>
    )
  }
}

export default SellerAccount
