import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll, remove, cloneDeep } from 'lodash'
import Autosuggest from 'react-autosuggest'
import getSellers from '../../../services/api/sellers/SellersAction'
import Loading from '../../loading/Loading'
import i18n from '../../../utils/i18n/i18n'

import './SellerAutoComplete.css'

const getSellerName = seller => seller.ecommerceId && `${seller.ecommerceId} - ${seller.tradingName}`

export default class SellerAutoComplete extends Component {

  constructor(props) {
    super(props)

    const { selectedSeller, selectedSellers } = this.props

    this.state = {
      loadingSellerAutoComplete: false,
      sellers: [],
      value: getSellerName(selectedSeller) || '',
      selectedSellers
    }

    bindAll(this, [
      'onSuggestionsFetchRequested',
      'onSuggestionsClearRequested',
      'onSuggestionSelected',
      'renderSuggestion',
      'isLoadingSeller',
      'onChange',
      'removeSelectedSellers'
    ])
  }

  componentWillReceiveProps(props) {
    const { selectedSeller } = props
    this.setState({
      value: getSellerName(selectedSeller) || ''
    })
  }

  isLoadingSeller() {
    this.setState({
      loadingSellerAutoComplete: true
    })
  }

  renderSuggestion(sugesstion) {
    const { loadingSellerAutoComplete } = this.state
    const content = loadingSellerAutoComplete
      ? <Loading isFetching={loadingSellerAutoComplete} />
      : (
        <Fragment>
          <span className="text-bold ">{sugesstion.ecommerceId}</span>
          &nbsp;
          -
          &nbsp;
          {sugesstion.tradingName}
        </Fragment>
      )
    return <div>{content}</div>
  }

  onSuggestionSelected(event, { suggestion }) {
    const sellerName = getSellerName(suggestion)
    this.setState({
      value: sellerName,
      selectedSeller: suggestion
    }, () => {
      const { onSuggestionSelected } = this.props
      onSuggestionSelected(suggestion)
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      sellers: []
    }, () => {
      const { selectedSeller } = this.state
      if (!selectedSeller) {
        const { onSuggestionsClearRequested } = this.props
        onSuggestionsClearRequested()
      }
    })
  }

  onSuggestionsFetchRequested(search) {
    const params = {
      ecommerceIdOrTradingName: search.value
    }

    if (search.value.length > 2 && search.reason === 'input-changed') {
      this.isLoadingSeller()

      getSellers(params).then((response) => {
        const resultSellers = this.removeSelectedSellers(response)
        this.setState({
          sellers: resultSellers,
          loadingSellerAutoComplete: false
        })
      })
    }
  }

  removeSelectedSellers(response) {
    const { selectedSellers } = this.state

    if (!selectedSellers) {
      return response
    }

    const result = cloneDeep(response)
    remove(result, resultSeller => selectedSellers.some(seller => seller.ecommerceId === resultSeller.ecommerceId))
    return result
  }

  onChange(event, { newValue, method }) {
    if (method === 'down') {
      return null
    }

    if (method === 'up') {
      return null
    }

    return this.setState({
      value: newValue,
      selectedSeller: null
    })
  }

  render() {
    const { className } = this.props
    const { sellers, value } = this.state

    const inputProps = {
      placeholder: i18n.t('Search by trading name or ecommerce id'),
      value,
      onChange: this.onChange
    }

    return (
      <div className={`form-group seller-input-text wrap-input-text clearfix ${className}`}>
        <i className="icon-search" />
        <Autosuggest
          id="seller"
          suggestions={sellers}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={() => {}}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    )
  }
}

SellerAutoComplete.propTypes = {
  selectedSeller: PropTypes.shape({
    tradingName: PropTypes.string,
    ecommerceId: PropTypes.string
  }),
  className: PropTypes.string,
  onSuggestionsClearRequested: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  selectedSellers: PropTypes.arrayOf(
    PropTypes.shape({
      tradingName: PropTypes.string,
      ecommerceId: PropTypes.string
    })
  )
}

SellerAutoComplete.defaultProps = {
  selectedSeller: {}
}
