import React from 'react'
import PropTypes from 'prop-types'
import { CustomDialogHeader, CustomDialog, CustomDialogFooter } from '../dialog/CustomDialog'
import i18n from '../../../utils/i18n/i18n'

import './WarningDialog.css'

/**
 * **************************************************************************************
 * Renders WarningDialogHeader - <WarningDialogHeader>
 * **************************************************************************************
 */
export const WarningDialogHeader = (props) => {
  const {
    title,
    classes,
    id,
    onClose
  } = props

  return (
    <CustomDialogHeader
      title={title}
      icon="icon-warning"
      classes={classes}
      onClose={onClose}
      id={id}
    />
  )
}

WarningDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

/**
 * **************************************************************************************
 * Renders WarningDialogFooter - <WarningDialogFooter>
 * **************************************************************************************
 */
export const WarningDialogFooter = (props) => {
  const { classes, onClose, id } = props

  const buttons = [{
    id: 'warning-dialog-btn-back',
    class: 'btn-warning',
    name: i18n.t('Back'),
    action: onClose,
    key: 'back-button'
  }]

  return (
    <CustomDialogFooter
      classes={classes}
      buttons={buttons}
      id={id}
    />
  )
}

WarningDialogFooter.propTypes = {
  classes: PropTypes.string,
  onClose: PropTypes.func,
  id: PropTypes.string.isRequired
}

/**
 * **************************************************************************************
 * Renders WarningDialog - <WarningDialog>
 * **************************************************************************************
 */
export const WarningDialog = (props) => {
  const {
    title,
    message,
    open,
    onClose,
    id
  } = props

  const header = <WarningDialogHeader id={`${id}_header`} title={title} classes="warning-dialog-header" onClose={onClose} />

  const footer = <WarningDialogFooter id={`${id}_footer`} classes="warning-dialog-footer" onClose={onClose} />

  return (
    <CustomDialog
      header={header}
      footer={footer}
      open={open}
      onClose={onClose}
      classes="warning-dialog"
      id={id}
    >
      <span className="warning-dialog-message">
        {message}
      </span>
    </CustomDialog>
  )
}

WarningDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string.isRequired
}

WarningDialog.defaultProps = {
  open: false
}
export default WarningDialog