import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../utils/i18n/i18n'

import './SellerAccountGridInfo.css'
import CredentialManager from '../../../commons/CredentialManager'

/**
 * **************************************************************************************
 * Renders Sellers Account Grid Info
 * This component renders the info of the sellers account table
 * **************************************************************************************
*/
function SellerAccountGridInfo(props) {

  const { actual, total, openInsertDialog } = props

  const createSellerAccountBt = (
    <span className="table-sellers-accounts-info-options-send-file" onClick={openInsertDialog}>
      <i className="icon-upload" />
      {i18n.t('Send File')}
    </span>
  )

  const hasRoleCreateSellerAccount = CredentialManager.userHasRole('ROLE_CREATE_SELLERS_ACCOUNTS')

  return (
    <div className="sellers-accounts-grid">
      <div className="sellers-accounts-grid-header">
        <div className="table-sellers-accounts-info-options">
          {hasRoleCreateSellerAccount && createSellerAccountBt}
          {hasRoleCreateSellerAccount && <span className="table-sellers-accounts-info-options-separator">.</span>}
        </div>
        <div className="table-sellers-accounts-info-summary">
          {`${i18n.t('Results')}: `}
          <span className="text-bold" id="actual_results">{actual}</span>
          {` ${i18n.t('Out of')}`}
          <span className="text-bold" id="total_results">{total}</span>
        </div>
      </div>
    </div>
  )
}
SellerAccountGridInfo.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  openInsertDialog: PropTypes.func.isRequired
}

export default SellerAccountGridInfo