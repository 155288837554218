import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import InputMoney from '../../../components/form-components/input-money/InputMoney'
import Select from '../../../components/form-components/select/Select'
import InputTextArea from '../../../components/form-components/input-text-area/InputTextArea'
import { CustomDialog, CustomDialogHeader, CustomDialogFooter } from '../../../components/commons/dialog/CustomDialog'
import getCycleInstanceBySeller from '../../../services/api/cycle-instances/CurrentCycleInstanceAction'
import WarningDialog from '../../../components/commons/warning-dialog/WarningDialog'


import { saveBillingAdjustment } from '../../../services/api/billing-adjustments/BillingAdjustmentAction'

import './BillingAdjustmentInsertDialog.css'
import i18n from '../../../utils/i18n/i18n'
import { FormattedDate } from '../../../components/commons'
import getBillingAdjustmentReasons from '../../../services/api/billing-adjustment-reasons/BillingAdjustmentReasonAction'
import SellerAutoComplete from '../../../components/auto-complete/seller-auto-complete/SellerAutoComplete'
import ErrorDialog from '../../../components/commons/error-dialog/ErrorDialog'

/**
 * **************************************************************************************
 * Render a select with DEBIT and CREDIT options - <AdjustmentTypeSelect>
 * **************************************************************************************
 */
const AdjustmentTypeSelect = (props) => {
  const {
    id, className, value, onChangeValue
  } = props

  const options = [
    {
      value: 'DEBIT',
      text: 'Débito'
    }, {
      value: 'CREDIT',
      text: 'Crédito'
    }
  ]

  return (
    <Select
      id={id}
      value={value}
      label={i18n.t('Adjustment Type')}
      placeholder={i18n.t('Select the Type')}
      className={className}
      options={options}
      onChangeValue={onChangeValue}
    />
  )
}

AdjustmentTypeSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChangeValue: PropTypes.func
}

/**
 * **************************************************************************************
 * Render a select with billingAdjustmentReasons options - <AdjustmentReasonsSelect>
 * **************************************************************************************
 */
const AdjustmentReasonsSelect = (props) => {
  const {
    id, className, adjustmentReasons, value, onChangeValue
  } = props

  const options = adjustmentReasons.map(reason => ({ value: reason.billingAdjustmentReasonId, text: reason.name }))

  return (
    <Select
      id={id}
      value={value}
      label={i18n.t('Reason')}
      className={className}
      options={options}
      onChangeValue={onChangeValue}
      placeholder={i18n.t('Select adjustment reason')}
    />
  )
}

AdjustmentReasonsSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  adjustmentReasons: PropTypes.arrayOf(PropTypes.shape({
    billingAdjustmentReasonId: PropTypes.number,
    name: PropTypes.string
  })),
  onChangeValue: PropTypes.func
}

AdjustmentReasonsSelect.defaultProps = {
  adjustmentReasons: []
}

export class BillingAdjustmentInsertDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSeller: {},
      billingAdjustment: {
        sellerId: null,
        type: null,
        value: 0,
        billingAdjustmentReasonId: null,
        description: '',
        cycleInstanceId: null
      },
      selectedCycleInstance: {
        cycleInstanceId: null,
        startDate: '',
        endDate: ''
      },
      openWarningDialog: false,
      openErrorDialog: false,
      billingAdjustmentReasons: [],
      isSaving: false,
      handleDisableButton: false
    }

    bindAll(this, [
      'onChangeValue',
      'onSave',
      'saveEnabled',
      'getCycleInstanceBySeller',
      'getBillingAdjustmentReasonsList',
      'onCloseWarningDialog',
      'onCloseErrorDialog',
      'onCloseDialog',
      'onSuggestionsClearRequested',
      'onSuggestionSelected',
      'changeIsSaving'
    ])
  }

  componentDidMount() {
    this.getBillingAdjustmentReasonsList()
  }

  getBillingAdjustmentReasonsList() {
    getBillingAdjustmentReasons().then((response) => {
      this.setState({
        billingAdjustmentReasons: response.billingAdjustmentReasonList
      })
    }).catch(() => {
      window.console.log('Error on getBillingAdjustmentReasons')
    })
  }

  getCycleInstanceBySeller(sellerId) {
    const params = {
      partnerId: sellerId,
      partnerCycleType: 'PAYMENT',
      status: 'OPEN',
      offset: 0,
      limit: 1,
      sortField: 'tradingName'
    }

    getCycleInstanceBySeller(params).then((response) => {
      if (response.cycleInstancesList.length > 0) {
        const cycleInstance = {
          cycleInstanceId: response.cycleInstancesList[0].cycleInstanceId,
          startDate: response.cycleInstancesList[0].startDate,
          endDate: response.cycleInstancesList[0].endDate
        }

        this.setState({
          selectedCycleInstance: cycleInstance
        })
        this.onChangeValue('cycleInstanceId', cycleInstance.cycleInstanceId)
      } else {
        this.setState({
          selectedSeller: {},
          selectedCycleInstance: {},
          openErrorDialog: true
        })
      }
    })
  }

  onChangeValue(key, value) {
    const { billingAdjustment } = this.state
    billingAdjustment[key] = value
    this.setState({
      billingAdjustment
    })
  }

  onSave() {
    if (this.saveEnabled()) {
      this.setState({
        isSaving: true,
        handleDisableButton: true
      }, () => {
        const { onClose, openBillingAdjustmentSuccessMessage } = this.props
        const { billingAdjustment } = this.state

        saveBillingAdjustment(billingAdjustment).then(() => {
          this.cleanForm()
          onClose()
          this.changeIsSaving(false)
          this.setState({
            handleDisableButton: false
          })
          openBillingAdjustmentSuccessMessage()
        }).catch((errors) => {
          this.changeIsSaving(false)

          function retrieveErrorMessage(message) {
            if (message == null) {
              return 'error.default.warning.message'
            } if (message.startsWith('seller with identifier')) {
              return 'Seller not found'
            } if (message.startsWith('Error on execute transfer for sourceId')) {
              return 'Error on execute transfer for target account'
            } if (message.startsWith('Seller')) {
              return 'Seller without stoneId'
            }
            return message
          }

          this.setState({
            warningMessage: retrieveErrorMessage(errors.data.meta.errors[0].message),
            openWarningDialog: true,
            handleDisableButton: false
          })
        })
      })
    }
  }

  changeIsSaving(isSaving) {
    this.setState({ isSaving })
  }

  saveEnabled() {
    const { billingAdjustment, isSaving } = this.state

    return (
      billingAdjustment.sellerId != null
      && billingAdjustment.type != null
      && billingAdjustment.value != null
      && billingAdjustment.value > 0
      && billingAdjustment.billingAdjustmentReasonId != null
      && billingAdjustment.description !== ''
      && !isSaving)
  }

  cleanForm() {
    this.setState({
      billingAdjustment: {
        sellerId: null,
        type: null,
        value: 0,
        billingAdjustmentReasonId: null,
        description: '',
        cycleInstanceId: null
      },
      selectedCycleInstance: {},
      selectedSeller: {}
    })
  }

  onCloseWarningDialog() {
    this.setState({
      openWarningDialog: false
    })
  }

  onCloseErrorDialog() {
    this.setState({
      openErrorDialog: false
    })
  }

  onCloseDialog() {
    const { onClose } = this.props
    this.cleanForm()
    onClose()
  }

  onSuggestionsClearRequested() {
    this.setState({
      selectedCycleInstance: {},
      selectedSeller: {}
    }, () => {
      this.onChangeValue('sellerId', null)
      this.onChangeValue('cycleInstanceId', null)
    })
  }

  onSuggestionSelected(seller) {
    this.setState({
      selectedSeller: seller
    })
    this.onChangeValue('sellerId', seller.sellerId)
    this.getCycleInstanceBySeller(seller.sellerId)
  }

  render() {
    const {
      billingAdjustment,
      selectedCycleInstance,
      billingAdjustmentReasons,
      openWarningDialog,
      openErrorDialog,
      selectedSeller,
      handleDisableButton,
      warningMessage
    } = this.state

    const { open } = this.props
    const { cycleInstanceId, startDate, endDate } = selectedCycleInstance

    const header = (
      <CustomDialogHeader
        title={i18n.t('Add Adjustment')}
        subtitle={i18n.t('All fields are required')}
        icon="icon-text-edit"
        id="billing-adjustment-insert-dialog-header"
      />
    )

    const buttons = [{
      id: 'billing-adjustment-insert-btn-cancel',
      class: handleDisableButton ? 'disable' : '',
      name: i18n.t('Cancel'),
      action: this.onCloseDialog,
      key: 'cancelButton',
      handleDisableButton
    }, {
      id: 'billing-adjustment-insert-btn-add',
      class: this.saveEnabled() ? 'btn-action' : 'disable',
      name: i18n.t('Add Adjustment'),
      action: this.onSave,
      key: 'addButton'
    }]
    const footer = <CustomDialogFooter buttons={buttons} id="billing-adjustment-insert-dialog-footer" />

    return (
      <CustomDialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        header={header}
        footer={footer}
        scroll="body"
        id="billing-adjustment-insert-dialog"
      >
        <WarningDialog
          title={i18n.t('Caution')}
          message={i18n.t(warningMessage)}
          onClose={this.onCloseWarningDialog}
          open={openWarningDialog}
          id="billing-adjustment-insert-dialog-error"
        />

        <ErrorDialog
          title={i18n.t('Error on getting cycle instances')}
          onClose={this.onCloseErrorDialog}
          open={openErrorDialog}
          id="billing-adjustment-insert-dialog-error-no-cycles"
        >
          {i18n.t('error.message.no.cycle.instance')}
        </ErrorDialog>

        <div className="form-group">
          <span className="form-description pull-right">{i18n.t('Only ADJUSTMENTS in open cycles are allowed')}</span>
        </div>

        <div className="form-group wrap-input-text">
          <label htmlFor="seller-auto-complete">{i18n.t('Seller')}</label>
          <SellerAutoComplete
            id="seller-auto-complete"
            selectedSeller={selectedSeller}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </div>
        <div className="form-group">
          <div className="form-info">
            <div className="form-info-cycle-instance">
              <span className="form-info-label">
                {i18n.t('Adjustment Cycle')}
                :
              </span>
              <span id="cycle-instance" className="form-info-value">{ cycleInstanceId || '--'}</span>
            </div>
            <div className="form-info-period pull-right">
              <span className="form-info-label">
                {i18n.t('Period')}
                :
              </span>
              <span id="period" className="form-info-value">
                {(startDate && endDate) ? (
                  <Fragment>
                    <span className="formatter-date text-bold">
                      {i18n.t('from')}
                      &nbsp;
                      <FormattedDate value={startDate} />
                      &nbsp;
                      {i18n.t('to')}
                      &nbsp;
                      <FormattedDate value={endDate} />
                    </span>
                  </Fragment>
                ) : '--'}
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-2 pull-left">
            <AdjustmentTypeSelect id="type" value={billingAdjustment.type} onChangeValue={this.onChangeValue} />
          </div>

          <div className="col-2 pull-right">
            <InputMoney id="value" value={billingAdjustment.value} label={i18n.t('Value')} onChangeValue={this.onChangeValue} />
          </div>
        </div>

        <div className="form-group">
          <AdjustmentReasonsSelect
            id="billingAdjustmentReasonId"
            value={billingAdjustment.billingAdjustmentReasonId}
            onChangeValue={this.onChangeValue}
            adjustmentReasons={billingAdjustmentReasons}
          />
        </div>

        <div className="form-group">
          <InputTextArea
            id="description"
            className="billing-adjustment-description"
            value={billingAdjustment.description}
            label={i18n.t('Description')}
            placeholder={i18n.t('Describe the reason of adjustment for this seller.')}
            onChangeValue={this.onChangeValue}
          />
        </div>
      </CustomDialog>
    )
  }
}

BillingAdjustmentInsertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openBillingAdjustmentSuccessMessage: PropTypes.func.isRequired
}

export default BillingAdjustmentInsertDialog