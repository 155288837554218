import React, { Component } from 'react'
import { bindAll } from 'lodash'
import emitter from '../../../commons/Emitter'
import i18n from '../../../utils/i18n/i18n'

import './Error.css'

const ERROR_SHOW_MILLISECONDS = 8000

class ErrorMessage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      message: ''
    }

    bindAll(this, ['openError', 'closeError'])
  }

  componentWillMount() {
    emitter.addListener('error', (message) => {
      this.openError(i18n.t(message))
    })

    emitter.addListener('download error', (message) => {
      this.openError(i18n.t(message))
    })
  }

  componentWillUnmount() {
    emitter.removeAllListeners('error')
  }

  openError(message) {
    this.setState({
      isOpen: true,
      message
    }, () => {
      setTimeout(this.closeError, ERROR_SHOW_MILLISECONDS)
    })
  }

  closeError() {
    const { isOpen } = this.state
    if (isOpen) {
      this.setState({
        isOpen: false
      })
    }
  }

  render() {
    const { isOpen, message } = this.state
    return (
      <div className={`error-message ${isOpen ? 'show' : 'hide'}-error`}>
        <div className="container">
          <i className="icon-server-error" />
          <span className="message">
            {message}
          </span>
          <i className="icon-close" onClick={this.closeError} />
        </div>
      </div>
    )
  }

}

export default ErrorMessage