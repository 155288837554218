import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

/** css */
import './PaymentReportsSummary.css'

/** components */
import LoadingLogo from '../../../../components/logos'
import ActionLink from '../../../../components/link/ActionLink'
import InsightIcon from '../../../../components/icons'
import Tooltip from '../../../../components/tooltip/Tooltip'
import { CredentialManager } from '../../../../commons/CredentialManager'

import {
  CURRENCY, PERCENTAGE, TotalValueBox, DownloadBox, SummaryHeader
} from '../../commons/ReportInfo'
import { getSaphiraReportDownload, getDetailedReportDownload } from '../../../../services/api/download/DownloadActions'

/**
 * This component renders the link for downloading the Saphira Report
 */
const SaphiraDownloadLink = (props) => {
  const { cycleInstanceId, disableReport, loading } = props

  const icon = <i className="icon-download" />

  const link = (
    <ActionLink
      componentClass="report-link"
      icon={icon}
      text={t('Saphira Report')}
      id={`download_link_saphira_${cycleInstanceId}`}
      onClickAction={
      () => !disableReport && getSaphiraReportDownload(cycleInstanceId, loading)
      }
      url="#"
      disable={disableReport}
    />
  )

  const tooltipMessage = disableReport
    ? 'Only closed cycles can be sent to Saphira.'
    : 'This file is formatted according to SAPHIRA system file upload.'

  const tIcon = (
    <Tooltip className="download" source={link}>
      <InsightIcon className="tooltip__insight-icon" />
      <span><Trans>{tooltipMessage}</Trans></span>
    </Tooltip>
  )

  const hasRoleDownloadFinancialClose = CredentialManager.userHasRole('ROLE_DOWNLOAD_FINANCIAL_CLOSE')

  return hasRoleDownloadFinancialClose && tIcon
}
SaphiraDownloadLink.propTypes = {
  cycleInstanceId: PropTypes.number.isRequired,
  disableReport: PropTypes.bool
}
SaphiraDownloadLink.defaultProps = {
  disableReport: false
}

/**
 * This component renders the link for downloading the Detailed Report
 */
const DetailedDownloadLink = (props) => {
  const { cycleInstanceId, loading } = props

  const icon = <i className="icon-download" />

  const hasRoleDownloadDetailed = CredentialManager.userHasRole('ROLE_DOWNLOAD_FINANCIAL_CLOSE_ITEMS')

  return hasRoleDownloadDetailed && (
    <ActionLink
      componentClass="report-link"
      text={t('Detailed Report')}
      icon={icon}
      id={`download_link_detail_${cycleInstanceId}`}
      onClickAction={
        () => getDetailedReportDownload(cycleInstanceId, loading)
      }
      url="#"
      disable={false}
    />
  )
}
DetailedDownloadLink.propTypes = {
  cycleInstanceId: PropTypes.number.isRequired
}

// ------------------------------
// SUBCOMPONENT: SuccessGrid
// ------------------------------
const SuccessGrid = (props) => {
  const { cycleInstance, disableReport, loading } = props
  const {
    cycleInstanceId, averageCommission, totalValue, totalCommission, totalPayment
  } = cycleInstance || {
    cycleInstanceId: 0, averageCommission: 0, totalValue: 0, totalCommission: 0, totalPayment: 0
  }
  return (
    <div className="resume">
      <div className="label-box">
        <h5 className="title"><Trans>Consolidated cycle in the period</Trans></h5>
      </div>
      <div className="summary-box">
        <div className="content-box">
          <TotalValueBox
            className="average-commission"
            value={averageCommission}
            label="Average Commission"
            id={`average_commission_${cycleInstanceId}`}
            format={PERCENTAGE}
          />
          <TotalValueBox
            className="total-value"
            value={totalValue}
            label="Revenue"
            info="REVENUE = SALE - RETURN"
            id={`total_value_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <TotalValueBox
            className="total-commission"
            value={totalCommission}
            label="Marketplace Commission"
            info="MARKETPLACE COMMISSION = COMMISSION - RETURNED COMISSION"
            id={`total_commission_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <TotalValueBox
            className="total-payment"
            value={totalPayment}
            label="Payment Value"
            info="PAYMENT VALUE = REVENUE - COMMISSION"
            id={`total_payment_${cycleInstanceId}`}
            format={CURRENCY}
          />
          <DownloadBox
            className="download-reports"
          >
            <SaphiraDownloadLink
              className="report-link"
              cycleInstanceId={cycleInstanceId}
              disableReport={disableReport}
              loading={loading}
            />
            <DetailedDownloadLink
              className="report-link"
              cycleInstanceId={cycleInstanceId}
              loading={loading}
            />
          </DownloadBox>
        </div>
      </div>
      {!cycleInstance
        && (
          <div className="grid_message_area">
            <div className="grid_message_box">
              <p><Trans>No display cycle</Trans></p>
            </div>
          </div>
        )
      }
    </div>
  )
}
SuccessGrid.propTypes = {
  cycleInstance: PropTypes.shape({
    cycleInstanceId: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    totalValue: PropTypes.number,
    totalCommission: PropTypes.number,
    totalPayment: PropTypes.number
  }),
  disableReport: PropTypes.bool
}
SuccessGrid.defaultProps = {
  disableReport: false
}

const Rows = (props) => {
  const { cycleInstance, isSummaryFetching, disableReport, loading } = props
  if (isSummaryFetching) {
    return <LoadingLogo className="grid loading" />
  }
  return <SuccessGrid cycleInstance={cycleInstance} disableReport={disableReport} loading={loading} />
}
Rows.propTypes = {
  cycleInstance: PropTypes.shape({
    cycleInstanceId: PropTypes.number
  }),
  isSummaryFetching: PropTypes.bool.isRequired,
  disableReport: PropTypes.bool
}
Rows.defaultProps = {
  cycleInstance: undefined,
  disableReport: false
}

// ------------------------------
// MAIN
// ------------------------------
const PaymentReportsSummary = (props) => {
  const { summary, isSummaryFetching, disableReport, loading } = props
  const { cycleInstance } = summary
  const cycleInstanceId = cycleInstance ? cycleInstance.cycleInstanceId : undefined
  const startDate = cycleInstance ? cycleInstance.startDate : undefined
  const endDate = cycleInstance ? cycleInstance.endDate : undefined
  return (
    <div className="payment-report-summary">
      <SummaryHeader cycleInstanceId={cycleInstanceId} startDate={startDate} endDate={endDate} />
      <Rows cycleInstance={cycleInstance} isSummaryFetching={isSummaryFetching} disableReport={disableReport} loading={loading} />
    </div>
  )
}

PaymentReportsSummary.propTypes = {
  isSummaryFetching: PropTypes.bool.isRequired,
  disableReport: PropTypes.bool,
  summary: PropTypes.shape({
    cycleInstance: PropTypes.shape({
      cycleInstanceId: PropTypes.number,
      cycle: PropTypes.shape({
        cycleId: PropTypes.number,
        name: PropTypes.string,
        enabled: PropTypes.bool
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
      totalValue: PropTypes.number,
      totalPayment: PropTypes.number,
      totalCommission: PropTypes.number,
      totalOrders: PropTypes.number
    })
  }).isRequired
}

PropTypes.defaultProps = {
  disableReport: false
}

export {
  SuccessGrid, Rows, PaymentReportsSummary, SaphiraDownloadLink, DetailedDownloadLink
}
export default PaymentReportsSummary
