import React from 'react'
import PropTypes from 'prop-types'

import './ActionLink.css'

const ActionLink = (props) => {
  const {
    componentClass, url, icon, text, id, disable, title, onClickAction, target
  } = props

  const hrefValue = !disable ? url : null
  const titleValue = !disable ? title : null

  return (
    <a
      className={`${componentClass} ${disable ? 'disable' : 'enable'}`}
      target={target}
      rel="noopener noreferrer"
      href={hrefValue}
      id={id}
      title={titleValue}
      onClick={onClickAction}
    >
      {icon || null}
      {text ? <span className="text-description">{text}</span> : null}
    </a>)
}
ActionLink.propTypes = {
  componentClass: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.node,
  url: PropTypes.string.isRequired,
  id: PropTypes.string,
  disable: PropTypes.bool,
  title: PropTypes.string,
  target: PropTypes.string,
  onClickAction: PropTypes.func
}

export default ActionLink
