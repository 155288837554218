
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../utils/i18n/i18n'

import './StepPartnerInfo.css'

class StepPartnerInfo extends PureComponent {

  render() {
    const { ecommerceId, tradingName } = this.props
    return (
      <section className="partner-wizard-info">
        <div className="partner-wizard-info-selected">
          <div className="partner-wizard-info-selected-title">{i18n.t('Seller')}</div>
          <div className="partner-wizard-info-selected-content">{`${ecommerceId} - ${tradingName}`}</div>
        </div>
        <div className="partner-wizard-info-warning">
          <div className="partner-wizard-info-warning-icon">
            <i className="icon icon-warning" />
          </div>
          <div className="partner-wizard-info-warning-content">
            {i18n.t('Commissions with custom values going to have priority over plan values on plan assigned that partner')}
          </div>
        </div>
      </section>
    )
  }
}
StepPartnerInfo.propTypes = {
  ecommerceId: PropTypes.string,
  tradingName: PropTypes.string
}

export default StepPartnerInfo