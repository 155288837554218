/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetCommissioning } from '../../../commons/Connection'
import { GET_CYCLE_INSTANCES } from '../../../commons/Services'

// const PAGE_LIMIT = 10

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

const getCycleInstances = filter => doGetCommissioning(`${GET_CYCLE_INSTANCES}${filter ? getParams(filter) : ''}`)

export const getCycleInstanceBySeller = filter => doGetCommissioning(`${GET_CYCLE_INSTANCES}${filter ? getParams(filter) : ''}`)

export default getCycleInstances
