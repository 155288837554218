/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetCommissioning, doPost, doPut } from '../../../commons/Connection'
import {
  GET_PLAN_BY_ID,
  GET_PLANS,
  GET_PLANS_NAMES,
  POST_PLAN,
  PUT_PLAN
} from '../../../commons/Services'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

export const getPlans = filter => doGetCommissioning(`${GET_PLANS}${filter ? getParams(filter) : ''}`)

export const getPlan = planId => doGetCommissioning(`${GET_PLAN_BY_ID}/${planId}`)

export const postPlan = plan => doPost(POST_PLAN, plan)

export const putPlan = plan => doPut(`${PUT_PLAN}/${plan.planId}`, plan)

export const getPlansNames = () => doGetCommissioning(GET_PLANS_NAMES)