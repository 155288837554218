import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import i18n from '../../../utils/i18n/i18n'
import Tooltip from '../../../components/tooltip/Tooltip'
import { FormattedCurrency, FormattedDate } from '../../../components/commons/index'
import FormattedPercentage from '../../../components/commons/formater/FormattedPercentage'
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid
} from '../../../components/table/Table'

/** css */
import './ReportInfo.css'

/**
 * **************************************************************************************
 * Renders Report Info
 * This component renders the info of a table
 * **************************************************************************************
*/
function ReportInfo(props) {
  const { actual, total } = props
  return (
    <div className="report-grid">
      <div className="header">
        <div className="title">{i18n.t('Audit')}</div>
        <div className="description">{i18n.t('Audit Description')}</div>
        <div className="result-summary">
          {` ${i18n.t('Results')}: `}
          <span className="text-bold" id="actual_results">{actual}</span>
          {` ${i18n.t('Out of')} `}
          <span className="text-bold" id="total_results">{total}</span>
        </div>
      </div>
    </div>
  )
}
ReportInfo.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

/**
 * **************************************************************************************
 * Renders Report Data
 * This component renders the data (headers and rows) of a table
 * **************************************************************************************
*/
function ReportData(props) {
  const {
    audits, hasMore, loadItems, onSorting, currentSortingField, currentSortingDirection, total,
    lines, sortingFields, titles, styleClasses, descriptions
  } = props
  return (
    <TableContainer>
      <Table>
        <TableHeader
          sortingFields={sortingFields}
          titles={titles}
          styleClasses={styleClasses}
          descriptions={descriptions}
          onSorting={onSorting}
          currentSortingField={currentSortingField}
          currentSortingDirection={currentSortingDirection}
        />
        {lines
          ? (
            <TableBodyInfinite
              hasMore={hasMore}
              loadItems={loadItems}
              loadingColSpan={12}
            >
              {lines}
            </TableBodyInfinite>
          ) : <RenderEmptyReportGrid displayMessage={i18n.t('No audits for display')} colSpan={titles.length} /> }
        <HasMore actual={audits.length > total ? total : audits.length} total={total} />
      </Table>
    </TableContainer>
  )
}
ReportData.propTypes = {
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  audits: PropTypes.arrayOf(
    PropTypes.shape({
      audit: PropTypes.shape({
        who: PropTypes.string,
        operation: PropTypes.string,
        module: PropTypes.string,
        data: PropTypes.string
      })
    })
  ).isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  lines: PropTypes.arrayOf(PropTypes.node),
  sortingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  styleClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired
}

/**
 * Constants used to define the format of values
 */
const CURRENCY = 'CURRENCY'
const PERCENTAGE = 'PERCENTAGE'

// ------------------------------
// SUBCOMPONENT: TotalValueBox
// ------------------------------
const TotalValueBox = (props) => {
  const {
    className, id, value, label, info, format
  } = props

  const icon = <i className="icon-info" />

  let tIcon = null

  if (info) {
    const xInfo = info ? info.split(' = ') : null

    tIcon = (
      <Tooltip className="info" source={icon}>
        <span className="assign">
          <Trans>{xInfo[0]}</Trans>
          &nbsp;=&nbsp;
        </span>
        <span className="operation"><Trans>{xInfo[1]}</Trans></span>
      </Tooltip>
    )
  }
  return (
    <div className={className}>
      <div className="total">
        <span className="value" id={id}>
          {format === CURRENCY && <FormattedCurrency value={value} />}
          {format === PERCENTAGE && <FormattedPercentage value={value} /> }
        </span>
        <div className="label">
          <Trans>{label}</Trans>
          {info ? tIcon : null}
        </div>
      </div>
    </div>
  )
}
TotalValueBox.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  info: PropTypes.string
}

const DownloadBox = (props) => {
  const { className, children } = props
  return (
    <div className={className}>
      {children}
    </div>
  )
}
DownloadBox.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

// ------------------------------
// SUBCOMPONENT: Header
// ------------------------------
const SummaryHeader = (props) => {
  const { startDate, endDate, cycleInstanceId } = props
  return (
    <div className="header">
      <div className="title">
        <Trans>Cycle</Trans>
        &nbsp;
        {cycleInstanceId}
      </div>
      <div className="description" id={`from_to_${cycleInstanceId}`}>
        <Trans>from</Trans>
        &nbsp;
        {startDate ? (<FormattedDate value={startDate} />) : (<Trans>Empty date</Trans>)}
        &nbsp;
        <Trans>to</Trans>
        &nbsp;
        {endDate ? (<FormattedDate value={endDate} />) : (<Trans>Empty date</Trans>)}
      </div>
    </div>
  )
}
SummaryHeader.propTypes = {
  cycleInstanceId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string
}
SummaryHeader.defaultProps = {
  cycleInstanceId: undefined
}

export {
  CURRENCY, PERCENTAGE, TotalValueBox, DownloadBox, SummaryHeader, ReportData
}

export default ReportInfo
