import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import emitter from './Emitter'

/** components */
import { CredentialManager } from './CredentialManager'

const auth = '/authentication'

class NavigationListener extends Component {

  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
    emitter.addListener('authenticate', () => {
      this.setState({ redirect: true })
      CredentialManager.logout()
    })
  }

  componentWillUnmount() {
    emitter.removeAllListeners('authenticate')
  }

  render() {
    const { redirect } = this.state
    if (redirect && auth !== window.location.pathname) {
      return (
        <Redirect to={auth} />
      )
    }
    return ('')
  }
}

NavigationListener.propTypes = {}

export default NavigationListener