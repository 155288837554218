/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetDataHub, doPost } from '../../../commons/Connection'
import { GET_BILLING_ADJUSTMENTS } from '../../../commons/Services'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

const getBillingAdjustments = filter => doGetDataHub(`${GET_BILLING_ADJUSTMENTS}${filter ? getParams(filter) : ''}`)

export const saveBillingAdjustment = billingAdjustment => doPost(`${GET_BILLING_ADJUSTMENTS}`, billingAdjustment)

export default getBillingAdjustments
