import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import StepZilla from 'react-stepzilla'
import { CustomDialog, CustomDialogHeader } from '../../../components/commons/dialog/CustomDialog'
import i18n from '../../../utils/i18n/i18n'

import StepPartner from './steps/StepPartner'
import StepCategories from './steps/StepCategories'
import { putPartnerPlan } from '../../../services/api/sellers/SellersAction'

import './SellerInsertWizard.css'

const getStepTitle = (id, classes, title, stepIndex, stepCurrent) => {
  let states = []
  if (stepCurrent === 0) {
    states = [[0, 'active'], [1, 'first']]
  } else {
    states = [[0, 'first'], [1, 'active']]
  }
  const stepColorStateMap = new Map(states)

  return (
    <div id={id} className={`partner-wizard-top ${classes} partner-wizard-top-${stepColorStateMap.get(stepIndex)}`}>
      {title}
    </div>
  )
}

const hasSelectedPlan = stepPartner => Number(stepPartner.planId) > 0

const convertPartner = (stepPartner, stepCategories) => {
  const customCategories = stepCategories.selectedCategories.map(category => ({
    id: category.id,
    code: category.code,
    name: category.name,
    effect: {
      type: 'PERCENT',
      value: category.partnerEffectValue
    }
  }))

  const result = {}
  if (hasSelectedPlan(stepPartner)) {
    result.planId = Number(stepPartner.planId)
  }
  result.effectValue = stepPartner.defaultCommission
  result.customCategories = customCategories
  return result
}

const getPlanId = partner => (partner.plan ? partner.plan.planId : 0)
const getDefaultCommission = partner => (partner.effect ? partner.effect.value : undefined)

class SellerInsertWizard extends Component {

  constructor(props) {
    super(props)

    const { partner } = props

    this.state = {
      sellerId: partner.seller.sellerId,
      ecommerceId: partner.seller.ecommerceId,
      tradingName: partner.seller.tradingName,
      stepCategories: {
        categories: [],
        customCategories: [],
        selectedCategories: []
      },
      stepPartner: {
        defaultCommission: getDefaultCommission(partner),
        planId: getPlanId(partner)
      },
      currentStep: 0
    }

    bindAll(this, ['updatePartner', 'updateCategories', 'onWizardClose', 'onWizardSave', 'changeCurrentStep'])
  }

  componentWillReceiveProps(props) {
    const { partner } = props

    this.setState({
      sellerId: partner.seller.sellerId,
      ecommerceId: partner.seller.ecommerceId,
      tradingName: partner.seller.tradingName,
      stepPartner: {
        defaultCommission: getDefaultCommission(partner),
        planId: getPlanId(partner)
      }
    })
  }

  updatePartner(stepPartner) {
    this.setState(stepPartner)
  }

  updateCategories(stepCategories) {
    this.setState(stepCategories)
  }

  onWizardClose() {
    const { onClose } = this.props
    this.setState({
      stepPartner: {},
      stepCategories: {
        categories: [],
        customCategories: [],
        selectedCategories: []
      }
    }, () => onClose())
  }

  onWizardSave() {
    const { openSuccessMessage, loadItems } = this.props
    const { sellerId, stepPartner, stepCategories } = this.state
    const newPartner = convertPartner(stepPartner, stepCategories)

    putPartnerPlan(sellerId, newPartner).then(() => {
      this.onWizardClose()
      openSuccessMessage()
      loadItems()
    })
  }

  changeCurrentStep(step) {
    this.setState({
      currentStep: step
    })
  }

  render() {
    const { isOpen, onClose } = this.props
    const {
      sellerId,
      ecommerceId,
      tradingName,
      currentStep,
      stepPartner,
      stepCategories
    } = this.state

    const steps = [
      {
        name: getStepTitle('wizzard-top-partner', 'partner-wizard-top-partner', i18n.t('Basic Information'), 0, currentStep),
        component: <StepPartner
          updatePartner={(u) => { this.updatePartner(u) }}
          closeAction={() => this.onWizardClose()}
          currentPartner={stepPartner || {}}
          stepInfo={{ ecommerceId, tradingName }}
        />
      }, {
        name: getStepTitle('wizzard-top-categories', 'partner-wizard-top-categories', i18n.t('Include Categories'), 1, currentStep),
        component: <StepCategories
          updateCategories={(u) => { this.updateCategories(u) }}
          categories={stepCategories ? stepCategories.categories : []}
          customCategories={stepCategories ? stepCategories.customCategories : []}
          closeAction={() => this.onWizardClose()}
          saveAction={() => this.onWizardSave()}
          stepInfo={{ sellerId, ecommerceId, tradingName }}
          currentPartner={stepPartner || {}}
          selectedCategories={stepCategories ? stepCategories.selectedCategories : []}
        />
      }
    ]

    const header = (
      <CustomDialogHeader
        title={i18n.t('Customize Commission Grid')}
        icon="icon-text-money"
        id="partner-wizard-header"
        classes="partner-wizard-header"
      />
    )

    return (
      <CustomDialog
        open={isOpen}
        onClose={onClose}
        header={header}
        disableBackdropClick
        disableEscapeKeyDown
        scroll="body"
        id="partner-wizard"
      >

        <StepZilla
          steps={steps}
          stepsNavigation={false}
          showNavigation={false}
          preventEnterSubmission
          onStepChange={(step) => { this.changeCurrentStep(step) }}
        />
      </CustomDialog>
    )
  }

}

SellerInsertWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  openSuccessMessage: PropTypes.func,
  loadItems: PropTypes.func,
  partner: PropTypes.shape({
    sellerId: PropTypes.number,
    ecommerceId: PropTypes.string,
    tradingName: PropTypes.string,
    plan: PropTypes.shape({
      planId: PropTypes.number,
      effect: PropTypes.shape({
        value: PropTypes.number
      })
    })
  })
}

export default SellerInsertWizard