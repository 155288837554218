import React from 'react'
import PropTypes from 'prop-types'
import { CustomDialog, CustomDialogHeader, CustomDialogFooter } from '../../../components/commons/dialog/CustomDialog'
import './BillingAdjustmentDescriptionDialog.css'
import i18n from '../../../utils/i18n/i18n'

/**
 * **************************************************************************************
 * Renders BillingAdjustmentDescriptionDialog - <BillingAdjustmentDescriptionDialog>
 * **************************************************************************************
 */
const BillingAdjustmentDescriptionDialog = (props) => {
  const { billingAdjustment, onClose, open } = props

  if (billingAdjustment.billingAdjustmentId == null) {

    return null
  }

  const header = (
    <CustomDialogHeader
      id="billing-adjustment-description-dialog-header"
      title={i18n.t('Description')}
      icon="icon-common-file-text-search"
      onClose={onClose}
    />
  )
  const buttom = [{
    class: 'close-button',
    name: i18n.t('Close'),
    action: onClose,
    key: billingAdjustment.billingAdjustmentId.toString()
  }]

  const footer = <CustomDialogFooter id="billing-adjustment-description-dialog-footer" buttons={buttom} />

  return (
    <CustomDialog id="billing-adjustment-description-dialog" header={header} footer={footer} open={open} onClose={onClose}>
      <div>
        <section>
          <span className="billing-adjustment-id">{i18n.t('Billing Adjustment Id')}</span>
          <span id="billing-adjustment-id-value">
            {billingAdjustment.billingAdjustmentId}
          </span>
          <br />
          <span className="seller">{i18n.t('Seller')}</span>
          <span id="seller-value">
            {`${billingAdjustment.seller.ecommerceId} - ${billingAdjustment.seller.tradingName}`}
          </span>
          <br />
          <span className="reason">{i18n.t('Reason')}</span>
          <span id="reason-value">
            {billingAdjustment.billingAdjustmentReason.name}
          </span>
          <br />
        </section>
        <br />
        <section>
          <p id="description-value">
            {billingAdjustment.description}
          </p>
        </section>

      </div>
    </CustomDialog>
  )
}
BillingAdjustmentDescriptionDialog.propTypes = {
  billingAdjustment:
    PropTypes.shape({
      billingAdjustmentId: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.number,
      description: PropTypes.string,
      billingId: PropTypes.number,
      createdOn: PropTypes.string,
      cycleInstance: PropTypes.shape({
        cycleInstanceId: PropTypes.number,
        startDate: PropTypes.string,
        endDate: PropTypes.string
      }),
      seller: PropTypes.shape({
        sellerId: PropTypes.number,
        ecommerceId: PropTypes.string,
        tradingName: PropTypes.string
      }),
      billingAdjustmentReason: PropTypes.shape({
        billingAdjustmentReasonId: PropTypes.number,
        name: PropTypes.string
      })
    }).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
}
export default BillingAdjustmentDescriptionDialog