import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../utils/i18n/i18n'
import { FormattedCNPJ } from '../../../components/commons/index'
import SellerAccountGridInfo from '../seller-account-grid-info/SellerAccountGridInfo'
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid, TableContent
} from '../../../components/table/Table'

import './SellerAccountGrid.css'

function buildNoResultMessage() {
  return (
    <div className="no-result-message">
      <i className="icon-common-file-warning" />
      {i18n.t('There are no sellers accounts to be shown.')}
    </div>
  )
}

function getAccountStatusClass(accountStatus) {
  if (accountStatus === 'NOT_CREATED') {
    return 'not-created'
  }

  if (accountStatus === 'ERROR') {
    return 'error'
  }

  return null
}

function getAccountCreatedIcon(accountStatus) {
  if (accountStatus === 'PROCESSING') {
    return <i className="icon-processing" />
  }

  if (accountStatus === 'ERROR') {
    return <i className="icon-warning" />
  }

  return null
}

function renderSellersAccounts(sellers) {
  if (!sellers || sellers.length === 0) {
    return null
  }

  return (sellers.map((seller) => {
    const {
      sellerId, ecommerceId, tradingName, cnpj, companyName, enabled, accountStatus
    } = seller
    const row = { key: sellerId, className: 'sellers-accounts-tr' }
    const sellerStatus = enabled ? 'active' : 'inactive'
    const accountCreatedIcon = getAccountCreatedIcon(accountStatus)
    const accountStatusClass = getAccountStatusClass(accountStatus)
    const columns = [{
      className: 'seller-account-tr-partner',
      value: (
        <span id={`partner_${sellerId}`}>
          {`${ecommerceId} - ${tradingName}`}
        </span>
      )
    },
    {
      className: 'seller-account-tr-cnpj',
      value: (
        <span id={`cnpj_${sellerId}`}>
          <FormattedCNPJ value={cnpj} />
        </span>
      )
    },
    {
      className: 'seller-account-tr-company-name',
      value: (
        <span id={`company-name_${sellerId}`}>
          {companyName}
        </span>
      )
    },
    {
      className: 'seller-account-tr-status',
      value: (
        <span id={`status_${sellerId}`}>
          <span className={`dot ${sellerStatus}`} />
          {i18n.t(sellerStatus, { context: 'female' })}
        </span>
      )
    },
    {
      className: 'seller-account-tr-created-account',
      value: (
        <span id={`created-account_${sellerId}`} className={accountStatusClass}>
          {accountCreatedIcon}
          {i18n.t(accountStatus)}
        </span>
      )
    }]

    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}

const SellerAccountGrid = (props) => {
  const {
    sellerAccountList,
    hasMore,
    loadItems,
    onSorting,
    currentSortingField,
    currentSortingDirection,
    total,
    openInsertDialog
  } = props

  const sortingFields = ['tradingName', 'cnpj', 'companyName', 'enabled', null]
  const titles = [i18n.t('Seller'), 'CNPJ', i18n.t('Company Name'), 'Status', i18n.t('C/C Account')]
  const styleClasses = ['seller-account th-trading-name', 'seller-account th-cnpj', 'seller-account th-company-name',
    'seller-account-created th-status', 'seller-account th-created-account']
  const descriptions = [null, null, null, null, null]
  const lines = renderSellersAccounts(sellerAccountList)

  return (
    <Fragment>
      <SellerAccountGridInfo
        actual={sellerAccountList.length > total ? total : sellerAccountList.length}
        total={total}
        openInsertDialog={openInsertDialog}
      />
      <TableContainer>
        <Table className="sellers-accounts-table">
          <TableHeader
            sortingFields={sortingFields}
            titles={titles}
            styleClasses={styleClasses}
            descriptions={descriptions}
            onSorting={onSorting}
            currentSortingField={currentSortingField}
            currentSortingDirection={currentSortingDirection}
          />
          {lines
            ? (
              <TableBodyInfinite
                hasMore={hasMore}
                loadItems={loadItems}
                loadingColSpan={12}
              >
                {lines}
              </TableBodyInfinite>
            )
            : (
              <RenderEmptyReportGrid
                displayMessage={buildNoResultMessage()}
                colSpan={titles.length}
              />
            )}
          <HasMore actual={sellerAccountList.length > total ? total : sellerAccountList.length} total={total} />
        </Table>
      </TableContainer>
    </Fragment>
  )
}

SellerAccountGrid.propTypes = {
  sellerAccountList: PropTypes.arrayOf(
    PropTypes.shape({
      sellerId: PropTypes.number,
      ecommerceId: PropTypes.string,
      tradingName: PropTypes.string,
      companyName: PropTypes.string,
      enabled: PropTypes.bool,
      accountStatus: PropTypes.string
    })),
  hasMore: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  openInsertDialog: PropTypes.func.isRequired
}

export default SellerAccountGrid