import React from 'react'
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import ActionButton from '../../buttons/ActionButton'

import './CustomDialog.css'

/**
 * **************************************************************************************
 * Renders CustomDialogHeader - <CustomDialogHeader>
 * **************************************************************************************
 */
export const CustomDialogHeader = (props) => {
  const {
    title,
    subtitle,
    icon,
    classes,
    id,
    onClose
  } = props

  const headerIcon = icon ? (<i className={`icon ${icon}`} />) : ''
  const headerSubtitle = subtitle ? (<span className="dialog-header-subtitle">{subtitle}</span>) : ''
  const headerCloseIcon = onClose ? <i id={`icon_close_${id}`} className="icon-close" onClick={onClose} /> : ''

  return (
    <div id={id} className={`dialog-header ${!isUndefined(classes) ? classes : ''}`}>
      <DialogTitle>
        {headerIcon}
        <span className="dialog-header-title">{title}</span>
        {headerCloseIcon}
        <div className="dialog-header-subtitle-area">
          {headerSubtitle}
        </div>
      </DialogTitle>
    </div>
  )
}

CustomDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  classes: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func
}

/**
 * **************************************************************************************
 * Renders CustomDialogFooter - <CustomDialogFooter>
 * **************************************************************************************
 */
export const CustomDialogFooter = (props) => {
  const { classes, buttons, id } = props

  return (
    <DialogActions>
      <div id={id} className={`dialog-footer ${!isUndefined(classes) ? classes : ''}`}>
        { buttons.map(button => (
          <ActionButton
            key={button.key}
            id={button.id}
            componentClass={`btn ${button.class}`}
            onClickAction={button.action}
            text={button.name}
            icon={button.icon}
          />
        ))}
      </div>
    </DialogActions>
  )
}

CustomDialogFooter.propTypes = {
  classes: PropTypes.string,
  id: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    class: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    key: PropTypes.string.isRequired,
    icon: PropTypes.string
  }))
}

/**
 * **************************************************************************************
 * Renders CustomDialog - <CustomDialog>
 * **************************************************************************************
 */
export const CustomDialog = (props) => {
  const {
    disableBackdropClick,
    disableEscapeKeyDown,
    id,
    classes,
    open,
    header,
    footer,
    children,
    onClose,
    scroll,
    className
  } = props

  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={onClose}
      scroll={scroll}
      className={`dialog-root ${className}`}
    >
      <div id={id} className={`dialog ${!isUndefined(classes) ? classes : ''}`}>
        {header}
        <div className="dialog-content">
          {children}
        </div>
        {footer}
      </div>
    </Dialog>
  )
}

CustomDialog.propTypes = {
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  id: PropTypes.string.isRequired,
  classes: PropTypes.string,
  open: PropTypes.bool,
  header: PropTypes.element,
  footer: PropTypes.element,
  children: PropTypes.node,
  onClose: PropTypes.func,
  scroll: PropTypes.string,
  className: PropTypes.string
}

CustomDialog.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  open: false,
  scroll: 'paper'
}
export default CustomDialog