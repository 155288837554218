import querystring from 'querystring'
import {
  doGetDataHub, doPatch, doPost, doUpload
} from '../../../commons/Connection'
import { GET_PROMOTIONS, UPLOAD_PROMOTION } from '../../../commons/Services'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

export const getPromotions = filter => doGetDataHub(`${GET_PROMOTIONS}${filter ? getParams(filter) : ''}`)

export const setPromotionState = promotion => doPatch(`${GET_PROMOTIONS}/${promotion.promotionId}`, promotion)

export const savePromotion = promotion => doPost(`${GET_PROMOTIONS}`, promotion)

export const uploadPromotion = (params, file) => doUpload(`${UPLOAD_PROMOTION}${params ? getParams(params) : ''}`, file)