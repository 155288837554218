import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import StepFooter from './StepFooter'
import i18n from '../../../../utils/i18n/i18n'
import SellerAutoComplete from '../../../../components/auto-complete/seller-auto-complete/SellerAutoComplete'
import ActionButton from '../../../../components/buttons/ActionButton'

import './StepPartners.css'

//-----------------------------------------------------------------------------------------
//  Item component
//-----------------------------------------------------------------------------------------
const Item = (props) => {
  const { partner, className, onRemove } = props

  return (
    <div className={`selected-partner-item ${className}`}>
      <div className="wrap-partner-info">
        <span className="partner-ecommerce-id">{partner.ecommerceId}</span>
        <span className="partner-separator"> - </span>
        <span className="partner-trading-name">{partner.tradingName}</span>
      </div>
      <i className="icon-close" onClick={() => onRemove(partner)} />
    </div>
  )
}

Item.propTypes = {
  partner: PropTypes.shape({
    id: PropTypes.number,
    tradingName: PropTypes.string,
    ecommerceId: PropTypes.string
  }),
  className: PropTypes.string,
  onRemove: PropTypes.func
}
//-----------------------------------------------------------------------------------------

class StepPartners extends Component {

  constructor(props) {
    super(props)
    const { selectedPartners } = this.props

    this.state = {
      selectedPartner: {},
      partners: selectedPartners
    }

    bindAll(this, ['onSelectSeller', 'onRemoveSeller', 'onSuggestionSelected', 'onSuggestionsClearRequested', 'onUpdatePartners'])
  }

  onUpdatePartners(state) {
    const { updatePlan } = this.props

    const newState = {
      ...this.state,
      state
    }

    this.setState(newState, () => updatePlan({
      stepPartners: {
        selectedPartners: newState.partners
      }
    }))
  }

  onSelectSeller() {
    const { selectedPartner, partners } = this.state

    if (selectedPartner.sellerId) {
      partners.push(selectedPartner)
      this.onUpdatePartners({
        partners
      })
      this.onSuggestionsClearRequested()
    }
  }

  onRemoveSeller(seller) {
    const { partners } = this.state

    const index = partners.indexOf(seller)
    partners.splice(index, 1)
    this.onUpdatePartners({
      partners
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      selectedPartner: {}
    })
  }

  onSuggestionSelected(seller) {
    this.setState({
      selectedPartner: seller
    })
  }

  getPartnersGrid() {
    const { partners } = this.state

    const rows = []
    const rowCount = partners.length / 2

    for (let row = 0; row < rowCount; row += 1) {
      const partnerLeft = partners[2 * row]
      const partnerRight = partners[2 * row + 1]

      const odd = row % 2
      const rowElement = (
        <div key={partnerLeft.sellerId} className="selected-partner-row">
          {partnerLeft && (
            <Item
              key={partnerLeft.sellerId}
              className={odd ? 'selected-partner-odd' : ''}
              partner={partnerLeft}
              onRemove={this.onRemoveSeller}
            />
          )}

          {partnerRight && (
            <Item
              key={partnerRight.sellerId}
              className={odd ? 'selected-partner-odd' : ''}
              partner={partnerRight}
              onRemove={this.onRemoveSeller}
            />
          )}
        </div>
      )

      rows.push(rowElement)
    }

    return rows
  }

  render() {
    const { partners, selectedPartner } = this.state
    const {
      jumpToStep, closeAction, saveAction, isSaving
    } = this.props

    const partnerList = partners.length > 0
      ? this.getPartnersGrid()
      : <div className="message-without-partners">{i18n.t('Without add store')}</div>

    return (
      <Fragment>
        <section id="search-seller" className="search-seller">
          <label htmlFor="seller">{i18n.t('Search seller')}</label>
          <div className="seller-auto-complete">
            <SellerAutoComplete
              id="seller"
              selectedSeller={selectedPartner}
              className="input-search-partners"
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              selectedSellers={partners}
            />

            <ActionButton
              icon="icon-add"
              componentClass="btn-add-partner"
              onClickAction={this.onSelectSeller}
              text={i18n.t('Add')}
            />
          </div>
        </section>

        <section id="selected-partner" className="selected-seller">
          <header className="step-partners-header">
            <div id="title">
              <h3 className="title">{i18n.t('Added Sellers')}</h3>
              <span className="counter">{ `(${partners.length})` }</span>
            </div>
            <div id="subtitle">
              <i className="icon-warning" />
              <span className="description">{i18n.t('A store can not enabled on more than on plan')}</span>
            </div>
          </header>
          <div className="selected-partner-list">
            { partnerList }
          </div>
        </section>

        <StepFooter
          currentStepIdex={2}
          jumpToStep={jumpToStep}
          closeAction={closeAction}
          saveAction={saveAction}
          isLast
          isValid={isSaving}
        />
      </Fragment>
    )
  }
}

export default StepPartners
StepPartners.propTypes = {
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  saveAction: PropTypes.func,
  updatePlan: PropTypes.func,
  selectedPartners: PropTypes.arrayOf(PropTypes.shape({
    tradingName: PropTypes.string,
    ecommerceId: PropTypes.string
  })),
  isSaving: PropTypes.bool
}