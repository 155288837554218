import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../utils/i18n/i18n'

/** components */
import { FormattedCurrency, FormattedDate } from '../../../../components/commons/index'
import FormattedPercentage from '../../../../components/commons/formater/FormattedPercentage'
import ActionLink from '../../../../components/link/ActionLink'
import ReportInfo, { ReportData } from '../../commons/ReportInfo'
import { CredentialManager } from '../../../../commons/CredentialManager'

import { TableContent } from '../../../../components/table/Table'

/** css */
import './PaymentReportsGrid.css'
import { getSaphiraReportDownload, getDetailedReportDownload } from '../../../../services/api/download/DownloadActions'

function renderCycleInstances(cycleInstances, loading) {
  if (!cycleInstances || cycleInstances.length === 0) {
    return null
  }
  const hasRoleDownloadFinancialClose = CredentialManager.userHasRole('ROLE_DOWNLOAD_FINANCIAL_CLOSE')
  const hasRoleDownloadDetailed = CredentialManager.userHasRole('ROLE_DOWNLOAD_FINANCIAL_CLOSE_ITEMS')

  return (cycleInstances.map((cycleInstance) => {
    const negativeTotalValue = (cycleInstance.totalValue < 0) ? 'negative' : ''
    const negativeTotalCommission = (cycleInstance.totalCommission < 0) ? 'negative' : ''
    const negativeTotalPayment = (cycleInstance.totalPayment < 0) ? 'negative' : ''
    const { cycleInstanceId, averageCommission } = cycleInstance
    const row = { key: cycleInstance.cycleInstanceId, className: 'cycleInstance-payment-tr' }
    const icon = <i className="icon-download" />
    const btnDownloadSaphiraReport = (
      <div className="download_link_saphira">
        <ActionLink
          componentClass="report-link"
          icon={icon}
          text={i18n.t('Saphira Report')}
          id={`download_link_saphira_${cycleInstanceId}`}
          onClickAction={
          () => getSaphiraReportDownload(cycleInstanceId, loading)
          }
          url="#"
          disable={false}
        />
      </div>
    )
    const btnDownloadDetailedReport = (
      <div>
        <ActionLink
          componentClass="report-link"
          text={i18n.t('Detailed Report')}
          icon={icon}
          id={`download_link_detail_${cycleInstanceId}`}
          onClickAction={
            () => getDetailedReportDownload(cycleInstanceId, loading)
          }
          url="#"
          disable={false}
        />
      </div>
    )

    const columns = [{
      className: 'cycleInstance-payment-tr-cycles',
      value: (
        <span id={`cycle_${cycleInstanceId}`}>
          {cycleInstanceId}
        </span>
      )
    },
    {
      className: 'cycleInstance-payment-tr-period',
      value: (
        <span id={`from_to_${cycleInstanceId}`}>
          {i18n.t('from')}
          &nbsp;
          <FormattedDate value={cycleInstance.startDate} />
          &nbsp;
          {i18n.t('to')}
          &nbsp;
          <FormattedDate value={cycleInstance.endDate} />
        </span>
      )
    },
    {
      className: 'cycleInstance-payment-tr-average-commission',
      value: (
        <span id={`average_commission_${cycleInstanceId}`}>
          <FormattedPercentage value={averageCommission} />
        </span>
      )
    },
    {
      className: `cycleInstance-payment-tr-billing ${negativeTotalValue}`,
      value: (
        <span id={`total_value_${cycleInstanceId}`}>
          <FormattedCurrency value={cycleInstance.totalValue} />
        </span>
      )
    },
    {
      className: `cycleInstance-payment-tr-total-commission ${negativeTotalCommission}`,
      value: (
        <span id={`total_commission_${cycleInstanceId}`}>
          <FormattedCurrency value={cycleInstance.totalCommission} />
        </span>
      )
    },
    {
      className: `cycleInstance-payment-tr-total-payment ${negativeTotalPayment}`,
      value: (
        <span id={`total_payment_${cycleInstanceId}`}>
          <FormattedCurrency value={cycleInstance.totalPayment} />
        </span>
      )
    },
    {
      className: 'cycleInstance-payment-tr-download',
      value: (
        <span>
          {hasRoleDownloadFinancialClose && btnDownloadSaphiraReport}
          {hasRoleDownloadDetailed && btnDownloadDetailedReport}
        </span>
      )
    }
    ]

    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}

const PaymentReportsGrid = (props) => {
  const {
    cycleInstances, hasMore, loadItems, onSorting, currentSortingField, currentSortingDirection, total, loading
  } = props
  const lines = renderCycleInstances(cycleInstances, loading)
  const sortingFields = ['cycleInstanceId', 'endDate', 'averageCommission', 'totalValue', 'totalCommission', 'totalPayment', null]
  const titles = [i18n.t('Cycles'), i18n.t('Period'), i18n.t('Average Commission'),
    i18n.t('Billing'), i18n.t('Commission'), i18n.t('Repayment'), null]
  const styleClasses = [null, 'th-left', null, null, null, null, null]
  const descriptions = [null, null, null, null, null, null]
  return (
    <div>
      <ReportInfo
        actual={cycleInstances.length > total ? total : cycleInstances.length}
        total={total}
      />
      <ReportData
        cycleInstances={cycleInstances}
        hasMore={hasMore}
        loadItems={loadItems}
        onSorting={onSorting}
        currentSortingField={currentSortingField}
        currentSortingDirection={currentSortingDirection}
        total={total}
        lines={lines}
        sortingFields={sortingFields}
        titles={titles}
        styleClasses={styleClasses}
        descriptions={descriptions}
      />
    </div>
  )
}
PaymentReportsGrid.propTypes = {
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  cycleInstances: PropTypes.arrayOf(
    PropTypes.shape({
      cycleInstanceId: PropTypes.number,
      cycle: PropTypes.shape({
        cycleId: PropTypes.number,
        name: PropTypes.string,
        enabled: PropTypes.bool
      }),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
      totalValue: PropTypes.number,
      totalPayment: PropTypes.number,
      totalCommission: PropTypes.number,
      totalOrders: PropTypes.number
    })
  ).isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired
}

export default PaymentReportsGrid