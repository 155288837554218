import 'normalize.css'

/** dependencies */
import React from 'react'
import ReactDOM from 'react-dom'
import {
  Router, Switch, Route, Redirect
} from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import createBrowserHistory from 'history/createBrowserHistory'
import ptBR from 'date-fns/locale/pt-BR'
import { registerLocale } from 'react-datepicker'
import i18n from './utils/i18n/i18n'
import Error from './components/commons/error/Error'
import configs from './settings/application'

/** css */
import './sass/main.css'

/** components */
import { Header } from './components'
import { CredentialManager } from './commons/CredentialManager'
import NavigationListener from './commons/NavigationListener'

/** scenes */
import {
  PaymentReports, NotFound, CommissionReports, BillingAdjustment,
  Authentication, Plans, Audit, Promotions, MassivePromotionsErrorPrint,
  MassiveSellersAccountsPrint, SellerAccount
} from './scenes/index'

/**
 *  React DatePicker Locale Configuration - <start>
 */

const DEFAULT_LOCALE = configs.locale.default

if (DEFAULT_LOCALE === 'pt-BR') {
  registerLocale('pt-BR', ptBR)
}

/**
 * React DatePicker Locale Configuration - <end>
 */

function RequireAuth(PageToRender) {
  return CredentialManager.userIsAuthenticated() ? (
    <main className="base-main-content base-main-pages">
      <NavigationListener />
      <Header />
      <PageToRender />
      <Error />
    </main>
  ) : (
    <Redirect to="/authentication" />
  )
}

function NoAuth(PageToRender) {
  return (
    <main className="base-main-content">
      <PageToRender />
      <Error />
    </main>
  )
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router history={createBrowserHistory()}>
      <Switch>

        {/* Rota alternativa para a home/dashboard */}
        <Route exact path="/">
          <Redirect to="/commission-management-reports" />
        </Route>

        {/* Rota para paginas com header */}
        <Route exact path="/payment-management-reports" render={() => RequireAuth(PaymentReports)} />
        <Route exact path="/commission-management-reports" render={() => RequireAuth(CommissionReports)} />
        <Route exact path="/billing-adjustments" render={() => RequireAuth(BillingAdjustment)} />
        <Route exact path="/plans" render={() => RequireAuth(Plans)} />
        <Route exact path="/promotions" render={() => RequireAuth(Promotions)} />
        <Route exact path="/audit" render={() => RequireAuth(Audit)} />
        <Route exact path="/sellers-account" render={() => RequireAuth(SellerAccount)} />

        {/* Rota para paginas sem o header */}
        <Route exact path="/promotions/massive-input/errors" render={() => NoAuth(MassivePromotionsErrorPrint)} />
        <Route exact path="/seller-accounts/massive-input/errors" render={() => NoAuth(MassiveSellersAccountsPrint)} />
        <Route exact path="/authentication" render={() => NoAuth(Authentication)} />
        <Route exact path="/404" render={NotFound} />

        {/* Se não encontrar a opção de URL */}
        <Redirect to="/404" />

      </Switch>
    </Router>
  </I18nextProvider>,
  document.getElementById('root'))
