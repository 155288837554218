export default {
  locale: {
    default: 'pt-BR',
    defaultCurrency: 'BRL'
  },
  format: {
    timeStamp: 'YYYY-MM-DDTHH:mm:ssZ',
    timeStampParam: 'YYYY-MM-DDTHH:mm:ss[Z]'
  }
}
