/* eslint react/no-danger: 0 */

import React from 'react'
import { padStart, uniqBy } from 'lodash'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import PropTypes from 'prop-types'
import i18n from '../../../utils/i18n/i18n'


const FileError = (props) => {
  const { title, errors } = props
  return errors.length > 0 && (
    <div className="error-title error-file">{title}</div>
  )
}
FileError.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  }))
}

const ExpansionError = (props) => {
  const {
    title, lines, defaultExpanded, disableExpansionPanel, className
  } = props
  const formattedLines = lines.map(line => padStart(line, 2, '0')).sort()

  return formattedLines.length > 0 && (
    <ExpansionPanel className={`${className} error-expansion-panel`} defaultExpanded={defaultExpanded} disabled={disableExpansionPanel}>
      <ExpansionPanelSummary className="error-expansion-panel-summary" expandIcon={<ExpandMoreIcon />} disabled={disableExpansionPanel}>
        <span>
          <strong>
            {padStart(formattedLines.length, 2, '0')}
            {' '}
            {`${i18n.t(formattedLines.length > 1 ? 'Lines' : 'Line')}`}
          </strong>
          {` ${i18n.t('with')} `}
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="error-expansion-panel-detail">
        <span
          className="error-lines"
        >
          <strong>
            {i18n.t(formattedLines.length > 1 ? 'Lines' : 'Line')}
            :
          </strong>
          {` ${formattedLines.join(', ')}`}
        </span>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
ExpansionError.propTypes = {
  title: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  ),
  defaultExpanded: PropTypes.bool,
  disableExpansionPanel: PropTypes.bool
}

ExpansionError.defaulProps = {
  defaultExpanded: false
}

const MassivePromotionsError = (props) => {
  const {
    errors, defaultExpanded, className, disableExpansionPanels
  } = props

  //Basic validation errors
  const basicEmptyValues = errors.filter(error => error.message === 'Not exist values')
  const basicDifferentPromotionType = errors.filter(error => error.message === 'Different types of promotion found')
  const basicDifferentCampaign = errors.filter(error => error.message === 'Different campaign found')
  const basicPartnerNotFound = errors.filter(error => error.message === 'Partner not found')

  //Date errors
  const fileStartDateInPast = errors.filter(error => error.field === 'campaign.startDateInPast')
  const fileInvalidDuration = errors.filter(error => error.field === 'campaign.invalidDuration')
  const filerEndDateBeforeStart = errors.filter(error => error.field === 'campaign.endDateBeforeStart')

  const totalFileErrors = basicEmptyValues.length
    + basicDifferentPromotionType.length
    + basicDifferentCampaign.length
    + basicPartnerNotFound.length
    + fileStartDateInPast.length
    + fileInvalidDuration.length
    + filerEndDateBeforeStart.length

  //Lenght errors
  let lengthErrors = []
  lengthErrors = lengthErrors.concat(errors.filter(error => error.field === 'sellerId.length'))
    .concat(errors.filter(error => error.field === 'sku.length'))
    .concat(errors.filter(error => error.field === 'categoryId.length'))
    .concat(errors.filter(error => error.field === 'campaign.length'))
    .concat(errors.filter(error => error.field === 'promoCommissionRules.effect.value.invalidRange'))
  lengthErrors = uniqBy(lengthErrors, 'identifier').map(error => error.identifier)

  //Line errors
  const lineSkuDuplicated = errors.filter(error => error.field === 'promoSku.duplicated').map(error => error.identifier)
  const lineSkuConflicted = errors.filter(error => error.field === 'promoSku.conflicted').map(error => error.identifier)
  const lineCategoryDuplicated = errors.filter(error => error.field === 'promoCategory.duplicated').map(error => error.identifier)
  const lineCategoryConflicted = errors.filter(error => error.field === 'promoCategory.conflicted').map(error => error.identifier)
  const linePartnerConflicted = errors.filter(error => error.field === 'partner.conflicted').map(error => error.identifier)
  const linePartnerDuplicated = errors.filter(error => error.field === 'rule.partner.duplicated').map(error => error.identifier)
  const lineEffectValueNull = errors.filter(error => error.field === 'promoCommissionRules.effect.notNull')
    .concat(errors.filter(error => error.field === 'sku.null'))
    .concat(errors.filter(error => error.field === 'category.null'))
    .concat(errors.filter(error => error.field === 'campaign'))
    .concat(errors.filter(error => error.field === 'promo.ecommerceId.notNull'))
    .map(error => error.identifier)
  const linePromotionNotFound = errors.filter(error => error.field === 'promotion.not.found').map(error => error.identifier)
  const linePartnerIdDifferent = errors.filter(error => error.field === 'partnerId.different').map(error => error.identifier)
  const linePromotionExpired = errors.filter(error => error.field === 'promotion.expired').map(error => error.identifier)
  const lineActivePromotionTypeChange = errors.filter(error => error.field === 'active.promotion.type.change').map(error => error.identifier)
  const lineActivePromotionCampaignChange = errors.filter(error => error.field === 'active.promotion.campaign.change').map(error => error.identifier)
  const lineActivePromotionStartDateChange = errors.filter(error => error.field === 'active.promotion.startDate.change')
    .map(error => error.identifier)

  const lineErrors = lengthErrors.length
    + lineSkuDuplicated.length
    + lineSkuConflicted.length
    + lineCategoryDuplicated.length
    + lineCategoryConflicted.length
    + linePartnerConflicted.length
    + linePartnerDuplicated.length
    + lineEffectValueNull.length
    + linePromotionNotFound.length
    + linePartnerIdDifferent.length
    + linePromotionExpired.length
    + lineActivePromotionTypeChange.length
    + lineActivePromotionCampaignChange.length
    + lineActivePromotionStartDateChange.length

  const typeOfLineErrors = [
    {
      title: i18n.t('Required fields must not be empty'),
      lines: lineEffectValueNull,
      className: 'lineEffectValueNull'
    }, {
      title: i18n.t('Active promotion start date must not change'),
      lines: lineActivePromotionStartDateChange,
      className: 'lineActivePromotionStartDateChange'
    }, {
      title: i18n.t('Expired promotion must not change'),
      lines: linePromotionExpired,
      className: 'linePromotionExpired'
    }, {
      title: i18n.t('Active promotion campaign must not change'),
      lines: lineActivePromotionCampaignChange,
      className: 'lineActivePromotionCampaignChange'
    }, {
      title: i18n.t('Active promotion type must not change'),
      lines: lineActivePromotionTypeChange,
      className: 'lineActivePromotionTypeChange'
    }, {
      title: i18n.t('Partner Id must not change'),
      lines: linePartnerIdDifferent,
      className: 'linePartnerIdDifferent'
    }, {
      title: i18n.t('Promotion Id not found'),
      lines: linePromotionNotFound,
      className: 'linePromotionNotFound'
    }, {
      title: i18n.t('Number of characters greater than allowed'),
      lines: lengthErrors,
      className: 'lengthErrors'
    }, {
      title: i18n.t('Duplicated SKUs for seller'),
      lines: lineSkuDuplicated,
      className: 'lineSkuDuplicated'
    }, {
      title: i18n.t('Conflicted SKUs'),
      lines: lineSkuConflicted,
      className: 'lineSkuConflicted'
    }, {
      title: i18n.t('Duplicated Category for seller'),
      lines: lineCategoryDuplicated,
      className: 'lineCategoryDuplicated'
    }, {
      title: i18n.t('Conflicted Category'),
      lines: lineCategoryConflicted,
      className: 'lineCategoryConflicted'
    }, {
      title: i18n.t('Conflicted Partner'),
      lines: linePartnerConflicted,
      className: 'linePartnerConflicted'
    }, {
      title: i18n.t('Duplicated Partner'),
      lines: linePartnerDuplicated,
      className: 'linePartnerDuplicated'
    }
  ]

  const typeOfFileErrors = [
    {
      title: i18n.t('The file is empty'),
      errors: basicEmptyValues,
      className: 'basicEmptyValues'
    }, {
      title: i18n.t('Different types of promotion found'),
      errors: basicDifferentPromotionType,
      className: 'basicDifferentPromotionType'
    }, {
      title: i18n.t('Different campaign found'),
      errors: basicDifferentCampaign,
      className: 'basicDifferentCampaign'
    }, {
      title: i18n.t('Partner not found'),
      errors: basicPartnerNotFound,
      className: 'basicPartnerNotFound'
    }, {
      title: i18n.t('The PROMOTION END DATE is less than START DATE.'),
      errors: filerEndDateBeforeStart,
      className: 'filerEndDateBeforeStart'
    }, {
      title: i18n.t('The PROMOTION START DATE must not be less than the CURRENT DATE.'),
      errors: fileStartDateInPast,
      className: 'fileStartDateInPast'
    }, {
      title: i18n.t('The promotion max allowed time is six months.'),
      errors: fileInvalidDuration,
      className: 'fileInvalidDuration'
    }
  ]

  return (
    <div className={className}>
      {totalFileErrors > 0 && (
        <section id="errors-file" className="error-group">
          <div className="error-text">
            {totalFileErrors > 1 ? i18n.t('Were found') : i18n.t('Was found')}
            {' '}
            <strong>
              {`${totalFileErrors} ${totalFileErrors > 1 ? i18n.t('problems') : i18n.t('problem')} `}
            </strong>
            {`${i18n.t('on sent file.')} ${i18n.t('Make the necessary adjustments and submit again the file, please')}`}
          </div>

          {typeOfFileErrors.map(error => (
            <FileError
              key={error.title}
              title={error.title}
              errors={error.errors}
              className={error.className}
            />
          ))}
        </section>
      )}

      {lineErrors > 0 && (
        <section id="errors-lines" className="error-group">
          <div className="error-text">
            {lineErrors > 1 ? i18n.t('Were found', { context: 'female' }) : i18n.t('Was found', { context: 'female' })}
            {' '}
            <strong>
              {lineErrors}
              {` ${lineErrors > 1 ? i18n.t('lines with problems') : i18n.t('line with problem')} `}
            </strong>
            {`${i18n.t('on sent file.')} ${i18n.t('Make the necessary adjustments and submit again the file, please')}`}
          </div>
          {typeOfLineErrors.map(error => (
            <ExpansionError
              key={error.title}
              title={error.title}
              lines={error.lines}
              className={error.className}
              defaultExpanded={defaultExpanded}
              disableExpansionPanel={disableExpansionPanels}
            />
          ))}
        </section>
      )}
    </div>)
}

MassivePromotionsError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  })),
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
  disableExpansionPanels: PropTypes.bool
}

MassivePromotionsError.defaultProps = {
  defaultExpanded: false,
  className: '',
  disableExpansionPanels: false
}

export default MassivePromotionsError