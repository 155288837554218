import React from 'react'

/** css */
import './LoggedUser.css'

/** components */
import User from '../../../user/User'
import CredentialManager from '../../../../commons/CredentialManager'

export default function UserMenu() {
  const { name, type } = CredentialManager.getAuthenticatedUser()
  const isAdmin = type === 'ADMIN'
  return (
    <User userName={name} isAdmin={isAdmin} className="logged-user" id="logged" />
  )
}
