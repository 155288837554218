import React from 'react'

/** css */
import './Title.css'

/** components */
import Logo from './logo/Logo'
import LoggedUser from './user/LoggedUser'
import Menu from './menu/Menu'

export default function Title() {
  return (
    <div className="header-title">
      <Logo />
      <LoggedUser />
      <Menu />
    </div>
  )
}
