import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll, isEqual } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import SortingDirection from '../../commons/Enums'
import { CheckComponentChildrenAreAllowed, CheckComponentHasChildren } from '../../commons/ComponentValidations'
import Loading from '../loading/Loading'

import './Table.css'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)

/**
 * **************************************************************************************
 * Renders EmptyReportGrid - <EmptyReportGrid>
 * **************************************************************************************
 */
const EmptyReportGrid = (props) => {
  const { children, colSpan } = props
  return (
    <TableBody>
      <TableRow key="footer" className="message-area">
        <TableColumn colSpan={colSpan} className="message-box">
          {children}
        </TableColumn>
      </TableRow>
    </TableBody>
  )
}
EmptyReportGrid.propTypes = {
  children: PropTypes.node,
  colSpan: PropTypes.number
}

/**
 * **************************************************************************************
 * Renders HeaderTitle - <HeaderTitle>
 * **************************************************************************************
 */
const HeaderTitle = (props) => {
  const { description, title } = props
  let descRendered = null
  if (description) {
    descRendered = (
      <Fragment>
        <br />
        <span className="description">{description}</span>
      </Fragment>)
  }

  return (
    <span>
      {title}
      {descRendered}
    </span>
  )
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

/**
 * **************************************************************************************
 * Renders HasMore - <HasMore>
 * **************************************************************************************
 */
const HasMore = (props) => {
  const { actual, total } = props
  if (actual < total) {
    return (
      <TableFoot>
        <TableRow key="footer">
          <TableColumn colSpan="100%">
            <i className="icon-arrow-bottom center" />
          </TableColumn>
        </TableRow>
      </TableFoot>
    )
  }
  return (
    <TableFoot />
  )
}
HasMore.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

/**
 * **************************************************************************************
 * Renders TableHeader - <TableHeader>
 * **************************************************************************************
 */
const TableHeader = (props) => {
  const {
    sortingFields, titles, descriptions, onSorting, currentSortingField, currentSortingDirection, styleClasses
  } = props
  let i = 0
  const headers = sortingFields.map((field, index) => {
    const title = titles[index]
    const styleClass = styleClasses[index]
    const description = descriptions[index]
    i += 1


    if (!field) {
      if (!title) {
        return (
          <TableHeaderColumn key={i} className={styleClass} />
        )
      }

      return (
        <TableHeaderColumn key={i} className={styleClass}>
          <HeaderTitle title={title} description={description} />
        </TableHeaderColumn>
      )
    }

    return (
      <TableHeaderColumnSortable
        key={field}
        onSorting={onSorting}
        sortingField={field}
        currentSortingField={currentSortingField}
        currentSortingDirection={currentSortingDirection}
        styleClass={styleClass}
      >
        <HeaderTitle title={title} description={description} />
      </TableHeaderColumnSortable>
    )
  })

  return (
    <TableHead className="table-head">
      <TableRow>
        {headers}
      </TableRow>
    </TableHead>
  )
}
TableHeader.propTypes = {
  sortingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  styleClasses: PropTypes.arrayOf(PropTypes.string)
}

/**
 * **************************************************************************************
 * Renders TableBodyInfinite - <tbody>
 * **************************************************************************************
 */
const TableBodyInfinite = (props) => {
  const {
    className, loadItems, hasMore, loadingColSpan, children
  } = props

  return (
    <InfiniteScroll
      pageStart={0}
      element="tbody"
      className={getClassName('table-tbody', className)}
      loadMore={loadItems}
      hasMore={hasMore}
      loader={(
        <TableRow key="loading">
          <TableColumn colSpan={loadingColSpan}>
            <Loading isFetching />
          </TableColumn>
        </TableRow>
        )}
    >
      {children}
    </InfiniteScroll>
  )
}
TableBodyInfinite.propTypes = {
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  loadingColSpan: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array
}

/**
 * Renders the sorting titles
 * @param {*} direction
 * @param {*} isSelected
 */
const renderSorting = (direction, isSelected) => {
  if (!isSelected) {
    return ['icon-arrow-bottom unselected', SortingDirection.ASC]
  }
  if (isEqual(direction, SortingDirection.ASC)) {
    return ['icon-arrow-bottom', SortingDirection.DESC]
  }
  return ['icon-arrow-top', SortingDirection.ASC]
}

/**
 * **************************************************************************************
 * Render TableHeaderColumn Sortable - a sortable <th>
 * This component controls the state to show sorting buttons.
 * **************************************************************************************
 */
class TableHeaderColumnSortable extends PureComponent {
  constructor(props) {
    super(props)

    bindAll(this, ['doSort'])
  }

  doSort(field, direction) {
    const { onSorting } = this.props
    onSorting(field, direction)
  }

  render() {
    const {
      children, sortingField, className, currentSortingField, currentSortingDirection, styleClass
    } = this.props
    const isSortingSelected = currentSortingField && isEqual(currentSortingField, sortingField)
    const defClasses = `table-th sortable ${styleClass || ''}`
    const classTh = isSortingSelected ? defClasses : `${defClasses} unselected`
    const [sortClass, sortDirection] = renderSorting(currentSortingDirection, isSortingSelected)
    return (
      <th className={getClassName(classTh, className)} id={`th_${sortingField}`} onClick={() => this.doSort(sortingField, sortDirection)}>
        {children}
        <i className={sortClass} />
      </th>
    )
  }
}
TableHeaderColumnSortable.propTypes = {
  className: PropTypes.string,
  sortingField: PropTypes.string.isRequired,
  currentSortingField: PropTypes.string,
  currentSortingDirection: PropTypes.string,
  onSorting: PropTypes.func.isRequired,
  children: PropTypes.node,
  styleClass: PropTypes.string
}

/**
 * **************************************************************************************
 * Render TableHeaderColumn - <th>
 * This component controls the state to show sorting buttons.
 * **************************************************************************************
*/
const TableHeaderColumn = (props) => {
  const { className, children } = props
  return (
    <th className={getClassName('table-th unselected', className)}>
      {children}
    </th>
  )
}
TableHeaderColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

/**
 * **************************************************************************************
 * Render TableColumn - <td>
 * **************************************************************************************
*/
const TableColumn = (props) => {
  const { className, colSpan, children } = props
  return (
    <td className={getClassName('table-td', className)} colSpan={colSpan || null}>
      {children}
    </td>
  )
}
TableColumn.propTypes = {
  className: PropTypes.string,
  colSpan: PropTypes.number,
  children: PropTypes.node
}

/**
 * **************************************************************************************
 * Renders TableRow - <tr>
 * **************************************************************************************
 */
const TableRow = (props) => {
  const { className, children } = props
  return (
    <tr className={getClassName('table-tr', className)}>
      {children}
    </tr>
  )
}
TableRow.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentHasChildren(componentName, React.Children.count(prop))
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children,
      [TableHeaderColumn.name, TableColumn.name, TableHeaderColumnSortable.name])
  }
}

/**
 * **************************************************************************************
 * Renders TableBody - <tbody>
 * **************************************************************************************
 */
const TableBody = (props) => {
  const { className, children } = props
  return (
    <tbody className={getClassName('table-tbody', className)}>
      {children}
    </tbody>
  )
}
TableBody.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentHasChildren(componentName, React.Children.count(prop))
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children, TableRow.name)
  }
}

/**
 * **************************************************************************************
 * Renders TableHead - <thead>.
 * Require at least one child, and the child allowed its just a TableHeader.
 * **************************************************************************************
 */
const TableHead = (props) => {
  const { className, children } = props
  return (
    <thead className={getClassName('table-thead', className)}>
      {children}
    </thead>
  )
}
TableHead.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentHasChildren(componentName, React.Children.count(prop))
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children, TableRow.name)
  }
}

/**
 * **************************************************************************************
 * Renders TableFoot - <tfoot>
 * **************************************************************************************
 */
const TableFoot = (props) => {
  const { className, children } = props
  return (
    <tfoot className={getClassName('table-tfoot', className)}>
      {children}
    </tfoot>
  )
}
TableFoot.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children, TableRow.name)
  }
}

/**
 * **************************************************************************************
 * Renders TableRows
 * **************************************************************************************
 */
const TableContent = (props) => {
  const { row, columns } = props
  const builtColumns = columns.map((column, index) => {
    const key = `${row.key}${index}`
    return (
      <TableColumn className={column.className} key={key}>
        {column.value}
      </TableColumn>
    )
  })

  return (
    <TableRow key={row.key} className={row.className}>
      {builtColumns}
    </TableRow>
  )
}
TableContent.propTypes = {
  row: PropTypes.shape({
    key: PropTypes.number,
    className: PropTypes.string
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    value: PropTypes.node
  }))
}

const RenderEmptyReportGrid = (props) => {
  const { displayMessage, colSpan } = props
  return (
    <EmptyReportGrid key="EmptyReportGrid" colSpan={colSpan}>
      {displayMessage}
    </EmptyReportGrid>
  )
}
RenderEmptyReportGrid.propTypes = {
  displayMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  colSpan: PropTypes.number.isRequired
}

/**
 * **************************************************************************************
 * Renders Table - <table>
 * This component render a table.
 * If you want a container outside of table, use this table as a child of TableContainer
 * Require at least one child. Allowed children are: TableHead, TableBody and TableFoot
 * **************************************************************************************
 */
const Table = (props) => {
  const { className, children } = props
  return (
    <div>
      <table className={getClassName('table', className)}>
        {children}
      </table>
      <div>&nbsp;</div>
    </div>
  )
}
Table.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    CheckComponentHasChildren(componentName, React.Children.count(prop))
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children,
      [TableHead.name, TableHeader.name, TableBody.name, TableBodyInfinite.name, HasMore.name, RenderEmptyReportGrid.name])
  }
}
/**
 * **************************************************************************************
 * Renders Table Container - <div>
 * This component render a <div> outside of table area, to look like a container.
 * **************************************************************************************
*/
const TableContainer = (props) => {
  const { className, children } = props
  return (
    <div className={getClassName('table-container', className)}>
      {children}
    </div>
  )
}
TableContainer.propTypes = {
  className: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName]
    const childrenQuantity = React.Children.count(prop)
    CheckComponentHasChildren(componentName, childrenQuantity)
    CheckComponentChildrenAreAllowed(componentName, prop, React.Children, [Table.name])
  }
}

/**
 * **************************************************************************************
 * Exporting components
 * **************************************************************************************
 */
export {
  TableContainer, Table, TableHead, TableBody, TableFoot, TableRow, HasMore, EmptyReportGrid,
  TableColumn, TableHeaderColumn, TableBodyInfinite, TableHeaderColumnSortable, TableHeader, TableContent, RenderEmptyReportGrid
}
