/* eslint prefer-promise-reject-errors: "off" */
/**
 * See more: https://eslint.org/docs/rules/prefer-promise-reject-errors
 */

import axios from 'axios'
import emitter from './Emitter'
import i18n from '../utils/i18n/i18n'
/** components */
import { CredentialManager } from './CredentialManager'

function getAuthorizationHeaders(config) {
  let conf = {}
  conf = config
  conf.headers.Authorization = `${CredentialManager.getUserToken().token_type} ${CredentialManager.getUserToken().access_token}`
  return conf
}

const RESPONSE_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 401,
  UNAUTHORIZED: 403,
  NOT_FOUND: 404,
  BUSINESS_EXCEPTION: 422,
  INTERNAL_SERVER_ERROR: 500
}

const axiosCommissioning = axios.create({
  baseURL: process.env.REACT_APP_C4_COMMISSIONING_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})

axiosCommissioning.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosDataHub = axios.create({
  baseURL: process.env.REACT_APP_MKTP_DATA_HUB_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})

axiosDataHub.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosSeller = axios.create({
  baseURL: process.env.REACT_APP_MKTP_SELLER_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})

axiosSeller.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosOrder = axios.create({
  baseURL: process.env.REACT_APP_MKTP_ORDER_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})

axiosOrder.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosInstanceUpload = axios.create({
  baseURL: process.env.REACT_APP_C4_COMMISSIONING_URL,
  headers: { 'Content-Type': 'multipart/form-data' }
})

axiosInstanceUpload.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosInstanceUploadSeller = axios.create({
  baseURL: process.env.REACT_APP_MKTP_SELLER_URL,
  headers: { 'Content-Type': 'multipart/form-data' }
})

axiosInstanceUploadSeller.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_MKTP_AUTH_URL,
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
})

axiosAuth.interceptors.request.use(
  getAuthorizationHeaders,
  error => Promise.reject(error)
)

const axiosInstanceAuthentication = axios.create({
  baseURL: process.env.REACT_APP_MKTP_AUTH_URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  mode: 'cors'
})

export const verifyMessage = (data) => {
  if (data) {
    const messageError = data.meta.errors[0].message === i18n.t(data.meta.errors[0].message)
      ? 'Server Bad Request.' : data.meta.errors[0].message
    return messageError
  }
  return 'Server Bad Request.'
}

export const onServerError = (data) => {
  emitter.emit('error', verifyMessage(data))
}

const onAccessDenied = (data) => {
  emitter.emit('authenticate', data)
}

const handleResponse = (status, data) => (
  new Promise((resolve, reject) => {
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject({ status, data })
      onServerError(data)
    } else if (status === RESPONSE_STATUS.FORBIDDEN) {
      onAccessDenied(data)
    } else if (status === RESPONSE_STATUS.UNAUTHORIZED) {
      onAccessDenied(data)
    } else if (status === RESPONSE_STATUS.BAD_REQUEST) {
      reject({ status, data })
    } else if (status === RESPONSE_STATUS.NOT_FOUND) {
      reject({ status, data })
      onServerError()
    } else if (status === RESPONSE_STATUS.BUSINESS_EXCEPTION) {
      reject({ status, data })
    } else {
      resolve(data)
    }
  })
)

export const handleError = (response, resolve, reject) => {
  if (response) {
    const { status, data } = response
    handleResponse(status, data)
      .then(resolve)
      .catch(reject)
  } else {
    onServerError()
  }
}

export const doGetCommissioning = url => (
  new Promise((resolve, reject) => {
    axiosCommissioning.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doGetDataHub = url => (
  new Promise((resolve, reject) => {
    axiosDataHub.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doGetSeller = url => (
  new Promise((resolve, reject) => {
    axiosSeller.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doGetOrder = url => (
  new Promise((resolve, reject) => {
    axiosOrder.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doPut = (url, body) => (
  new Promise((resolve, reject) => {
    axiosCommissioning.put(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doPatch = (url, body) => (
  new Promise((resolve, reject) => {
    axiosCommissioning.patch(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doPost = (url, body) => (
  new Promise((resolve, reject) => {
    axiosCommissioning.post(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doDelete = (url, body) => (
  new Promise((resolve, reject) => {
    axiosCommissioning.delete(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doUpload = (url, body) => (
  new Promise((resolve, reject) => {
    axiosInstanceUpload.post(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

export const doUploadSeller = (url, body) => (
  new Promise((resolve, reject) => {
    axiosInstanceUploadSeller.post(url, body)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)

const handleAuthentication = (status, data) => (
  new Promise((resolve, reject) => {
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject({ status, data })
      onServerError(data)
    } else if (status >= RESPONSE_STATUS.BAD_REQUEST) {
      reject({ status, data })
    } else {
      resolve(data)
    }
  })
)

const handleAuthenticationError = (error, reject) => {
  if (error) {
    const { status } = error.response ? error.response : { status: '' }
    if (!status || status >= RESPONSE_STATUS.INTERNAL_SERVER_ERROR) {
      reject(error)
      onServerError({ meta: { errors: [{ message: error.message }] } })
    } else {
      reject(error)
    }
  }
}

export const doAuthenticate = (url, auth) => (
  new Promise((resolve, reject) => {
    axiosInstanceAuthentication.post(url, auth)
      .then(({ status, data }) => {
        handleAuthentication(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(error => handleAuthenticationError(error, reject))
  })
)

export const doAuth = url => (
  new Promise((resolve, reject) => {
    axiosAuth.get(url)
      .then(({ status, data }) => {
        handleResponse(status, data)
          .then(resolve)
          .catch(reject)
      })
      .catch(({ response }) => {
        handleError(response, resolve, reject)
      })
  })
)
