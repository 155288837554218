import React, { Component } from 'react'
import { t } from 'i18next'
import { bindAll } from 'lodash'
import emitter from '../../commons/Emitter'
import SortingDirection from '../../commons/Enums'

import PageTitle from '../../components/page-title/PageTitle'
import { getPromotions, setPromotionState } from '../../services/api/promotions/PromotionsAction'
import PromotionsGrid from './promotions-grid/PromotionsGrid'
import CommissionDetailListDialog from '../../components/dialogs/CommissionDetailListDialog'
import i18n from '../../utils/i18n/i18n'
import PromotionInsertDialog from './promotion-insert-dialog/PromotionInsertDialog'
import Closable from '../../components/closable/Closable'


import './Promotions.css'
import MassivePromotionInsertDialog from './massive-promotion-insert-dialog/MassivePromotionInsertDialog'
/**
 * Renders the title for the Plans scene
 */
export const PromotionsTitle = () => (
  <PageTitle
    title={t('Promotions management')}
    description={t('Promotions management for Marketplace\'s sellers')}
  />
)
export default class Promotions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      promotionsList: [],
      currentSortingField: 'startDate',
      currentSortingDirection: SortingDirection.ASC,
      hasMore: true,
      total: 0,
      isCommissionDetailListDialogOpen: false,
      commissionDetails: [],
      isPromotionInsertDialogOpen: false,
      showPromotionInsertSuccessMessage: false,
      isMassivePromotionInsertDialogOpen: false,
      selectedFilter: {}
    }

    bindAll(this, [
      'loadPromotionsPage',
      'updatePromotionsList',
      'onSorting',
      'setPromotions',
      'updatePromotionState',
      'updatedPromotionStatusOnList',
      'openCommissionDetailsDialog',
      'closeCommissionDetailsDialog',
      'openInsertPromotionDialog',
      'closeInsertPromotionDialog',
      'closePromotionInsertSuccessMessage',
      'openPromotionInsertSuccessMessage',
      'openMassiveInsertPromotionDialog',
      'closeMassiveInsertPromotionDialog',
      'onSelectFilter',
      'onClearFilter'
    ])

  }

  componentWillMount() {
    emitter.addListener('error', () => {
      this.setState({
        hasMore: false
      })
    })
  }

  componentWillUnmount() {
    emitter.removeAllListeners('error')
  }

  componentDidMount() {
    this.loadPromotionsPage(0)
  }

  /**
   * Calls the API for load the list of promotions by page
   * @param {*} page              The number of page to load promotions
   */
  loadPromotionsPage(page) {
    const { currentSortingField, currentSortingDirection, selectedFilter } = this.state
    const params = {
      limit: 10
    }
    params.offset = page || 0
    params.sortField = currentSortingField
    params.sortDirection = currentSortingDirection

    if (selectedFilter && selectedFilter.selectedSeller && selectedFilter.selectedSeller.sellerId) {
      params.partnerId = selectedFilter.selectedSeller.sellerId
    }

    getPromotions(params).then((response) => {
      this.updatePromotionsList(response, params.offset)
    })
  }

  /**
   * Updates the state with the Promotions API response
   * @param {*} response          The response object of getPromotions request
   * @param {*} page              The current page
   */
  updatePromotionsList(response, page) {
    if (response && response.promotionList && response.promotionFilter.paging) {
      const newPromotionsList = []
      const { paging } = response.promotionFilter
      if (page === 0) {
        this.setState({
          promotionsList: []
        }, () => {
          response.promotionList.map(promotion => newPromotionsList.push(promotion))
          this.setPromotions(newPromotionsList, paging.total)
        })
      } else {
        const { promotionsList } = this.state
        promotionsList.map(promotion => newPromotionsList.push(promotion))
        response.promotionList.map(promotion => newPromotionsList.push(promotion))
        this.setPromotions(newPromotionsList, paging.total)
      }
    }
    if (response && response.platformEffectValue) {
      const { platformEffectValue } = response
      this.setPlatformEffectValue(platformEffectValue)
    }
  }

  /**
   * Sets the state values for promotions
   * @param {*} promotionsList    The promotions list
   * @param {*} total             The total of items to list
   */
  setPromotions(promotionsList, total) {
    this.setState({
      promotionsList,
      hasMore: (promotionsList.length < total),
      total
    })
  }

  /**
    * Sets the state values for sorting and reload the promotions
    * @param {*} sortingField       The field used to sort list
    * @param {*} sortingDirection   The sorting direction
    */
  onSorting(sortingField, sortingDirection) {
    this.setState({
      currentSortingField: sortingField,
      currentSortingDirection: sortingDirection
    }, () => {
      this.loadPromotionsPage(0)
    })
  }

  /**
   * Calls API to set the enable/disable a promotion
   * @param {*} promotionId       The id of promotion to update
   * @param {*} enabled           The new value of promotion status
   */
  updatePromotionState(promotionId, enabled) {
    const promotion = {
      promotionId,
      enabled
    }
    setPromotionState(promotion).then((response) => {
      this.updatedPromotionStatusOnList(response)
    })
  }

  /**
   * After set the promotion status find the promotion on scene state and update
   * the status of promotion
   */
  updatedPromotionStatusOnList(response) {
    const {
      enabled, promotionId, changedBy, changedOn
    } = response
    const { promotionsList } = this.state
    const index = promotionsList.findIndex(promotion => promotion.promotionId === promotionId)
    promotionsList[index].enabled = enabled
    promotionsList[index].changedBy = changedBy
    promotionsList[index].changedOn = changedOn
    this.setState({ promotionsList })
  }

  openCommissionDetailsDialog(commissionDetails) {
    this.setState({
      isCommissionDetailListDialogOpen: true,
      commissionDetails
    })
  }

  closeCommissionDetailsDialog() {
    this.setState({
      isCommissionDetailListDialogOpen: false
    })
  }

  /**
   * Open the insert dialog
   */
  openInsertPromotionDialog() {
    this.setState({
      isPromotionInsertDialogOpen: true
    })
  }

  /**
   * Close the insert dialog
   */
  closeInsertPromotionDialog() {
    this.setState({
      isPromotionInsertDialogOpen: false
    })
  }

  closePromotionInsertSuccessMessage() {
    this.setState({
      showPromotionInsertSuccessMessage: false
    })
  }

  openPromotionInsertSuccessMessage() {
    this.setState({
      showPromotionInsertSuccessMessage: true
    }, () => this.loadPromotionsPage(0))
  }

  openMassiveInsertPromotionDialog() {
    this.setState({
      isMassivePromotionInsertDialogOpen: true
    })
  }

  /**
   * Close the insert dialog
   */
  closeMassiveInsertPromotionDialog() {
    this.setState({
      isMassivePromotionInsertDialogOpen: false
    })
  }

  onSelectFilter(seller) {
    this.setState({
      selectedFilter: {
        selectedSeller: seller
      }
    }, () => {
      this.loadPromotionsPage(0)
    })
  }

  onClearFilter() {
    this.setState({
      selectedFilter: {
        selectedSeller: {}
      }
    }, () => {
      this.loadPromotionsPage(0)
    })
  }

  render() {
    const {
      promotionsList,
      currentSortingField,
      currentSortingDirection,
      hasMore,
      total,
      isCommissionDetailListDialogOpen,
      commissionDetails,
      isPromotionInsertDialogOpen,
      showPromotionInsertSuccessMessage,
      isMassivePromotionInsertDialogOpen,
      selectedFilter
    } = this.state

    return (
      <div className="content promotions">
        <section className="header-message-container">
          <Closable
            className="header-message header-success-message"
            isOpen={showPromotionInsertSuccessMessage}
            onClose={this.closePromotionInsertSuccessMessage}
            showCloseButton
            autoCloseTime={10000}
          >
            <span className="promotion-insert-success">
              <i className="icon-ok-check" />
              {i18n.t('Promotion created successfully.')}
            </span>
          </Closable>
        </section>

        <PromotionsTitle />

        <PromotionsGrid
          loadItems={this.loadPromotionsPage}
          openCommissionDetailsDialog={this.openCommissionDetailsDialog}
          hasMore={hasMore}
          total={total}
          promotions={promotionsList || []}
          onSorting={this.onSorting}
          currentSortingField={currentSortingField}
          currentSortingDirection={currentSortingDirection}
          onUpdatePromotionState={(promotionId, enabled) => this.updatePromotionState(promotionId, enabled)}
          openInsertDialog={() => this.openInsertPromotionDialog()}
          openMassiveInsertDialog={this.openMassiveInsertPromotionDialog}
          onSelectFilter={this.onSelectFilter}
          onClearFilter={this.onClearFilter}
          selectedFilter={selectedFilter}
        />

        <CommissionDetailListDialog
          id="commission-details-dialog"
          icon="icon-promotional-commission"
          title={i18n.t('Promotional Commission')}
          items={commissionDetails}
          isOpen={isCommissionDetailListDialogOpen}
          onClose={() => this.closeCommissionDetailsDialog()}
        />

        <PromotionInsertDialog
          isOpen={isPromotionInsertDialogOpen}
          onClose={() => this.closeInsertPromotionDialog()}
          showPromotionInsertMessage={this.openPromotionInsertSuccessMessage}
        />
        <MassivePromotionInsertDialog
          isOpen={isMassivePromotionInsertDialogOpen}
          onClose={() => this.closeMassiveInsertPromotionDialog()}
          showPromotionInsertMessage={this.openPromotionInsertSuccessMessage}
        />
      </div>
    )
  }
}