import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import CustomizeCategories from '../../../../components/customize-categories/CustomizeCategories'

import StepFooter from './StepFooter'

const createCustomCategory = category => ({
  id: category.id,
  code: category.code,
  name: category.name,
  planEffectValue: 0,
  planCustom: true
})

/*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["category"] }]*/
const setPlanCustom = (categories, selectedCategory, planCustom) => {
  categories.forEach((category) => {
    if (category.id === selectedCategory.id) {
      category.planCustom = planCustom
    } else if (category.children) {
      setPlanCustom(category.children, selectedCategory, planCustom)
    }
  })
}

class StepCategories extends Component {

  constructor(props) {
    super(props)
    const { categories, customCategories } = this.props
    this.state = { categories, customCategories }

    bindAll(this, ['selectCategory', 'removeCustomCategory', 'changePercentValue', 'updateCategories'])
  }

  updateCategories(state) {
    const { updatePlan } = this.props

    const newState = {
      ...this.state,
      state
    }

    this.setState(newState, () => updatePlan({
      stepCategories: {
        ...newState
      }
    }))
  }

  selectCategory(categorySelected) {
    if (categorySelected.id) {
      const { categories, customCategories } = this.state

      const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(categorySelected.id)
      if (indexCustomCategories === -1) {
        customCategories.push(createCustomCategory(categorySelected))
      }

      setPlanCustom(categories, categorySelected, true)

      this.updateCategories({
        categories,
        customCategories
      })
    }
  }

  removeCustomCategory(category) {
    const { categories, customCategories } = this.state

    const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(category.id)
    if (indexCustomCategories !== -1) {
      customCategories.splice(indexCustomCategories, 1)
    }

    setPlanCustom(categories, category, false)

    this.updateCategories({
      categories,
      customCategories
    })
  }

  changePercentValue(id, percentage) {
    const { customCategories } = this.state
    const indexCustomCategories = customCategories.map(customCategory => customCategory.id).indexOf(Number(id))

    if (indexCustomCategories !== -1) {
      customCategories[indexCustomCategories].planEffectValue = percentage
    }

    this.updateCategories({
      customCategories
    })
  }

  render() {
    const { categories, customCategories } = this.state
    const { jumpToStep, closeAction } = this.props

    return (
      <Fragment>
        <div id="categories-step">
          <CustomizeCategories
            treeList={categories}
            selectedItems={customCategories}
            selectCategory={this.selectCategory}
            removeCategory={this.removeCustomCategory}
            changePercentValue={this.changePercentValue}
          />
        </div>
        <StepFooter
          currentStepIdex={1}
          jumpToStep={jumpToStep}
          closeAction={closeAction}
        />
      </Fragment>
    )
  }
}

export default StepCategories
StepCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      children: PropTypes.arrayOf
    })
  ),
  customCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      children: PropTypes.arrayOf
    })
  ),
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  updatePlan: PropTypes.func
}