import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { Treebeard } from 'react-treebeard'
import decorators from 'react-treebeard/lib/components/decorators'
import defaultTheme from 'react-treebeard/lib/themes/default'
import InputText from '../form-components/InputText'
import i18n from '../../utils/i18n/i18n'

import * as filters from './filter'

const removeChildren = treeList => treeList.map((node) => {
  if (node.children && node.children.length > 0) {
    removeChildren(node.children)
  } else {
    node.children = null
  }
  return node
})

const convertData = (treeList) => {
  const tree = removeChildren(treeList)

  return {
    name: 'Todas',
    toggled: true,
    children: tree
  }
}

const customToggle = (treeNode) => {
  const { style } = treeNode
  return (
    <div style={style.toggle}>
      <svg height="10px" width="10px">
        <path
          d="M6.8,5l-4-3.6c-0.3-0.3-0.3-0.8,0-1c0.3-0.3,0.8-0.3,1.1,0l4,3.6c0.6,
          0.6,0.6,1.5,0,2.1l-4,3.6 C3.6,10,3.1,10,2.8,9.7c-0.3-0.3-0.3-0.8,0-1L6.8,5z"
        />
      </svg>
    </div>
  )
}

class CategoriesTree extends Component {

  constructor(props) {
    super(props)
    const data = convertData(props.treeList)
    filters.expandSelectedNodes(data)
    this.state = {
      filterValue: '',
      data
    }
    bindAll(this, ['onToggle', 'customHeader', 'onFilterMouseUp', 'onClickTreeItem', 'onFilterData'])
  }

  componentWillReceiveProps(props) {
    const data = convertData(props.treeList)
    filters.expandSelectedNodes(data)
    this.setState({
      data
    }, () => {
      this.onFilterData()
    })
  }

  onClickTreeItem(newNode) {
    const { selectCategory } = this.props
    selectCategory(newNode)
    this.onFilterData()
  }

  customHeader(treeNode) {
    const { node, style } = treeNode

    let customType
    if (node.partnerCustom) {
      customType = 'partnerCustom'
    } else if (node.planCustom) {
      customType = 'planCustom'
    }

    const icon = <i className={`icon-checked ${customType}`} />
    return (
      <div style={style.base}>
        <div id="tree-item">
          {customType && icon}
          <span id={`tree-item-title-${node.id}`} className={customType} onClick={() => this.onClickTreeItem(node)}>{ node.name }</span>
        </div>
      </div>
    )
  }

  /*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["node"] }]*/
  // Função que abre a árvore quando clicado na seta
  onToggle(node, toggled) {
    const { cursor } = this.state

    if (cursor) {
      cursor.active = false
    }

    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    this.setState({ cursor: node })
  }

  onFilterMouseUp(e) {
    const filter = e.target.value.trim()
    this.setState({ filterValue: filter }, () => this.onFilterData())
  }

  onFilterData() {
    const { treeList } = this.props
    const pureData = convertData(treeList)
    const { filterValue } = this.state

    if (!filterValue) {
      filters.expandSelectedNodes(pureData)
      this.setState({
        data: pureData
      })
    } else {
      let filtered = filters.filterTree(pureData, filterValue)
      filtered = filters.expandFilteredNodes(filtered, filterValue)
      this.setState({ data: filtered })
    }
  }

  render() {
    const { data } = this.state

    decorators.Toggle = customToggle
    decorators.Header = this.customHeader

    defaultTheme.tree.base = {
      ...defaultTheme.tree.base,
      backgroundColor: '#ebebeb',
      listStyle: 'none',
      padding: '16px'
    }

    /**
     * The Firefox requires container object on tree.node with link properties
     */
    defaultTheme.tree.node = {
      ...defaultTheme.tree.node,
      container: {
        activeLink: {
          background: '#31363F'
        },
        link: {
          cursor: 'pointer',
          display: 'block',
          padding: '0px 5px',
          position: 'relative'
        }
      }
    }

    return (
      <Fragment>
        <h3 id="title">{ i18n.t('Select the Categories') }</h3>

        {/* Adiciona o campo de busca */}
        <div id="tree-categories-search">
          <InputText
            className="input-search"
            icon="icon-search"
            placeholder={i18n.t('Search category')}
            onChange={this.onFilterMouseUp}
          />
        </div>

        {/* Adiciona o componente da árvore */}
        <div id="tree">
          <Treebeard
            style={defaultTheme}
            decorators={decorators}
            data={data}
            onToggle={this.onToggle}
          />
        </div>
      </Fragment>
    )
  }
}

CategoriesTree.propTypes = {
  treeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      planCustom: PropTypes.bool,
      partnerCustom: PropTypes.bool,
      children: PropTypes.arrayOf
    })
  ).isRequired,
  selectCategory: PropTypes.func
}

export default CategoriesTree
