import querystring from 'querystring'
import {
  GET_SAPHIRA_REPORT_DOWNLOAD,
  GET_DETAILED_REPORT_DOWNLOAD,
  GET_CONSOLIDATED_REPORT_DOWNLOAD,
  GET_BILLING_ADJUSTMENTS_REPORT_DOWNLOAD,
  GET_PROMOTION_DOWNLOAD
} from '../../../commons/Services'
import { doDownload, doDownloadLoading } from '../../../commons/Downloads'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

export const getSaphiraReportDownload = (cycleInstanceId, loading) => {
  doDownload(`${process.env.REACT_APP_C4_COMMISSIONING_URL}/${GET_SAPHIRA_REPORT_DOWNLOAD}${cycleInstanceId}`, loading)
}

export const getDetailedReportDownload = (cycleInstanceId, loading) => {
  doDownload(`${process.env.REACT_APP_MKTP_DATA_HUB_URL}/${GET_DETAILED_REPORT_DOWNLOAD}?cycle_instance_id=${cycleInstanceId}`, loading)
}

export const getConsololidatedReportDownload = (cycleInstanceId, loading) => {
  doDownload(`${process.env.REACT_APP_MKTP_DATA_HUB_URL}/${GET_CONSOLIDATED_REPORT_DOWNLOAD}?cycle_instance_id=${cycleInstanceId}`, loading)
}

export const getBillingAdjustmentsReportDownload = (param) => {
  doDownload(`${process.env.REACT_APP_MKTP_DATA_HUB_URL}/${GET_BILLING_ADJUSTMENTS_REPORT_DOWNLOAD}${param ? getParams(param) : ''}`)
}

export const getPromotionDownload = (promotionId) => {
  doDownload(`${process.env.REACT_APP_MKTP_DATA_HUB_URL}/${GET_PROMOTION_DOWNLOAD.replace('{promotionId}', promotionId)}`)
}