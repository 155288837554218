/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import ErrorDialog from '../../../components/commons/error-dialog/ErrorDialog'

import './MassivePromotionsErrorDialog.css'
import i18n from '../../../utils/i18n/i18n'
import MassivePromotionsError from '../massive-promotions-error/MassivePromotionsError'

const onExportErrors = errors => () => {
  localStorage.setItem('errors', JSON.stringify(errors))
  window.open('/promotions/massive-input/errors', '_blank')
}

const MassivePromotionsErrorDialog = (props) => {
  const {
    isOpen, onClose, dialogId, errors
  } = props
  return (
    <ErrorDialog
      title={i18n.t(`${errors.length > 1 ? 'Problems' : 'Problem'} on create Promotion Grid`)}
      open={isOpen}
      onClose={onClose}
      id={dialogId}
      icon="icon-warning2"
      scroll="body"
      hasExportButton
      onExport={onExportErrors(errors)}
    >
      <MassivePromotionsError errors={errors} />
    </ErrorDialog>
  )
}

MassivePromotionsErrorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogId: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  }))
}
export default MassivePromotionsErrorDialog