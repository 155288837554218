import React, { Component, Fragment } from 'react'
import { bindAll } from 'lodash'
import PropTypes from 'prop-types'
import StepFooter from './StepFooter'
import StepPartnerInfo from './StepPartnerInfo'
import i18n from '../../../../utils/i18n/i18n'
import InputPercentage from '../../../../components/form-components/input-percentage/InputPercentage'
import Select from '../../../../components/form-components/select/Select'
import { getPlansNames } from '../../../../services/api/plans/PlansAction'

import './StepPartner.css'

class StepPartner extends Component {

  constructor(props) {
    super(props)

    const { currentPartner } = this.props

    this.state = {
      stepPartner: currentPartner,
      plans: []
    }

    bindAll(this, ['onChangeValue'])
  }

  componentDidMount() {
    getPlansNames().then((result) => {
      this.setState({
        plans: result
      })
    })
  }

  onChangeValue(key, value) {
    const { stepPartner } = this.state
    const { updatePartner } = this.props
    stepPartner[key] = value
    updatePartner({ stepPartner })
    this.setState({
      stepPartner
    })
  }

  render() {
    const { jumpToStep, closeAction, stepInfo } = this.props
    const { stepPartner, plans } = this.state

    const options = plans ? plans.map(p => ({ text: p.name, value: p.planId })) : []
    options.unshift({ text: i18n.t('Without grid applied'), value: 0 })

    return (
      <Fragment>
        <StepPartnerInfo
          ecommerceId={stepInfo.ecommerceId}
          tradingName={stepInfo.tradingName}
        />

        <div className="form-group">
          <InputPercentage
            id="defaultCommission"
            value={stepPartner.defaultCommission}
            label={i18n.t('Contractual Commission')}
            onChangeValue={this.onChangeValue}
          />
        </div>

        <div className="form-group">
          <Select
            id="planId"
            placeholder={i18n.t('Select the grid')}
            label={i18n.t('Seller Commission Grid')}
            options={options}
            value={stepPartner.planId != null ? stepPartner.planId.toString() : 0}
            onChangeValue={this.onChangeValue}
          />
        </div>
        <StepFooter
          currentStepIdex={0}
          jumpToStep={jumpToStep}
          closeAction={closeAction}
          isFirst
        />
      </Fragment>
    )
  }
}
StepPartner.propTypes = {
  jumpToStep: PropTypes.func,
  closeAction: PropTypes.func,
  updatePartner: PropTypes.func,
  stepInfo: PropTypes.shape({
    ecommerceId: PropTypes.string,
    tradingName: PropTypes.string
  }),
  currentPartner: PropTypes.shape({
    defaultCommission: PropTypes.number,
    planId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  })
}

export default StepPartner