import { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import configs from '../../../settings/application'

const DEFAULT_LOCALE = configs.locale.default

/**
* Format an String representation of date into format described.
* ex: '2000-10-21T00:00:00.000Z' result: '20/10/2000'
*
* @param value string representation of the date
* @param locale locale to use on format
*/
const toLocaleDate = (value, locale = DEFAULT_LOCALE) => new Intl.DateTimeFormat(locale, {
  hour: '2-digit',
  minute: '2-digit',
  options: {
    hourCycle: 'h24'
  }
}).format(new Date(value))

export default class FormattedTime extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formattedValue: toLocaleDate(props.value, props.locale)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setState({
        formattedValue: toLocaleDate(nextProps.value, nextProps.locale)
      })
    }
  }

  render() {
    const { formattedValue } = this.state
    return formattedValue
  }
}

FormattedTime.propTypes = {
  value: PropTypes.string.isRequired,
  locale: PropTypes.string
}

FormattedTime.defaultProps = {
  locale: DEFAULT_LOCALE
}