import React, { Component } from 'react'
import MassivePromotionsError from '../massive-promotions-error/MassivePromotionsError'
import i18n from '../../../utils/i18n/i18n'

import './MassivePromotionsErrorPrint.css'

class MassivePromotionsErrorPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: JSON.parse(localStorage.getItem('errors'))
    }
    localStorage.removeItem('errors')
  }

  render() {
    const { errors } = this.state

    return (
      errors
        ? (
          <div className="massive-promotion-error-print">
            <div className="massive-promotion-error-print-header">
              <h2>
                <i className="icon icon-warning2" />
                <span>{i18n.t('Errors on create Promotion Grid')}</span>
              </h2>
            </div>
            <MassivePromotionsError
              className="massive-promotion-error-print-content"
              errors={errors}
              defaultExpanded
              disableExpansionPanels
            />
          </div>
        )
        : window.close()
    )
  }

}

export default MassivePromotionsErrorPrint