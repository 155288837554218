/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetCommissioning, doGetOrder, doGetSeller } from '../../../commons/Connection'
import { GET_AUDIT } from '../../../commons/Services'

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

// eslint-disable-next-line max-len
export const getCommissioningAudit = (auditType, filter) => doGetCommissioning(`${GET_AUDIT.replace('{type}', auditType)}${filter ? getParams(filter) : ''}`)
export const getOrderAudit = (auditType, filter) => doGetOrder(`${GET_AUDIT.replace('{type}', auditType)}${filter ? getParams(filter) : ''}`)
export const getSellerAudit = (auditType, filter) => doGetSeller(`${GET_AUDIT.replace('{type}', auditType)}${filter ? getParams(filter) : ''}`)

export const getAuditByType = filter => doGetSeller(`${GET_AUDIT}${filter ? getParams(filter) : ''}`)

export default getSellerAudit
