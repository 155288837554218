import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import DatePicker from 'react-datepicker'
import { CustomDialog, CustomDialogFooter, CustomDialogHeader } from '../../../components/commons/dialog/CustomDialog'
import InputDatePicker from '../../../components/commons/input-date-picker/InputDatePicker'
import i18n from '../../../utils/i18n/i18n'
import SellerAutoComplete from '../../../components/auto-complete/seller-auto-complete/SellerAutoComplete'
import PromotionsErrorDialog from '../promotions-error-dialog/PromotionsErrorDialog'
import { savePromotion } from '../../../services/api/promotions/PromotionsAction'
import InputText from '../../../components/form-components/InputText'
import SkuCommissionInputList from '../../../components/form-components/input-sku-commission/SkuCommissionInputList'
import configs from '../../../settings/application'

import 'react-datepicker/dist/react-datepicker.css'

import './PromotionInsertDialog.css'

const DEFAULT_LOCALE = configs.locale.default

export default class PromotionInsertDialog extends Component {

  constructor(props) {
    super(props)

    this.state = this.getInitialState()

    bindAll(this, [
      'onSuggestionsClearRequested',
      'onSuggestionSelected',
      'onSave',
      'handleClose',
      'handleStartDateChange',
      'handleEndDateChange',
      'handleCampaignChange',
      'getPromotionRequest',
      'isSaveEnabled',
      'getInitialState',
      'handleIncludePromoSkuRule',
      'handleRemovePromoSkuRule',
      'changeIsSaving'
    ])
  }

  getInitialState() {
    return {
      isErrorInsertDialogOpen: false,
      promotion: {},
      commissionRules: [],
      errors: [],
      seller: undefined,
      isSaving: false
    }
  }

  handleClose() {
    const { onClose } = this.props
    this.setState(this.getInitialState(), () => onClose())
  }

  onSuggestionsClearRequested() {
    this.setState({
      seller: undefined
    })
  }

  onSuggestionSelected(seller) {
    this.setState({
      seller
    })
  }

  /**
   * Open Error Dialog
   */
  openErrorDialog() {
    this.setState({ isErrorInsertDialogOpen: true })
  }

  /**
   * Close Error Dialog
   */
  closeErrorDialog() {
    this.setState({
      isErrorInsertDialogOpen: false,
      errors: []
    })
  }

  changeIsSaving(isSaving) {
    this.setState({ isSaving })
  }

  onSave() {
    if (this.isSaveEnabled()) {
      this.setState({
        isSaving: true
      }, () => {
        const promotionRequest = this.getPromotionRequest()
        const { showPromotionInsertMessage } = this.props
        savePromotion(promotionRequest).then(() => {
          this.handleClose()
          this.changeIsSaving(false)
          showPromotionInsertMessage()
        }).catch((error) => {
          this.changeIsSaving(false)
          this.setState({
            errors: error.data.meta.errors
          }, () => this.openErrorDialog())
        })
      })
    }
  }

  getPromotionRequest() {
    const { promotion, commissionRules, seller } = this.state

    const promoCommissionRules = commissionRules.map(rule => ({
      promoSku: rule.sku,
      effect: rule.effect
    }))

    return {
      ...promotion,
      promoCommissionRules,
      partner: seller,
      promotionType: 'SKU'
    }
  }

  handleStartDateChange(date) {
    const { promotion } = this.state
    this.setState({
      promotion: {
        ...promotion,
        startDate: date
      }
    })
  }

  handleEndDateChange(date) {
    const { promotion } = this.state
    this.setState({
      promotion: {
        ...promotion,
        endDate: date
      }
    })
  }

  handleCampaignChange(event) {
    const { promotion } = this.state
    const { value } = event.target

    this.setState({
      promotion: {
        ...promotion,
        campaign: value
      }
    })
  }

  handleIncludePromoSkuRule(sku, effect) {
    const { commissionRules } = this.state
    commissionRules.push({ sku, effect })

    this.setState({
      commissionRules
    })
  }

  handleRemovePromoSkuRule(item) {
    const { commissionRules } = this.state

    const removalIndex = commissionRules.map(commissionedSku => commissionedSku.sku).indexOf(item.sku)
    commissionRules.splice(removalIndex, 1)

    this.setState({
      commissionRules
    })
  }

  isSaveEnabled() {
    const {
      promotion, commissionRules, seller, isSaving
    } = this.state

    return (
      promotion
      && promotion.startDate
      && promotion.endDate
      && promotion.campaign
      && commissionRules.length > 0
      && seller
      && seller.sellerId
      && true
      && !isSaving
    )
  }

  render() {
    const { isOpen } = this.props

    const {
      isErrorInsertDialogOpen, errors, promotion, commissionRules, seller
    } = this.state

    const datePickerConfig = {
      dateFormat: 'dd/MM/yyyy HH:mm',
      timeFormat: 'HH:mm',
      timeIntervals: 30
    }

    const header = (
      <CustomDialogHeader
        title={i18n.t('Create Promotional Commission')}
        subtitle={i18n.t('All fields are required')}
        icon="icon-promotional-comission"
        id="promotion-insert-dialog-header"
        classes="promotion-insert-dialog-header"
      />
    )

    const buttons = [{
      id: 'btnCancel',
      class: '',
      name: i18n.t('Cancel'),
      action: this.handleClose,
      key: 'cancelButton'
    }, {
      id: 'btnCreatePromotion',
      class: this.isSaveEnabled() ? 'btn-action' : 'disable',
      name: i18n.t('Add Promotion'),
      action: this.onSave,
      key: 'createButton'
    }]
    const startDateInput = <InputDatePicker idName="start_date" />
    const endDateInput = <InputDatePicker idName="end_date" />
    return (
      <Fragment>
        <CustomDialog
          open={isOpen}
          onClose={this.handleClose}
          header={header}
          disableBackdropClick
          disableEscapeKeyDown
          scroll="body"
          id="promotion-insert-dialog"
          className="promotion-insert-dialog-class"
        >
          <div className="form-group wrap-input-text seller-container">
            <label htmlFor="sellerAutoComplete">{i18n.t('Seller')}</label>
            <SellerAutoComplete
              id="sellerAutoComplete"
              selectedSeller={seller || {}}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
            />
          </div>
          <div className="form-group wrap-input-text promotion-validity-container">
            <div id="startDateContainer" className="col-2 pull-left">
              <label htmlFor="startDate">{i18n.t('Promotion Start Date')}</label>
              <DatePicker
                selected={promotion.startDate}
                onChange={this.handleStartDateChange}
                showTimeSelect
                timeFormat={datePickerConfig.timeFormat}
                timeIntervals={datePickerConfig.timeIntervals}
                locale={DEFAULT_LOCALE}
                dateFormat={datePickerConfig.dateFormat}
                timeCaption={i18n.t('Time')}
                customInput={startDateInput}
              />
              <span className="promotion-validity-alert">
                <i className="icon-info" />
                {i18n.t('Can not create promotions longer than 6 months')}
              </span>
            </div>
            <div id="endDateContainer" className="col-2 pull-right">
              <label htmlFor="endDate">{i18n.t('Promotion End Date')}</label>
              <DatePicker
                selected={promotion.endDate}
                onChange={this.handleEndDateChange}
                showTimeSelect
                timeFormat={datePickerConfig.timeFormat}
                timeIntervals={datePickerConfig.timeIntervals}
                locale={DEFAULT_LOCALE}
                dateFormat={datePickerConfig.dateFormat}
                timeCaption={i18n.t('Time')}
                customInput={endDateInput}
              />
            </div>
          </div>
          <div className="form-group wrap-input-text promotional-action-container">
            <InputText
              id="promotionalAction"
              value={promotion.campaign || ''}
              placeholder={i18n.t('Please enter a name for the action')}
              label={i18n.t('Promotional Action')}
              onChange={this.handleCampaignChange}
              maxLength={60}
            />
          </div>
          <SkuCommissionInputList
            commissionedSkuList={commissionRules}
            onInclude={this.handleIncludePromoSkuRule}
            onRemove={this.handleRemovePromoSkuRule}
          />
          <CustomDialogFooter id="promotionInsertDialogFooter" buttons={buttons} />
        </CustomDialog>

        <PromotionsErrorDialog
          isOpen={isErrorInsertDialogOpen}
          onClose={() => this.closeErrorDialog()}
          dialogId="promotions-grid-error-dialog"
          errors={errors}
          commissionRules={commissionRules}
        />
      </Fragment>
    )
  }
}

PromotionInsertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showPromotionInsertMessage: PropTypes.func.isRequired
}

function InputStartDate(props) {
  const { onClick, value } = props
  return (
    <Fragment>
      <i className="icon-calendar" />
      <input type="text" id="start_date" placeholder="dd / mm / aaaa" onClick={onClick} value={value} autoComplete="off" />
    </Fragment>
  )
}
InputStartDate.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

function InputEndDate(props) {
  const { onClick, value } = props
  return (
    <Fragment>
      <i className="icon-calendar" />
      <input className="icon-close" type="text" id="end_date" placeholder="dd / mm / aaaa" onClick={onClick} value={value} autoComplete="off" />
    </Fragment>
  )
}
InputEndDate.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}