import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import i18n from '../../../utils/i18n/i18n'
import ReportInfo, { ReportData } from '../commons/ReportInfo'
import { TableContent } from '../../../components/table/Table'
import './AuditGrid.css'
import { FormattedDate, FormattedTime } from '../../../components/commons'

function renderAudits(audits, onOpenDescription) {
  if (!audits || audits.length === 0) {
    return null
  }
  return (audits.map((audit) => {
    const row = { key: Math.random(), className: 'audit-tr' }
    const columns = [{
      className: 'audit-tr-cycles',
      value: (
        <span>
          {audit.who}
        </span>
      )
    },
    {
      className: 'audit-tr-period',
      value: (
        <span>
          <FormattedDate value={audit.when} />
          {' '}
          <FormattedTime value={audit.when} />
        </span>
      )
    },
    {
      className: 'audit-tr-action',
      value: (
        <span>
          {audit.operation}
        </span>
      )
    },
    {
      className: 'audit-tr-sales-commission',
      value: (
        <span>
          {audit.module}
        </span>
      )
    },
    {
      className: 'audit-tr-sales-details',
      value: (
        <Fragment>
          <div className="audit-tr-description-overlay">
            <i
              id={`icon-description-${row}`}
              className="icon-common-file-text-search audit-tr-description-icon"
              onClick={() => onOpenDescription(audit)}
            />
          </div>
        </Fragment>
      )
    }]
    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}

const AuditGrid = (props) => {
  const {
    audits, hasMore, loadItems, onSorting, currentSortingField, currentSortingDirection, total, onOpenDescription
  } = props
  const lines = renderAudits(audits, onOpenDescription)
  const sortingFields = ['who', 'when', 'operation', 'module', 'details']
  const titles = [i18n.t('Audit User'), i18n.t('Audit Date'), i18n.t('Audit Action'), i18n.t('Audit Application'), i18n.t('Audit Details')]
  const styleClasses = [null, 'th-left', null, null, null]
  const descriptions = [null, null, null, null, null]
  return (
    <div>
      <ReportInfo
        actual={audits.length > total ? total : audits.length}
        total={total}
      />
      <ReportData
        audits={audits}
        hasMore={hasMore}
        loadItems={loadItems}
        onSorting={onSorting}
        currentSortingField={currentSortingField}
        currentSortingDirection={currentSortingDirection}
        total={total}
        lines={lines}
        sortingFields={sortingFields}
        titles={titles}
        styleClasses={styleClasses}
        descriptions={descriptions}
      />
    </div>
  )
}
AuditGrid.propTypes = {
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  audits: PropTypes.arrayOf(
    PropTypes.shape({
      audit: PropTypes.shape({
        who: PropTypes.string,
        operation: PropTypes.string,
        module: PropTypes.string,
        data: PropTypes.string
      })
    })
  ).isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  onOpenDescription: PropTypes.func.isRequired
}

export default AuditGrid