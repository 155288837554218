import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18n from '../../../utils/i18n/i18n'
import DropZone from '../../../components/dropzone/DropZone'
import { CustomDialog } from '../../../components/commons'
import { CustomDialogHeader, CustomDialogFooter } from '../../../components/commons/dialog/CustomDialog'
import { uploadSellerAccounts } from '../../../services/api/sellers/SellersAction'
import MassiveErrorCreateSellersAccountsDialog from '../massive-error-create-sellers-accounts-dialog/MassiveErrorCreateSellersAccountsDialog'

import helperTextsSellerAccountRules from './helperTextsSellerAccountRules'
import './MassiveSellerAccountInsertDialog.css'

function createHelperLines(helperText) {
  const textLines = (
    <ol className="expansion-panel-helper">
      {helperText.texts.map((text, index) => {
        const line = [
          <li key={index.toString()} className={`expansion-panel-helper-text${helperText.isBold[index] ? ' bold' : ''}`}>
            <i className="icon-arrow-right" />
            {i18n.t(text)}
          </li>
        ]
        return line
      })}
    </ol>
  )
  return textLines
}

function getInitialState() {
  return {
    isSaving: false,
    isValid: false,
    showErrorDialog: false,
    errors: [],
    fileUpload: undefined
  }
}

class MassiveSellerAccountInsertDialog extends Component {

  constructor(props) {
    super(props)

    this.state = getInitialState()

    bindAll(this, [
      'handleClose',
      'handleFileAdded',
      'handleUpload',
      'isSaveEnabled',
      'openErrorDialog',
      'closeErrorDialog'
    ])
  }

  handleClose() {
    const { onClose } = this.props
    this.setState(getInitialState(), () => onClose())
  }

  handleFileAdded(file) {
    this.setState({
      fileUpload: file,
      isValid: true
    })
  }

  handleUpload() {
    if (this.isSaveEnabled()) {
      this.setState({
        isSaving: true
      }, () => {
        const { showSellerAccountInsertMessage, openProcessingMessage, closeProcessingMessage } = this.props
        openProcessingMessage()
        const uploadRequest = this.getUploadRequest()
        uploadSellerAccounts(uploadRequest.file).then(() => {
          this.handleClose()
          showSellerAccountInsertMessage()
          this.changeIsSaving(false)
          closeProcessingMessage()
        }).catch((error) => {
          this.changeIsSaving(false)
          closeProcessingMessage()
          this.setState({
            errors: error.data.meta.errors
          }, () => this.openErrorDialog())
        })
      })
    }
  }

  changeIsSaving(isSaving) {
    this.setState({ isSaving })
  }

  getUploadRequest() {
    const { fileUpload } = this.state
    const file = new FormData()
    file.append('file', fileUpload)
    return {
      file
    }
  }

  isSaveEnabled() {
    const { isSaving, fileUpload } = this.state
    return (fileUpload && !isSaving)
  }

  openErrorDialog() {
    this.setState({ showErrorDialog: true })
  }

  closeErrorDialog() {
    this.setState({ showErrorDialog: false })
  }

  render() {
    const { isOpen } = this.props
    const {
      isSaving, isValid, fileUpload, showErrorDialog, errors
    } = this.state

    const header = (
      <CustomDialogHeader
        title={i18n.t('Create Seller Account')}
        icon="icon-create-seller"
        id="massive-seller-account-insert-dialog-header"
        classes="massive-seller-account-insert-dialog-header"
      />
    )

    const buttons = [{
      id: 'btnCancel',
      class: '',
      name: i18n.t('Cancel'),
      action: () => { this.handleClose() },
      key: 'cancelButton'
    }, {
      id: 'btnCreateSellerAccount',
      class: !isSaving && isValid ? 'btn-action' : 'disable',
      name: i18n.t('Create Account'),
      action: () => { this.handleUpload() },
      key: 'createButton'
    }]

    return (
      <Fragment>
        <CustomDialog
          open={isOpen}
          onClose={this.handleClose}
          header={header}
          disableBackdropClick
          disableEscapeKeyDown
          scroll="body"
          id="massive-seller-account-insert-dialog"
          className="massive-seller-account-insert-dialog"
        >
          {i18n.t(helperTextsSellerAccountRules.title)}

          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<ExpandMoreIcon />}>
              <span>
                <i className="icon-light-on" />
                {i18n.t('See the rules for creating/editing seller account on payment gatway.')}
              </span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {createHelperLines(helperTextsSellerAccountRules.helperText)}
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <div className="form-group wrap-input-text">
            <DropZone onFileAdded={(f) => { this.handleFileAdded(f) }} file={fileUpload} accept=".xlsx" />
          </div>

          <CustomDialogFooter id="massivePromotionInsertDialogFooter" buttons={buttons} />

        </CustomDialog>

        {showErrorDialog && (
          <MassiveErrorCreateSellersAccountsDialog
            dialogId="massive-seller-account-insert-dialog-error"
            isOpen={showErrorDialog}
            onClose={this.closeErrorDialog}
            errors={errors}
          />
        )}

      </Fragment>
    )
  }

}
MassiveSellerAccountInsertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSellerAccountInsertMessage: PropTypes.func.isRequired,
  openProcessingMessage: PropTypes.func.isRequired,
  closeProcessingMessage: PropTypes.func.isRequired
}

export default MassiveSellerAccountInsertDialog