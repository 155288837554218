export default {
  title: 'Helper text promotion title',
  helperText: {
    texts: [
      'Helper text promotion rule - 01',
      'Helper text promotion rule - 02',
      'Helper text promotion rule - 03',
      'Helper text promotion rule - 04',
      'Helper text promotion rule - 05',
      'Helper text promotion rule - 06',
      'Helper text promotion rule - 07',
      'Helper text promotion rule - 08',
      'Helper text promotion rule - 09',
      'Helper text promotion rule - 10',
      'Helper text promotion rule - 11',
      'Helper text promotion rule - 12',
      'Helper text promotion rule - 13',
      'Helper text promotion rule - 14',
      'Helper text promotion rule - 15',
      'Helper text promotion rule - 16',
      'Helper text promotion rule - 17',
      'Helper text promotion rule - 18',
      'Helper text promotion rule - 19',
      'Helper text promotion rule - 20'
    ],
    isBold: [false, false, false, false, false, false, false, false, true, false, false,
      false, false, false, false, false, false, false, false, false]
  }
}