/* eslint-disable max-len */
import React from 'react'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import './LoadingLogo.css'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)

const LoadingLogo = (props) => {
  const { className } = props
  return (
    <div className={getClassName('grid loading', className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="247" height="46" viewBox="0 0 247 46">
        <g fill="none" fillRule="evenodd">
          <g fill="#FFF">
            <text transform="translate(22 22)">
              <tspan x="44.478" y="18"><Trans>Loading data...</Trans></tspan>
            </text>
            <path d="M32.295 22.778c.06-.06.12-.084.17-.084.09 0 .152.08.15.177a.27.27 0 0 1-.085.178c-2.232 2.776-3.857 6.023-3.857 10.524 0 4.5 1.625 7.682 3.857 10.458a.275.275 0 0 1 .085.178c.002.098-.06.178-.15.178-.05 0-.11-.025-.17-.086l-9.517-9.203c-.475-.407-.778-.881-.778-1.557 0-.678.303-1.153.778-1.556l9.517-9.207M39.609 22c-3.726 0-5.18 5.28-5.18 11.573 0 6.295 1.454 11.506 5.18 11.506 2.236 0 4.141-1.31 4.148-2.387 0-.235-.09-.46-.287-.657-1.06-1.023-1.473-2.047-1.479-2.95-.005-1.724 1.49-3.008 2.868-3.008 1.896 0 3.014 1.49 3.014 3.419 0 1.828-.78 3.351-1.626 4.57a.272.272 0 0 0-.048.154c0 .094.056.173.141.173.052 0 .112-.027.176-.092l9.519-9.203c.474-.407.777-.881.777-1.557 0-.678-.303-1.153-.777-1.556l-9.519-9.207c-.064-.063-.124-.092-.176-.092-.086 0-.14.08-.14.176 0 .05.013.104.047.155.846 1.217 1.626 2.742 1.626 4.567 0 1.928-1.118 3.42-3.014 3.42-1.377 0-2.873-1.285-2.868-3.009.006-.901.42-1.926 1.479-2.949a.913.913 0 0 0 .287-.658C43.75 23.311 41.845 22 39.61 22" />
          </g>
        </g>
      </svg>
      <div className="loading-bar">
        <div className="progress" />
      </div>
    </div>
  )
}

LoadingLogo.propTypes = {
  className: PropTypes.string
}
LoadingLogo.defaultProps = {
  className: ''
}

export default LoadingLogo