import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../utils/i18n/i18n'

/** components */
import FormattedPercentage from '../commons/formater/FormattedPercentage'

/** style */
import './GroupedList.css'

const PERCENTAGE = 'PERCENTAGE'

const getClassName = (defaultClass, additionalClass) => (additionalClass ? `${defaultClass} ${additionalClass}` : defaultClass)

/**
 * **************************************************************************************
  * This function builds the show all item into the list of items <li></li>.
 * **************************************************************************************
 */
function buildShowAll(remaining, onClick) {
  return (
    <div className="show-all" onClick={onClick}>
      <span className="show-all-text">{i18n.t('Show all')}</span>
      <span className="show-all-badge">{`+ ${remaining}`}</span>
    </div>
  )
}

/**
 * **************************************************************************************
  * This function builds the list of items <li></li>.
 * **************************************************************************************
 */
function buildListItems(items, limit, onClick) {
  const remaining = limit ? items.length - limit + 1 : 0
  let index = 0
  const lines = items.map((item, i) => {
    index += 1
    if (limit) {
      if (i >= limit) {
        return null
      }
      if ((i === limit - 1) && (remaining > 1)) {
        return (
          <li className="list-item" key={index}>
            { buildShowAll(remaining, onClick) }
          </li>
        )
      }
    }
    return (
      <li className="list-item" key={`item_${index}`}>
        <div className="item" id={`item_${index}`}>
          <span className="item-text">{item.text}</span>
          { item.badgeFormat === 'PERCENTAGE'
            ? <span className="item-badge"><FormattedPercentage value={item.badgeValue} /></span>
            : <span className="item-badge">{item.badgeValue}</span>
          }
        </div>
      </li>
    )
  })
  return lines
}

/**
 * **************************************************************************************
 * Renders GroupedList
 * This component renders a grouped list.
 * **************************************************************************************
 */
const GroupedList = (props) => {
  const {
    items, limit, onClick, className
  } = props
  return (
    <ul className={getClassName('grouped-list', className)}>
      {buildListItems(items, limit, onClick)}
    </ul>
  )
}
GroupedList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      badgeValue: PropTypes.number,
      badgeFormat: PropTypes.string
    }).isRequired
  ),
  limit: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string
}
GroupedList.defaultProps = {
  items: {
    badgeFormat: PERCENTAGE
  }
}

export default GroupedList
export { PERCENTAGE }