/* eslint-disable max-len */
export default {
  translations: {
    // Home
    'home': 'Home',

    // Input Text area
    'characters left': 'Restam {{count}} caracteres',

    // Menu
    'Payment': 'Repasse',

    // Authentication
    'Password': 'Senha',
    'User': 'Usuário',
    'youremail@domain.com.br': 'seuemail@domínio.com.br',
    'Login': 'Entrar',
    'User or password is incorrect': 'Usuário ou senha incorretos',

    // Reports
    'payment-management-reports': 'Gestão de Repasse',
    'commission-management-reports': 'Gestão de Comissão',
    'billing-adjustments': 'Ajuste Manual',
    'Loading data...': 'AGUARDE, CARREGANDO…',
    'Cycle': 'Ciclo',
    'Consolidated cycle in the period': 'Consolidado do ciclo no período',
    'Average Commission': 'Comissão Média',
    'Revenue': 'Faturamento',
    'REVENUE': 'FATURAMENTO',
    'SALE - RETURN': 'VENDA - ESTORNO',
    'Marketplace Commission': 'Comissão Marketplace',
    'MARKETPLACE COMMISSION': 'COMISSÃO MARKETPLACE',
    'COMMISSION - RETURNED COMISSION': 'COMISSÃO - ESTORNO COMISSÃO',
    'Payment Value': 'Repasse',
    'PAYMENT VALUE': 'REPASSE',
    'REVENUE - COMMISSION': 'FATURAMENTO - COMISSÃO',
    'Saphira Report': 'Para Saphira',
    'Detailed Report': 'Detalhado',
    'Error occurred!': 'Ocorreu um erro!',
    'Download File': 'Baixar Arquivo',
    'Period': 'Período',
    'from': 'de',
    'to': 'até',
    'This file is formatted according to SAPHIRA system file upload.': 'Esse arquivo está formatado em acordo com o exigido pelo sistema SAPHIRA para upload.',
    'Only closed cycles can be sent to Saphira.': 'Apenas ciclos encerrados podem ser enviados ao SAPHIRA.',
    'No display cycle': 'Não há ciclo para exibição',
    'No previous cycles for display': 'Sem ciclos anteriores para exibição',
    'Empty date': '00/00/0000',
    'Closed Cycles': 'Ciclos Fechados',
    'Open Cycles': 'Ciclos Abertos',
    'Sales': 'Vendas',
    'SALES': 'VENDAS',
    'Sales Commission': 'Comissão de Vendas',
    'Return Commission': 'Estorno de Comissão',
    'Commission Balance': 'Saldo Comissão',
    'COMMISSION BALANCE': 'SALDO COMISSÂO',
    'COMMISSION - RETURN': 'COMISSÂO - ESTORNO',
    'Consolidated Commission': 'Comissão Consolidada',
    'ITEM + FREIGHT': 'ITEM + FRETE',
    'Error on do download': 'Falhar ao realizar o download',

    // Tabela de Gestão de Repasse
    'Billing': 'Faturamento',
    'Commission': 'Comissão',
    'Cycles': 'Ciclos',
    'financial': 'Financeiro',
    'Item + Freight': 'Item + Frete',
    'Orders': 'Pedidos',
    'Out of': 'de ',
    'Previous Cycles': 'Ciclos Anteriores',
    'Previous Reports': 'Relatórios anteriores',
    'Repayment': 'Repasse',
    'Reports generated from past cycles': 'Relatórios gerados de ciclos passados',
    'Results': 'Resultados',

    //BillingAdjustment
    'Open': 'Aberto',
    'Manual Adjustment': 'Ajuste Manual',
    'Marketplace ajustments list': 'Lista de ajustes do Marketplace',
    'Adjustment': 'Ajuste',
    'Adjustment Id': 'ID',
    'Created On': 'Criado Em',
    'Seller': 'Loja',
    'Reason': 'Finalidade',
    'CREDIT': 'Crédito',
    'DEBIT': 'Débito',
    'Add Adjustment': 'Adicionar Ajuste',
    'Download Spreadsheet': 'Baixar Planilha',
    'Select adjustment reason': 'Selecione a finalidade do ajuste',
    'There are no adjustments to be shown.': 'Não há ajustes a serem exibidos',
    'Billing Adjustment Id': 'Id Ajuste',
    'Search by trading name or ecommerce id': 'Pesquise pelo nome da loja ou pelo id seller',
    'All fields are required': 'Todos os campos são obrigatórios',
    'Only ADJUSTMENTS in open cycles are allowed': 'Só são permitidos AJUSTES em ciclos abertos.',
    'Adjustment Cycle': 'Ciclo de Ajuste',
    'Adjustment Type': 'Tipo de Ajuste',
    'Select the Type': 'Selecione o Tipo',
    'error.message.closed.cycle.instance': 'O Ciclo ao qual você está tentando inserir o ajuste encontra-se fechado. Apenas Ciclos abertos podem receber ajustes.',
    'error.message.no.cycle.instance': 'Não foi encontrado nenhum ciclo para esse seller. Por favor, confira o seller informado.',
    'Billing adjustment inserted successfully.': 'Ajuste inserido com sucesso.',
    'Describe the reason of adjustment for this seller.': 'Descreva o motivo do ajuste para esse lojista.',
    'error.default.warning.message': 'Ocorreu um erro inesperado, favor contactar o Administrador',

    // Errors
    '400 Error': 'Erro 400',
    '404 Error': 'Erro 404',
    '403 Error': 'Erro 403',
    '503 Error': 'Erro 503',
    '500 Error': 'Erro 500',
    'Sorry, invalid parameters.': 'Desculpe, parâmetros inválidos.',
    'Sorry, page not found.': 'Desculpe, página não encontrada.',
    'A page you are looking for does not exist on our servers.': 'A página que você procura não existe em nossos servidores.',
    'Forbidden': 'Acesso não permitido.',
    'You do not have permission to access this page': 'Você não tem permissão para acessar essa página.',
    'Sorry, the server is unavailable.': 'Desculpe, servidor indisponível.',
    'Sorry, internal server error.': 'Desculpe, erro interno no servidor.',
    'The request could not be understood by the server due to invalid parameters. Fix it and try again.': 'A requisição não pode ser compreendida pelo servidor devido a parâmetros inválidos. Corrija-a e tente novamente.',
    'Please, try again later.': 'Por favor, tente novamente mais tarde.',
    'Back to home page': 'Voltar para a página inicial...',
    'Server Bad Request.': 'Resposta inesperada do servidor.',
    'Error getting cycle instances list': 'Falha ao buscar lista de ciclos',
    'Error on getting cycle instances': 'Falha ao buscar ciclos',

    //Generics
    'Close': 'Fechar',
    'Back': 'Voltar',
    'Cancel': 'Cancelar',
    'Caution': 'Atenção',
    'Continue': 'Continuar',
    'Required': 'Preenchimento obrigatório',
    'Description': 'Descrição',
    'Value': 'Valor',
    'Details': 'Detalhes',
    'Save': 'Salvar',
    'Time': 'Hora',
    'Lines': 'Linhas',
    'Line': 'Linha',

    //Plans
    'plans': 'Grade de Comissão',
    'Grid Plan': 'Grade',
    'Grid Seller': 'Lojista',
    'Create Plan': 'Criar Grade',
    'Standard': 'Padrão',
    'Name': 'Nome',
    'Categories': 'Categorias',
    'Sellers': 'Lojas',
    'Area': 'Área',
    'There are no plans to be shown.': 'Sem grade cadastrada para exibição',
    'Plans Grid': 'Grade de Comissão',
    'Plans management for Marketplace\'s sellers': 'Gestão das comissões aplicadas aos lojistas no Marketplace',
    'Marketplace Standard Commission': 'Comissão Padrão Marketplace',
    'Show all': 'Ver todas',
    'Plan categories': 'Categorias da grade',

    //Plan Insert
    'Create Commission Grid': 'Criar Grade de Comissão',
    'Edit Commission Grid': 'Editar Grade de Comissão',
    'Basic Information': '1. Informações Básicas',
    'Include Categories': '2. Incluir Categorias',
    'Add Partners': '3. Adicionar Lojistas',
    'Save Plan': 'Salvar Grade',
    'Grid Name': 'Nome da Grade',
    'Requesting Area': 'Área Solicitante',
    'Standard Plan Commission': 'Comissão Padrão do Plano',
    'Enter the name of the new grid': 'Insira o nome da nova grade',
    'Select the area': 'Selecione a área',
    'Enter details here to help others understand this grid.': 'Insira aqui detalhes que possam ajudar outras pessoas a entenderem esta grade.',
    'Grids with the same name are not allowed': 'Não são permitidas grades com o mesmo nome',
    'Select the Categories': 'Selecione as Categorias',
    'Selected Categories': 'Categorias Selecionadas',
    'Search category': 'Buscar categoria',
    'Apply the commission for each selected category': 'Aplique a commissão para cada categoria selecionada',
    'Add': 'Adicionar',
    'Search seller': 'Buscar Loja',
    'Added Sellers': 'Lojas Adicionadas',
    'A store can not enabled on more than on plan': 'Uma Loja não pode estar habilitada em mais de um plano',
    'Without add store': 'Sem loja adicionada.',
    'Commission Grid successfully created': 'Grade de Comissão criada com sucesso.',
    'Commission Grid successfully edited': 'Grade de Comissão editada com sucesso.',
    'Partner Commission Grid successfully edited': 'Comissão da loja customizada com sucesso.',

    //Plan Insert Error
    'Problems on Create Grid': 'Problemas na Criação da Grade',
    'Problems on Edit Grid': 'Problemas na Edição da Grade',
    'The following problems must be fixed before that create grid': 'Os problemas a seguir devem ser resolvidos antes que a nova grade seja criada.',
    'The following problems must be fixed before that edit grid': 'Os problemas a seguir devem ser resolvidos antes que a grade seja editada.',
    'The grid already exists': 'A grade {{plan}} já existe',
    'Every created grid must have a unique name that helps to define yours creations purpose': 'Cada grade criada deve ter um nome único e que ajude a definir seu propósito de criação.',
    'Please, choose a name that is not in use.': 'Por favor, escolha um nome que não esteja em uso.',
    'The following partners already in use by another plan': 'As lojas listadas abaixo estão habilitadas em outros planos.',
    'Every partner must be in once Plan. You should set that plan on partner profile in Partner Tab': 'Cada loja deve estar habilitada em apenas um plano de comissão. Você pode habilitar esse plano diretamente entrando no perfil da loja na aba Lojistas.',
    'Please, remove the following partners on Add Partners Tab': 'Por favor, remova as lojas listadas abaixo da lista da aba ADICIONAR LOJAS.',

    // Sellers
    'There are no sellers to be shown': 'Sem sellers cadastrados para exibição',
    'Contractual': 'Contratual',
    'Store': 'Loja',
    'Plan': 'Plano',

    //Partner Insert
    'Customize Commission Grid': 'Customizar Grade de Comissão',
    'Commissions with custom values going to have priority over plan values on plan assigned that partner': 'Comissões com valores customizados aqui, terão precedência sobre os valores cadastrados no plano aplicado ao lojista.',
    'Contractual Commission': 'Comissão Contratual',
    'Seller Commission Grid': 'Grade de Comissão do Lojista',
    'Select the grid': 'Selecione a grade',
    'Without grid applied': 'Sem grade aplicada',

    // Audit
    'Audit': 'Auditoria da Aplicação',
    'Audit Description': 'Lista de ações executadas no Marketplace',
    'Audit Type': 'Tipo',
    'Select audit type': 'Selecione o tipo da auditoria',
    'Audit User': 'Usuário',
    'Audit Date': 'Data',
    'Audit Action': 'Ação',
    'Audit Application': 'Fonte',
    'Audit Details': 'Detalhes',
    'No audits for display': 'Sem logs para exibição',

    // Promotions
    'promotions': 'Promoções',
    'Promotions management': 'Gestão de Comissão Promocional',
    'Promotions management for Marketplace\'s sellers': 'Gerenciamento das comissões promocionais cadastradas no Marketplace',
    'Promotional Commission': 'Comissão Promocional',
    'Promotion Commission': 'Comissão Promocional',
    'Validity': 'Vigência',
    'Campaign': 'Ação',
    'Edit at': 'Editado em',
    'current': 'vigente',
    'expired': 'expirada',
    'scheduled': 'agendada',
    'by': 'por',
    'There are no promotions to be shown.': 'Não há promoções para serem exibidas.',
    'Do you really wish': 'Deseja realmente',
    'inactivate': 'inativar',
    'activate': 'ativar',
    'this promotion?': 'essa promoção?',
    'Add Promotion': 'Criar Promoção',
    'Create Promotional Commission': 'Criar Comissão Promocional',
    'Create/Edit Promotional Commission': 'Criar/Editar Comissão Promocional',
    'Promotion created successfully.': 'Promoção criada com sucesso.',
    'Promotion Start Date': 'Início da promoção',
    'Promotion End Date': 'Fim da promoção',
    'Can not create promotions longer than 6 months': 'Não é possível criar promoções com mais de 6 meses de duração',
    'Promotional Action': 'Ação Promocional',
    'Please enter a name for the action': 'Insira um nome para a ação',
    'Problem on create Promotion Grid': 'Problema na criação da Grade Promocional',
    'Problems on create Promotion Grid': 'Problemas na criação da Grade Promocional',
    'Errors on create Promotion Grid': 'Erros encontrados na criação da Grade Promocional',
    'Problem on processing sellers accounts': 'Problema ao Processar a Criação de Contas dos Lojistas',
    'Problems on processing sellers accounts': 'Problemas ao Processar a Criação de Contas dos Lojistas',
    'Errors on processing sellers accounts': 'Erros encontrados ao processar a criação de contas dos lojistas',
    'The following problem must be resolved to create the new promotion grid.': 'O problema a seguir deve ser resolvido para que a nova grade promocional seja criada.',
    'The following problems must be resolved to create the new promotion grid.': 'Os problemas a seguir devem ser resolvidos para que a nova grade promocional seja criada.',
    'The PROMOTION END DATE is less than START DATE.': 'A data FIM DA PROMOÇÃO é menor que a data INÍCIO',
    'The promotion max allowed time is six months.': 'O tempo máximo para a promoção é de seis meses.',
    'The PROMOTION START DATE must not be less than the CURRENT DATE.': 'A data INÍCIO DA PROMOÇÃO não deve ser menor do que a DATA ATUAL.',
    'The following SKUs are duplicates.': 'Os SKUs listados abaixo estão duplicados.',
    'The following SKUs are in another promotional commission in the same selected period.': 'Os SKUs listados abaixo estão inseridos em outra comissão promocional no mesmo período selecionado.',
    'So that there is no competition of values, one same SKU can not be in the same period or inside another.': 'Para que não haja concorrência de valores, um mesmo SKU não pode estar em períodos iguais ou contidos.',
    'Please, remove from the list the following SKUs before continue.': 'Por favor, remova os SKUs listados abaixo da lista antes de continuar.',
    'Insert the Seller SKU': 'Insira o SKU do Seller',
    'Added SKUs': 'SKUs Adicionados',
    'Find an SKU in the list below': 'Busque um SKU na lista abaixo',
    'No SKU added.': 'Sem SKU adicionado.',
    'Send File': 'Enviar Arquivo',
    'See rules for creating/editing promotion via file upload.': 'Ver regras para criação/edição de promoção via envio de arquivo.',
    'Upload the file dropping It here or ': 'Anexe o arquivo arrastando e soltando aqui ou ',
    'click here to select': 'clique aqui para selecionar',
    'Helper text promotion title': `Você pode criar ou editar as comissões promocionais usando um arquivo excel, no formato disponível para download 
     na tela com a lista de comissões promocionais. Existem algumas regras que o arquivo deve cumprir antes que a promoção seja criada.`,
    'Helper text promotion rule - 01': `Apenas as datas início/fim inseridas nos campos abaixo são válidas, as datas inseridas nas colunas DATA INÍCIO e DATA FIM no arquivo
     excel serão desconsiderados;`,
    'Helper text promotion rule - 02': `Todas as colunas em amarelo devem ser obrigatoriamente preenchidas, exceto: ID PROMOÇÃO, SKU e CATEGORIA sendo que, 
    apenas uma delas deve estar preenchida por arquivo;`,
    'Helper text promotion rule - 03': 'Não pode haver SKUs duplicados em uma mesma promoção;',
    'Helper text promotion rule - 04': 'Não é permitido um SKU, CATEGORIA, ou COMISSÃO LOJA em um mesmo período em comissões promocionais diferentes;',
    'Helper text promotion rule - 05': 'Não é permitida a criação de uma comissão promocional no passado e nem com uma DATA INÍCIO posterior a DATA FIM;',
    'Helper text promotion rule - 06': 'As comissões promocionais em andamento não poderão ser alteradas a DATA INÍCIO;',
    'Helper text promotion rule - 07': 'O período máximo de uma comissão promocional não deve ser superior a 6 meses;',
    'Helper text promotion rule - 08': `Para edição massiva de uma comissão promocional, as linhas da coluna ID PROMOÇÃO não devem ser alteradas, caso as linhas da coluna
     ID PROMOÇÃO estejam vazias uma nova promoção será criada;`,
    'Helper text promotion rule - 09': 'Ao editar uma promoção massivamente, todas as informações serão substituídas passando a valer as novas informações enviadas;',
    'Helper text promotion rule - 10': `Ao editar uma promoção vigente, não será permitido alteração do nome da Ação Promocional (campanha) assim como não será permitida
     a troca do tipo de promoção (SKU, CATEGORIA ou LOJA);`,
    'Helper text promotion rule - 11': 'Na edição não será permitida a troca da loja da promoção, independente do status da campanha;',
    'Helper text promotion rule - 12': 'Promoções expiradas não podem ser editadas;',
    'Helper text promotion rule - 13': 'É permitido criar promoção do mesmo tipo e vigência para vários Sellers em um mesmo arquivo;',
    'Helper text promotion rule - 14': 'É permitido editar promoção do mesmo tipo e Seller;',
    'Helper text promotion rule - 15': 'É permitido no mesmo arquivo, criar ou editar uma promoção;',
    'Helper text promotion rule - 16': 'ID Loja máximo 30 caracteres;',
    'Helper text promotion rule - 17': 'SKU máximo 20 caracteres;',
    'Helper text promotion rule - 18': 'ID Categoria máximo 100 caracteres;',
    'Helper text promotion rule - 19': '% Comissão Promocional até 2 casas decimais depois da vírgula ou ponto, exemplos: 12,75 OU 12.75 OU 00,00',
    'Helper text promotion rule - 20': 'Ação máximo 60 caracteres;',
    'The file is empty': 'A planilha não contém dados.',
    'Different partnerId found': 'Existem lojas diferentes na planilha.',
    'Different types of promotion found': 'Existem regras de tipo diferente na planilha.',
    'Different campaign found': 'Existem ações com nomes diferentes na planilha.',
    'Partner not found': 'ID de Loja não encontrado.',
    'Number of characters greater than allowed': '<span class="error-title">NÚMERO DE CARACTERES MAIOR DO QUE O PERMITIDO</span>',
    'Duplicated SKUs for seller': '<span class="error-title">SKUs DUPLICADOS</span> para um mesmo lojista',
    'Conflicted SKUs': '<span class="error-title">SKUs</span> inseridos <span class="error-title">EM OUTRA COMISSÃO PROMOCIONAL</span> no mesmo período selecionado',
    'Duplicated Category for seller': '<span class="error-title">CATEGORIAS DUPLICADAS</span> para um mesmo lojista',
    'Conflicted Category': '<span class="error-title">CATEGORIAS</span> inseridas <span class="error-title">EM OUTRA COMISSÃO PROMOCIONAL</span> no mesmo período selecionado',
    'Conflicted Partner': '<span class="error-title">COMISSÃO LOJA</span> inserida <span class="error-title">EM OUTRA COMISSÃO PROMOCIONAL</span> no mesmo período selecionado',
    'Duplicated Partner': '<span class="error-title">COMISSÃO LOJA DUPLICADA</span> para um mesmo lojista',
    'Required fields must not be empty': '<span class="error-title">CAMPOS OBRIGATÓRIOS</span> sem preenchimento',
    'Active promotion start date must not change': '<span class="error-title">ALTERAÇÃO DA DATA DE INÍCIO DE PROMOÇÃO VIGENTE</span> e que <span class="error-title">NÃO PODE</span> ser alterada',
    'Expired promotion must not change': '<span class="error-title">PROMOÇÃO EXPIRADA</span> e que <span class="error-title">NÃO PODE</span> ser alterada',
    'Active promotion campaign must not change': '<span class="error-title">ALTERAÇÃO DE NOME DE CAMPANHA VIGENTE</span> e que <span class="error-title">NÃO PODE</span> ser alterada',
    'Active promotion type must not change': '<span class="error-title">ALTERAÇÃO DO TIPO DE CAMPANHA VIGENTE</span> e que <span class="error-title">NÃO PODE</span> ser alterada',
    'Partner Id must not change': '<span class="error-title">ALTERAÇÃO DO ID LOJA</span> e que <span class="error-title">NÃO PODE</span> ser alterado',
    'Promotion Id not found': '<span class="error-title">ID DE PROMOÇÃO NÃO ENCONTRADO</span>',
    'Was found': 'Foi encontrado',
    'Were found': 'Foram encontrados',
    'Was found_female': 'Foi encontrada',
    'Were found_female': 'Foram encontradas',
    'problem': 'problema',
    'problems': 'problemas',
    'on sent file.': 'no arquivo enviado.',
    'Make the necessary adjustments and submit again the file, please': 'Faça os ajustes necessários e envie o arquivo novamente, por favor:',
    'line with problem': 'linha com problema',
    'lines with problems': 'linhas com problemas',
    'Export errors list': 'Exportar lista de erros',
    'Download Promotion': 'Download da Promoção',
    'with': 'com',

    //Seller Account
    'sellers-account': 'Criação de Conta do Lojista',
    'Seller Account': 'Conta lojista',
    'Seller Account Creation': 'Criação de Conta do Lojista',
    'Creation of seller account on payment splitter': 'Criação da conta do lojista no splitter de pagamento',
    'There are no sellers accounts to be shown.': 'Não há contas dos lojistas para serem exibidas.',
    'Company Name': 'Razão Social',
    'C/C Account': 'C/C Criada',
    'active': 'ativo',
    'inactive': 'inativo',
    'active_female': 'ativa',
    'inactive_female': 'inativa',
    'PROCESSING': 'processando',
    'CREATED': 'sim',
    'NOT_CREATED': 'não',
    'ERROR': 'erro',
    'Create Seller Account': 'Criar Conta do Lojista',
    'See the rules for creating/editing seller account on payment gatway.': 'Ver regras para criação da conta de lojista no splitter de pagamento.',
    'Helper text seller account title': 'Ao enviar o arquivo, as contas dos lojistas serão criadas automaticamente no splitter de pagamento.',
    'Helper text seller account rule - 01': 'A importação massiva deve ser feita por um arquivo formato .xlsx;',
    'Helper text seller account rule - 02': 'Os campos ecommerce id, banco, agência, número da conta, digito da conta, tipo da conta e nome do titular são obrigatórios;',
    'Helper text seller account rule - 03': 'A conta seller deve ser preenchida uma por linha;',
    'Helper text seller account rule - 04': 'Na coluna Número do Banco o valor não deve ter 3 caracteres.',
    'Helper text seller account rule - 05': 'Na coluna tipo de conta é necessário informar um dos seguintes valores: corrente, poupança, poupança conjunta ou corrente conjunta.',
    'Helper text seller account rule - 06': 'As informações dos lojistas devem estar contidas na primeira aba (planilha) do arquivo, as demais abas serão desconsideradas.',
    'Helper text seller account rule - 07': 'Na coluna Nome do Titular o valor não deve ter mais do que 30 caracteres.',
    'Create Account': 'Criar Conta',
    'Accounts created with success.': 'Contas criadas com sucesso.',
    'Account type is invalid': '<span class="error-title">TIPO DE CONTA INVÁLIDO</span>',
    'seller id  not found': '<span class="error-title">ID DA LOJA</span> não foi encontrado',
    'seller creation permission denied': '<span class="error-title">USUÁRIO</span> não tem permissão para criar conta lojista',
    'Processing file.': 'Arquivo em processamento.',
    'Seller Account Holder Name has more than 30 characters': '<span class="error-title">NOME DO TITULAR</span> com mais de 30 caracteres',

    //Billing Adjustment Internal Transfer
    'Seller not found': 'Lojista não encontrado',
    'Seller without stoneId': 'Lojista sem cadastro no splitter de pagamento',
    'Transfer is required': 'Erro ao salvar o Ajuste Manual',
    'Transfer Type is required': 'Erro ao salvar o Ajuste Manual',
    'Insufficient funds to perform transaction': 'Saldo insuficiente para realizar a transação',
    'Error saving billing record on internal database': 'Erro ao salvar o Ajuste Manual',
    'Error on execute transfer for target account': 'Erro ao executar a transferência para a conta de destino',
    'Error on save billing adjustment': 'Erro ao salvar o Ajuste Manual',
    'unexpected error': 'Erro ao salvar o Ajuste Manual',
    'PaymentClient#internalTransfer(PaymentTransfer,String)': 'Erro ao salvar o Ajuste Manual'
  }
}
