import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../utils/i18n/i18n'

/** components */
import FormattedPercentage from '../../../components/commons/formater/FormattedPercentage'
import PlansGridInfo from './plans-grid-info/PlansGridInfo'
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid, TableContent
} from '../../../components/table/Table'
import GroupedList, { PERCENTAGE } from '../../../components/group/GroupedList'
import { CredentialManager } from '../../../commons/CredentialManager'

/** style */
import './PlansGrid.css'

/**
 * Renders the message that there are not plans to be shown.
 */
function buildNoResultMessage() {
  return (
    <div className="no-result-message">
      <i className="icon-common-file-warning" />
      {i18n.t('There are no plans to be shown.')}
    </div>
  )
}

/**
 * Builds an array of category item objects to be used with the grouped list component
 */
function buildItems(customCategories) {
  return customCategories.map((category) => {
    const { name } = category
    let displayName = name
    if (name.length > 20) {
      displayName = `${name.substring(0, 17)}...`
    }
    return ({
      text: displayName,
      badgeValue: category.effect.value,
      badgeFormat: category.effect.type === 'PERCENT' ? PERCENTAGE : ''
    })
  })
}

/**
 * Renders the plans grid's lines
 */
function renderPlans(plans, openPlanDialog, openCategoriesDialog) {
  if (!plans || plans.length === 0) {
    return null
  }
  const emptyValue = <span>—</span>

  const hasRoleEditPlan = CredentialManager.userHasRole('ROLE_EDIT_PLAN')

  return (plans.map((plan) => {
    const {
      area, customCategories, description, effect, name, planId, totalPartners
    } = plan

    let displayDescription = description
    if (description && description.length > 120) {
      displayDescription = `${description.substring(0, 117)}...`
    }
    let categoryItems = []
    if (customCategories) {
      categoryItems = buildItems(customCategories)
    }
    let categories = emptyValue
    if (customCategories && customCategories.length > 0) {
      categories = (
        <GroupedList
          items={categoryItems}
          limit={3}
          className="categories-list"
          onClick={() => openCategoriesDialog(categoryItems)}
        />
      )
    }
    const row = { key: planId, className: 'plan-tr' }
    let effectValue = emptyValue
    if (effect && effect.value) {
      effectValue = <FormattedPercentage value={effect.value} />
    }

    const columns = [{
      className: 'plan-tr-standard-commission',
      value: (<span id={`standard-commission_${planId}`}>{effectValue}</span>)
    },
    {
      className: 'plan-tr-name',
      value: (<span id={`name_${planId}`}>{name}</span>)
    },
    {
      className: 'plan-tr-categories',
      value: (<span id={`categories_${planId}`}>{categories}</span>)
    },
    {
      className: 'plan-tr-sellers',
      value: (<span id={`sellers_${planId}`}>{totalPartners}</span>)
    },
    {
      className: 'plan-tr-area',
      value: (<span id={`area_${planId}`}>{area}</span>)
    },
    {
      className: 'plan-tr-details',
      value: (<span id={`details_${planId}`}>{displayDescription}</span>)
    },
    {
      className: 'plan-tr-edit',
      value: (
        <span id={`edit_${planId}`}>
          { hasRoleEditPlan && <i id={`icon-edit-${planId}`} className="icon-text-edit" onClick={() => openPlanDialog(planId)} /> }
        </span>
      )
    }]

    return (
      <TableContent row={row} columns={columns} key={row.key} />
    )
  }))
}

/**
 * Renders the plans grid
 */
const PlansGrid = (props) => {
  const {
    plans, hasMore, loadItems, onSorting, currentSortingField, currentSortingDirection, total, openPlanDialog, openCategoriesDialog
  } = props
  const lines = renderPlans(plans, openPlanDialog, openCategoriesDialog)
  const sortingFields = ['effectValue', 'name', null, 'totalPartners', 'area', null, null]
  const titles = [i18n.t('Commission'), i18n.t('Name'), i18n.t('Categories'), i18n.t('Sellers'), i18n.t('Area'),
    i18n.t('Details'), null]
  const styleClasses = ['plan th-commission', 'plan th-left th-name', 'plan th-categories',
    'plan th-sellers', 'plan th-area', 'plan th-details', 'plan th-edit']
  const descriptions = [i18n.t('Standard'), null, null, null, null, null]
  return (
    <Fragment>
      <PlansGridInfo
        actual={plans.length > total ? total : plans.length}
        total={total}
        openPlanDialog={openPlanDialog}
      />
      <TableContainer>
        <Table className="plans-table">
          <TableHeader
            sortingFields={sortingFields}
            titles={titles}
            styleClasses={styleClasses}
            descriptions={descriptions}
            onSorting={onSorting}
            currentSortingField={currentSortingField}
            currentSortingDirection={currentSortingDirection}
          />
          {lines
            ? (
              <TableBodyInfinite
                hasMore={hasMore}
                loadItems={loadItems}
                loadingColSpan={12}
              >
                {lines}
              </TableBodyInfinite>
            )
            : (
              <RenderEmptyReportGrid
                displayMessage={buildNoResultMessage()}
                colSpan={titles.length}
              />
            )}
          <HasMore actual={plans.length > total ? total : plans.length} total={total} />
        </Table>
      </TableContainer>
    </Fragment>
  )
}
PlansGrid.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      plansList: PropTypes.arrayOf(
        PropTypes.shape({
          planId: PropTypes.number,
          name: PropTypes.string,
          area: PropTypes.string,
          effect: PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.number
          }),
          description: PropTypes.string,
          enabled: PropTypes.bool,
          totalPartners: PropTypes.number,
          customCategories: PropTypes.arrayOf(
            PropTypes.shape({
              code: PropTypes.string,
              name: PropTypes.string,
              effect: PropTypes.shape({
                type: PropTypes.string,
                value: PropTypes.number
              })
            }))
        }))
    })),
  hasMore: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  openPlanDialog: PropTypes.func.isRequired,
  openCategoriesDialog: PropTypes.func.isRequired
}

export default PlansGrid