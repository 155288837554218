import PropTypes from 'prop-types'

/**
* Format an String representation of CNPJ into format described.
* ex: '00000000000000' result: '00.000.000/0000-00'
*
* @param value string representation of the CNPJ
*/
export default function FormattedCNPJ(props) {
  const { value } = props
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
FormattedCNPJ.propTypes = {
  value: PropTypes.string.isRequired
}