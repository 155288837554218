import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../../utils/i18n/i18n'

import './PromotionsGridInfo.css'
import { CredentialManager } from '../../../../commons/CredentialManager'

const PromotionsGridInfo = (props) => {

  const {
    actual, total, openInsertDialog, openMassiveInsertDialog
  } = props

  const addPromotionButton = (
    <span className="table-promotions-info-options-add" onClick={openInsertDialog}>
      <i className="icon-add" />
      {i18n.t('Add Promotion')}
    </span>
  )

  const sendFileButton = (
    <span className="table-promotions-info-options-send-file" onClick={openMassiveInsertDialog}>
      <i className="icon-upload" />
      {i18n.t('Send File')}
    </span>
  )

  const hasRoleCreatePromotion = CredentialManager.userHasRole('ROLE_CREATE_PROMOTION')
  const hasRoleCreatePromotionMassive = CredentialManager.userHasRole('ROLE_CREATE_PROMOTION_MASSIVE')

  return (
    <div className="promotions-grid">
      <div className="promotions-grid-header">
        <div className="table-promotions-info-options">
          { hasRoleCreatePromotion ? addPromotionButton : null }
          { hasRoleCreatePromotion ? <span className="table-promotions-info-options-separator">.</span> : null }
          { hasRoleCreatePromotionMassive ? sendFileButton : null }
          { hasRoleCreatePromotionMassive ? <span className="table-promotions-info-options-separator">.</span> : null }
        </div>
        <div className="table-promotions-info-summary">
          {` ${i18n.t('Results')}: `}
          <span className="text-bold" id="actual_results">{actual}</span>
          {` ${i18n.t('Out of')} `}
          <span className="text-bold" id="total_results">{total}</span>
        </div>
      </div>
    </div>
  )
}

PromotionsGridInfo.propTypes = {
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  openInsertDialog: PropTypes.func.isRequired,
  openMassiveInsertDialog: PropTypes.func.isRequired
}


export default PromotionsGridInfo