import React from 'react'
import PropTypes from 'prop-types'
import { CustomDialog, CustomDialogFooter, CustomDialogHeader } from '../../../components/commons/dialog/CustomDialog'
import i18n from '../../../utils/i18n/i18n'
import KeyValueTable from '../key-value-table/KeyValueTable'
import './AuditDescriptionDialog.css'
import { FormattedDate, FormattedTime } from '../../../components/commons'

/**
 * **************************************************************************************
 * Renders AuditDescriptionDialog - <AuditDescriptionDialog>
 * **************************************************************************************
 */
const AuditDescriptionDialog = (props) => {

  const {
    audit, onClose, open, onFilterById
  } = props

  const header = (
    <CustomDialogHeader
      id="audit-description-dialog-header"
      title={i18n.t('Details')}
      icon="icon-common-file-text-search"
      onClose={onClose}
    />
  )
  const buttom = [{
    class: 'close-button',
    name: i18n.t('Close'),
    action: onClose,
    key: Math.random().toString()
  }]

  const footer = <CustomDialogFooter id="billing-adjustment-description-dialog-footer" buttons={buttom} />

  return (
    <CustomDialog id="audit-description-dialog" header={header} footer={footer} open={open} onClose={onClose}>
      <div>
        <section>
          <span className="audit-description-dialog-title">{i18n.t('Audit Application')}</span>
          <span id="module">
            {audit.module}
          </span>
          <br />
          <span className="audit-description-dialog-title">{i18n.t('Audit User')}</span>
          <span id="who">
            {audit.who}
          </span>
          <br />
          <span className="audit-description-dialog-title">{i18n.t('Audit Action')}</span>
          <span id="operation">
            {audit.operation}
          </span>
          <br />
          <span className="audit-description-dialog-title">{i18n.t('Audit Date')}</span>
          <span id="when">
            <FormattedDate value={audit.when || ''} />
            {' '}
            <FormattedTime value={audit.when || ''} />
          </span>
          <br />
        </section>
        <br />
        <section>
          <KeyValueTable data={audit.data} onFilterById={onFilterById} />
        </section>

      </div>
    </CustomDialog>
  )
}
AuditDescriptionDialog.propTypes = {
  audit: PropTypes.shape({
    who: PropTypes.string,
    when: PropTypes.string,
    operation: PropTypes.string,
    module: PropTypes.string,
    data: PropTypes.object
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onFilterById: PropTypes.func
}
export default AuditDescriptionDialog