import React from 'react'

import { Dropdown, MenuItem } from '../../../menu/dropdown/Dropdown'
import i18n from '../../../../utils/i18n/i18n'

import './Menu.css'
import { CredentialManager } from '../../../../commons/CredentialManager'
import { ToggleManager } from '../../../../commons/ToggleManager'

const options = [
  {
    text: 'Commission', route: '/commission-management-reports', role: null, toggle: null
  },
  {
    text: 'Payment', route: '/payment-management-reports', role: null, toggle: null
  },
  {
    text: 'Manual Adjustment', route: '/billing-adjustments', role: 'ROLE_VIEW_BILLING_ADJUSTMENTS', toggle: null
  },
  {
    text: 'Plans Grid', route: '/plans', role: 'ROLE_VIEW_PLANS', toggle: null
  },
  {
    text: 'Promotional Commission', route: '/promotions', role: 'ROLE_VIEW_PROMOTION', toggle: null
  },
  {
    text: 'Auditoria', route: '/audit', role: 'ROLE_VIEW_AUDIT', toggle: null
  },
  {
    text: 'Seller Account', route: '/sellers-account', role: 'ROLE_VIEW_SELLERS_ACCOUNTS', toggle: 'CREATE-STONE-SELLER-ACCOUNT'
  }
]

const Menu = () => (
  <Dropdown title="Menu" className="menu" id="nav">
    {
      options.map((item) => {
        if (item.role == null || CredentialManager.userHasRole(item.role)) {
          if (item.toggle == null || ToggleManager.hasToggle(item.toggle)) {
            return <MenuItem text={i18n.t(item.text)} route={item.route} key={item.text} />
          }
        }
        return null
      })
    }
  </Dropdown>
)

export default Menu