/* eslint-disable no-param-reassign */
import querystring from 'querystring'
import { doGetSeller, doPut, doUploadSeller } from '../../../commons/Connection'
import {
  GET_SELLERS, PUT_PARTNER_PLAN, GET_PARTNER_PLAN,
  GET_SELLER_ACCOUNTS, UPLOAD_SELLER_ACCOUNT
} from '../../../commons/Services'

// const PAGE_LIMIT = 10

const getParams = (filter) => {
  const { ...params } = filter
  return `?${querystring.stringify(params)}`
}

const getSellers = filter => doGetSeller(`${GET_SELLERS}${getParams(filter)}`)

export const getPartnerPlan = filter => doGetSeller(`${GET_PARTNER_PLAN}${getParams(filter)}`)

export const putPartnerPlan = (partnerId, partner) => doPut(`${PUT_PARTNER_PLAN.replace('{partnerId}', partnerId)}`, partner)

export const getSellerAccounts = filter => doGetSeller(`${GET_SELLER_ACCOUNTS}${getParams(filter)}`)

export const uploadSellerAccounts = file => doUploadSeller(`${UPLOAD_SELLER_ACCOUNT}`, file)

export default getSellers
