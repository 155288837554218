import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../utils/i18n/i18n'

/** css */
import './InputText.css'

const InputText = (props) => {
  const {
    className, label, type, placeholder, isMandatory, onChange, errorMessage, id, icon, value, maxLength
  } = props

  const formLabel = label ? <span className="wrap-input-label">{label}</span> : ''
  const formIcon = icon ? <i className={icon} /> : ''

  return (
    <div className={`wrap-input-text ${className}`}>
      <label htmlFor={`input_text_${id}`}>
        {formLabel}
        {isMandatory && <span className="mandatory-label">{` - ${i18n.t('Required')}`}</span>}
        <span className="wrap-input-message">
          {errorMessage}
        </span>
        {formIcon}
        <input
          type={type}
          className={icon && 'with-icon'}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          id={`input_text_${id}`}
          value={value}
        />
      </label>
    </div>
  )
}

InputText.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  isMandatory: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string
}

InputText.defaultProps = {
  className: '',
  label: null,
  type: 'text',
  placeholder: '',
  isMandatory: false,
  errorMessage: ''
}

export default InputText
