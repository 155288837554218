/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

function InsightIcon(props) {
  const { className } = props
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon-insight</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="[C4][MKTP][GPR][SP05]---Gestao-De-Repasse---V1.C1.01-2" transform="translate(-1051.000000, -222.000000)" fill="#5B788E">
          <g id="Group-9" transform="translate(1035.000000, 198.000000)">
            <g id="icon-insight" transform="translate(16.000000, 24.000000)">
              <path d="M12.9993,17.651 L12.9993,18 L10.9993,18 L10.9993,17.651 C10.9993,17.228 10.7323,16.85 10.3333,16.709 C8.3393,16.003 6.9993,14.11 6.9993,12 C6.9993,9.243 9.2433,7 11.9993,7 C14.7563,7 16.9993,9.243 16.9993,12 C16.9993,14.11 15.6603,16.003 13.6663,16.709 C13.2673,16.85 12.9993,17.228 12.9993,17.651 Z M4.9993,12 C4.9993,8.14 8.1403,5 11.9993,5 C15.8593,5 18.9993,8.14 18.9993,12 C18.9993,14.714 17.4183,17.17 14.9993,18.319 L14.9993,19 C14.9993,19.552 14.5523,20 13.9993,20 L9.9993,20 C9.4473,20 8.9993,19.552 8.9993,19 L8.9993,18.319 C6.5813,17.17 4.9993,14.714 4.9993,12 Z M8.9998,23 L8.9998,21 L14.9998,21 L14.9998,23 L12.9998,23 L12.9998,24 L10.9998,24 L10.9998,23 L8.9998,23 Z M13,0 L13,4 L11,4 L11,0 L13,0 Z M0,13 L0,11 L4,11 L4,13 L0,13 Z M20,13 L20,11 L24,11 L24,13 L20,13 Z M21.7067,3.707 L18.7067,6.707 L17.2927,5.293 L20.2927,2.293 L21.7067,3.707 Z M6.7067,5.293 L5.2927,6.707 L2.2927,3.707 L3.7067,2.293 L6.7067,5.293 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

InsightIcon.propTypes = {
  className: PropTypes.string
}

InsightIcon.defaultProps = {
  className: ''
}

export default InsightIcon