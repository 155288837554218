import { Component } from 'react'
import PropTypes from 'prop-types'
import configs from '../../../settings/application'

const DEFAULT_LOCALE = configs.locale.default
const DEFAULT_MINIMUM_FRACTION_DIGITS = 0
const DEFAULT_MAXIMUN_FRACTION_DIGITS = 2

/**
 * Format number into percentage
 *
 * @param value number value
 * @param locale locale to use on format
 * @param minimumFractionDigits the minimum of fraction digits
 * @param maximumFractionDigits the maximum of fraction digits
*/
const toPercentage = (value, locale, minimumFractionDigits, maximumFractionDigits) => new Intl.NumberFormat(locale, {
  style: 'percent',
  minimumFractionDigits,
  maximumFractionDigits
}).format(value / 100)


export class FormattedPercentage extends Component {
  render() {
    const {
      value, locale, minimumFractionDigits, maximumFractionDigits
    } = this.props
    return toPercentage(value, locale, minimumFractionDigits, maximumFractionDigits)
  }
}

FormattedPercentage.propTypes = {
  value: PropTypes.number.isRequired,
  locale: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number
}

FormattedPercentage.defaultProps = {
  locale: DEFAULT_LOCALE,
  minimumFractionDigits: DEFAULT_MINIMUM_FRACTION_DIGITS,
  maximumFractionDigits: DEFAULT_MAXIMUN_FRACTION_DIGITS
}

export default FormattedPercentage