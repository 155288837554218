import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import './Select.css'
import i18n from '../../../utils/i18n/i18n'
/**
 * **************************************************************************************
 * Render a select - <Select>
 * **************************************************************************************
 */
export class Select extends Component {
  constructor(props) {
    super(props)

    bindAll(this, ['onChange'])
  }

  onChange(event) {
    const { id, onChangeValue } = this.props
    const { value } = event.target

    onChangeValue(id, value)
  }

  render() {
    const {
      id,
      className,
      label,
      placeholder,
      options,
      value,
      isMandatory
    } = this.props
    const placeholderOption = placeholder ? <option value="none" className="select-placeholder" disabled>{placeholder}</option> : ''

    return (
      <div className={`wrap-input-text ${className}`}>
        <label htmlFor={id}>
          {label}
          {isMandatory && <span className="mandatory-label">{` - ${i18n.t('Required')}`}</span>}
        </label>
        <select id={id} value={value || 'none'} onChange={this.onChange}>
          {placeholderOption}
          {options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
        </select>
      </div>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isMandatory: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    text: PropTypes.string
  })),
  onChangeValue: PropTypes.func
}

Select.defaultProps = {
  isMandatory: false,
  options: []
}

export default Select