import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import StepZilla from 'react-stepzilla'
import { CustomDialog, CustomDialogHeader } from '../../../components/commons/dialog/CustomDialog'
import i18n from '../../../utils/i18n/i18n'

import StepPlan from './steps/StepPlan'
import StepCategories from './steps/StepCategories'
import StepPartners from './steps/StepPartners'

import './PlanInsertWizard.css'

const getStepTitle = (id, classes, title, stepIndex, stepCurrent) => {
  let states = []
  if (stepCurrent === 0) {
    states = [[0, 'active'], [1, 'first'], [2, 'second']]
  } else if (stepCurrent === 1) {
    states = [[0, 'first'], [1, 'active'], [2, 'second']]
  } else {
    states = [[0, 'first'], [1, 'second'], [2, 'active']]
  }
  const stepColorStateMap = new Map(states)

  return (
    <div id={id} className={`plans-wizard-top ${classes} plans-wizard-top-${stepColorStateMap.get(stepIndex)}`}>
      {title}
    </div>
  )
}

/**
 * Make a blank plan object
 */
const getBlankPlan = () => ({
  plan: {
    stepPlan: {
      name: '',
      area: '',
      defaultRule: 0,
      description: ''
    },
    stepCategories: {
      categories: [],
      customCategories: []
    },
    stepPartners: {
      selectedPartners: []
    },
    planId: 0
  },
  currentStep: 0
})

class PlanInsertWizard extends Component {

  constructor(props) {
    super(props)

    const { editPlan } = this.props

    this.state = {
      plan: editPlan,
      currentStep: 0
    }

    bindAll(this, ['getPlan', 'updatePlan', 'changeCurrentStep', 'handleSave'])
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen, editPlan } = nextProps

    if (isOpen) {
      this.setState({
        plan: editPlan
      })
    } else {
      this.setState(getBlankPlan())
    }
  }

  getPlan() {
    const { plan } = this.state
    return plan
  }

  updatePlan(update) {
    let { plan } = this.state

    plan = {
      ...plan,
      ...update
    }

    this.setState({
      plan
    })
  }

  changeCurrentStep(step) {
    this.setState({
      currentStep: step
    })
  }

  handleSave() {
    const { onSave } = this.props
    const { plan } = this.state
    onSave(plan)
  }

  render() {
    const { isOpen, onClose, isSaving } = this.props
    const { plan, currentStep } = this.state
    const { stepPlan, stepCategories, stepPartners } = plan

    let isEdit = false
    if (plan != null && plan.planId != null && plan.planId > 0) {
      isEdit = true
    }

    const steps = [
      {
        name: getStepTitle('wizzard-top-plan', 'plans-wizard-top-plan', i18n.t('Basic Information'), 0, currentStep),
        component: <StepPlan
          updatePlan={(u) => { this.updatePlan(u) }}
          closeAction={onClose}
          currentPlan={stepPlan || {}}
        />
      }, {
        name: getStepTitle('wizzard-top-categories', 'plans-wizard-top-categories', i18n.t('Include Categories'), 1, currentStep),
        component: <StepCategories
          updatePlan={(u) => { this.updatePlan(u) }}
          categories={stepCategories ? stepCategories.categories : []}
          customCategories={stepCategories ? stepCategories.customCategories : []}
          closeAction={onClose}
        />
      }, {
        name: getStepTitle('wizzard-top-partners', 'plans-wizard-top-partners', i18n.t('Add Partners'), 2, currentStep),
        component: <StepPartners
          updatePlan={(u) => { this.updatePlan(u) }}
          closeAction={onClose}
          saveAction={() => this.handleSave()}
          selectedPartners={stepPartners ? stepPartners.selectedPartners : []}
          isSaving={isSaving}
        />
      }
    ]

    const header = (
      <CustomDialogHeader
        title={i18n.t(`${isEdit ? 'Edit' : 'Create'} Commission Grid`)}
        icon={`icon-text-money${isEdit && '-edit'}`}
        id="plans-wizard-header"
        classes="plans-wizard-header"
      />
    )

    return (
      <CustomDialog
        open={isOpen}
        onClose={onClose}
        header={header}
        disableBackdropClick
        disableEscapeKeyDown
        scroll="body"
        id="plans-wizard"
      >
        <StepZilla
          steps={steps}
          stepsNavigation={false}
          showNavigation={false}
          preventEnterSubmission
          onStepChange={(step) => { this.changeCurrentStep(step) }}
        />
      </CustomDialog>
    )
  }
}
PlanInsertWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  editPlan: PropTypes.shape({
    plan: PropTypes.shape({
      stepPlan: PropTypes.shape({
        name: PropTypes.string,
        area: PropTypes.string,
        defaultRule: PropTypes.number,
        description: PropTypes.string
      }),
      stepCategories: PropTypes.shape({
        categories: PropTypes.array,
        customCategories: PropTypes.array
      }),
      stepPartners: PropTypes.shape({
        selectedPartners: PropTypes.array
      }),
      planId: PropTypes.number
    }),
    currentStep: PropTypes.number
  }),
  isSaving: PropTypes.bool
}

export default PlanInsertWizard