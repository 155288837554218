import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../utils/i18n/i18n'
import { FormattedCurrency, FormattedDate, FormattedTime } from '../../../components/commons/index'
import ActionLink from '../../../components/link/ActionLink'
import {
  Table, TableBodyInfinite, TableContainer,
  TableHeader, HasMore, RenderEmptyReportGrid, TableContent
} from '../../../components/table/Table'
import { CredentialManager } from '../../../commons/CredentialManager'
import { getBillingAdjustmentsReportDownload } from '../../../services/api/download/DownloadActions'


import './BillingAdjustmentGrid.css'

function buildNoResultMessage() {
  return (
    <div className="no-result-message">
      <i className="icon-common-file-warning" />
      {i18n.t('There are no adjustments to be shown.')}
    </div>
  )
}

function buildLines(billingAdjustments, onOpenDescription) {

  if (!billingAdjustments || billingAdjustments.length === 0) {
    return null
  }

  return (billingAdjustments.map((billingAdjustment) => {
    const {
      billingAdjustmentId, type, createdOn, value, description,
      cycleInstance: { cycleInstanceId, startDate, endDate },
      seller: { ecommerceId, tradingName },
      billingAdjustmentReason: { name }
    } = billingAdjustment

    let isOpen = true
    if (new Date() > new Date(endDate)) {
      isOpen = false
    }

    let displayDescription = description
    let descriptionOverLimit = false
    if (description.length > 97) {
      displayDescription = `${description.substring(0, 97)}...`
      descriptionOverLimit = true
    }

    const row = { key: billingAdjustmentId, className: 'illing-adjustment-tr' }

    const columns = [{
      className: 'billing-adjustment-tr-cycle',
      value: (
        <Fragment>
          <span className="billing-adjustment-tr-cycle-id" id={`cycle_id_${billingAdjustmentId}`}>
            {cycleInstanceId}
          </span>
          {isOpen
            ? (
              <span className="billing-adjustment-tr-cycle-status" id={`cycle_status_${billingAdjustmentId}`}>
                {`(${i18n.t('Open')})`}
              </span>
            ) : null
          }
        </Fragment>
      )
    }, {
      className: 'billing-adjustment-tr-period',
      value: (
        <Fragment>
          <span className="billing-adjustment-tr-period-from" id={`from_${billingAdjustmentId}`}>
            {i18n.t('from')}
            &nbsp;
            <FormattedDate value={startDate} />
          </span>
          <span className="billing-adjustment-tr-period-to" id={`to_${billingAdjustmentId}`}>
            {i18n.t('to')}
            &nbsp;
            <FormattedDate value={endDate} />
          </span>
        </Fragment>
      )
    }, {
      className: 'billing-adjustment-tr-type',
      value: (
        <span id={`type_${billingAdjustmentId}`}>
          {i18n.t(type)}
        </span>
      )
    }, {
      className: 'billing-adjustment-tr-id',
      value: (
        <span id={`id_${billingAdjustmentId}`}>
          {billingAdjustmentId}
        </span>
      )
    }, {
      className: 'billing-adjustment-tr-created',
      value: (
        <Fragment>
          <span className="billing-adjustment-tr-created-date" id={`created_date_${billingAdjustmentId}`}>
            <FormattedDate value={createdOn} />
          </span>
          <span className="billing-adjustment-tr-created-time" id={`created_time_${billingAdjustmentId}`}>
            <FormattedTime value={createdOn} />
            H
          </span>
        </Fragment>
      )
    }, {
      className: 'billing-adjustment-tr-seller',
      value: (
        <span id={`seller_${billingAdjustmentId}`}>
          {`${ecommerceId} - ${tradingName}`}
        </span>
      )
    }, {
      className: 'billing-adjustment-tr-value',
      value: (
        <span id={`value_${billingAdjustmentId}`}>
          <FormattedCurrency value={value} />
        </span>
      )
    }, {
      className: 'billing-adjustment-tr-reason',
      value: (
        <span id={`reason_${billingAdjustmentId}`}>
          {name}
        </span>
      )
    }, {
      className: 'billing-adjustment-tr-description',
      value: (
        <Fragment>
          <div className={`billing-adjustment-tr-description-overlay ${descriptionOverLimit ? 'show' : 'hide'}`}>
            <i
              id={`icon-description-${billingAdjustmentId}`}
              className="icon-common-file-text-search billing-adjustment-tr-description-icon"
              onClick={() => onOpenDescription(billingAdjustment)}
            />
          </div>
          <span id={`description_${billingAdjustmentId}`}>
            {displayDescription}
          </span>
        </Fragment>
      )
    }
    ]

    return (
      <TableContent row={row} columns={columns} key={billingAdjustmentId} />
    )

  }))
}

/**
 * **************************************************************************************
 * Renders Table Info
 * This component renders the info of a table
 * **************************************************************************************
*/
const TableInfo = (props) => {
  const {
    title, description, children, actual, total
  } = props
  return (
    <div className="table-info">
      <div className="table-info-title">
        <span className="table-info-title-main">{title}</span>
        <span className="table-info-title-subtitle">{description}</span>
      </div>
      <div className="table-info-options">{children}</div>
      <div className="table-info-summary">
        {` ${i18n.t('Results')}: `}
        <span className="text-bold" id="actual_results">{actual}</span>
        {` ${i18n.t('Out of')} `}
        <span className="text-bold" id="total_results">{total}</span>
      </div>
    </div>
  )
}
TableInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  actual: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

/**
 * Render Billing Adjustment Table
 * This component renders the table and content info
 */
const BillingAdjustmentGrid = (props) => {

  const {
    billingAdjustments, hasMore, loadItems, onSorting, currentSortingField,
    currentSortingDirection, total, onOpenDescription, onOpenInsert
  } = props

  const sortingFields = ['cycleInstance.cycleInstanceId', 'cycleInstance.endDate', null, 'billingAdjustmentId',
    'createdOn', 'seller.tradingName', null, null, null]

  const titles = [i18n.t('Cycle'), i18n.t('Period'), i18n.t('Adjustment'),
    i18n.t('Adjustment Id'), i18n.t('Created On'), i18n.t('Seller'),
    i18n.t('Value'), i18n.t('Reason'), i18n.t('Description')]

  const styleClasses = ['th-center', 'th-right', 'th-center', 'th-center', 'th-center', 'th-left', 'th-left', 'th-left', 'th-left']

  const descriptions = [null, null, null, null, null, null, null, null, null]

  const lines = buildLines(billingAdjustments, onOpenDescription)

  const icon = <i className="icon-download" />

  const hasRoleCreateAdjustment = CredentialManager.userHasRole('ROLE_CREATE_BILLING_ADJUSTMENTS')

  const addAdjustmentButton = (
    <span className="table-info-options-add" onClick={onOpenInsert}>
      <i className="icon-add" />
      {i18n.t('Add Adjustment')}
    </span>
  )

  const downloadSortingParams = {
    sortField: currentSortingField,
    sortDirection: currentSortingDirection
  }

  return (
    <Fragment>
      <TableInfo
        title={i18n.t('Manual Adjustment')}
        description={i18n.t('Marketplace ajustments list')}
        actual={billingAdjustments.length > total ? total : billingAdjustments.length}
        total={total}
      >
        { hasRoleCreateAdjustment ? addAdjustmentButton : null }
        { hasRoleCreateAdjustment ? <span className="table-info-options-separator">.</span> : null }
        <span className="table-info-options-download">
          <ActionLink
            componentClass="report-link"
            text={i18n.t('Download Spreadsheet')}
            icon={icon}
            id="download_link_report"
            onClickAction={
              () => getBillingAdjustmentsReportDownload(downloadSortingParams)
            }
            url="#"
            disable={false}
          />
        </span>
        <span className="table-info-options-separator">.</span>
      </TableInfo>
      <TableContainer>
        <Table>
          <TableHeader
            sortingFields={sortingFields}
            titles={titles}
            styleClasses={styleClasses}
            descriptions={descriptions}
            onSorting={onSorting}
            currentSortingField={currentSortingField}
            currentSortingDirection={currentSortingDirection}
          />
          {lines
            ? (
              <TableBodyInfinite
                hasMore={hasMore}
                loadItems={loadItems}
                loadingColSpan={12}
              >
                {lines}
              </TableBodyInfinite>
            )
            : (
              <RenderEmptyReportGrid
                displayMessage={buildNoResultMessage()}
                colSpan={titles.length}
              />
            )}
          <HasMore actual={billingAdjustments.length > total ? total : billingAdjustments.length} total={total} />
        </Table>
      </TableContainer>
    </Fragment>
  )
}
BillingAdjustmentGrid.propTypes = {
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  billingAdjustments: PropTypes.arrayOf(
    PropTypes.shape({
      billingAdjustmentId: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.number,
      description: PropTypes.string,
      billingId: PropTypes.number,
      createdOn: PropTypes.string,
      cycleInstance: PropTypes.shape({
        cycleInstanceId: PropTypes.number,
        startDate: PropTypes.string,
        endDate: PropTypes.string
      }),
      seller: PropTypes.shape({
        sellerId: PropTypes.number,
        ecommerceId: PropTypes.string,
        tradingName: PropTypes.string
      }),
      billingAdjustmentReason: PropTypes.shape({
        billingAdjustmentReasonId: PropTypes.number,
        name: PropTypes.string
      })
    })
  ).isRequired,
  onSorting: PropTypes.func.isRequired,
  currentSortingField: PropTypes.string.isRequired,
  currentSortingDirection: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  onOpenDescription: PropTypes.func.isRequired,
  onOpenInsert: PropTypes.func.isRequired
}

export default BillingAdjustmentGrid