import React from 'react'
import './KeyValueTable.css'
import PropTypes from 'prop-types'

const KeyValueTable = (props) => {

  const { data, onFilterById } = props

  const generateKeys = () => {
    const keys = Object.entries(data).map(([key, value]) => ({ key, value }))

    return keys.map(item => (
      <tr>
        <td className="left-column" key={Math.random()}>{item.key}</td>
        <td className="right-column" key={Math.random()}>
          <span onClick={() => onFilterById(0, item.value)}>{item.value}</span>
        </td>
      </tr>
    ))
  }

  return (
    <div className="key-value-table-container">
      <table className="key-value-table">
        {generateKeys()}
      </table>
    </div>
  )
}
KeyValueTable.propTypes = {
  data: PropTypes.node,
  onFilterById: PropTypes.func
}

export default KeyValueTable