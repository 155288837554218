import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

/** components */
import i18n from '../../utils/i18n/i18n'
import { CustomDialog, CustomDialogHeader, CustomDialogFooter } from '../commons/dialog/CustomDialog'
import GroupedList from '../group/GroupedList'

/** style */
import './CommissionDetailListDialog.css'

class CommissionDetailListDialog extends PureComponent {
  render() {
    const {
      isOpen, onClose, items, title, icon, id
    } = this.props

    const header = (
      <CustomDialogHeader
        title={title}
        icon={icon}
        id={`${id}-header`}
        onClose={onClose}
      />
    )

    const buttons = [{
      class: 'close-button',
      name: i18n.t('Close'),
      action: onClose,
      key: 'close'
    }]

    const footer = (
      <CustomDialogFooter
        id={`${id}-footer`}
        buttons={buttons}
      />
    )

    return (
      <CustomDialog
        open={isOpen}
        onClose={onClose}
        header={header}
        footer={footer}
        scroll="body"
        id={id}
        classes="detail-list-dialog"
      >
        <GroupedList items={items} />
      </CustomDialog>
    )
  }
}

CommissionDetailListDialog.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      badgeValue: PropTypes.number,
      badgeFormat: PropTypes.string
    }).isRequired
  )
}

export default CommissionDetailListDialog