export default {
  title: 'Helper text seller account title',
  helperText: {
    texts: [
      'Helper text seller account rule - 01',
      'Helper text seller account rule - 02',
      'Helper text seller account rule - 03',
      'Helper text seller account rule - 04',
      'Helper text seller account rule - 05',
      'Helper text seller account rule - 06',
      'Helper text seller account rule - 07'
    ],
    isBold: [false, false, false, false, false, false]
  }
}