/* eslint react/no-danger: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { padStart, uniqBy } from 'lodash'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import i18n from '../../../utils/i18n/i18n'

import './MassiveCreateSellersAccountsErrors.css'

const FileError = (props) => {
  const { title, errors } = props
  return errors.length > 0 && (
    <div className="error-title error-file">{title}</div>
  )
}
FileError.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  }))
}

const ExpansionError = (props) => {
  const {
    title, lines, defaultExpanded, disableExpansionPanel, className
  } = props
  const formattedLines = lines.map(line => padStart(line, 2, '0')).sort()
  return formattedLines.length > 0 && (
    <ExpansionPanel className={`${className} error-expansion-panel`} defaultExpanded={defaultExpanded} disabled={disableExpansionPanel}>
      <ExpansionPanelSummary
        className="error-expansion-panel-summary"
        expandIcon={!defaultExpanded && <ExpandMoreIcon />}
        disabled={disableExpansionPanel}
      >
        <span>
          <strong>
            {padStart(formattedLines.length, 2, '0')}
            {' '}
            {`${i18n.t(formattedLines.length > 1 ? 'Lines' : 'Line')}`}
          </strong>
          {` ${i18n.t('with')} `}
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="error-expansion-panel-detail">
        <span
          className="error-lines"
        >
          <strong>
            {i18n.t(formattedLines.length > 1 ? 'Lines' : 'Line')}
            :
          </strong>
          {` ${formattedLines.join(', ')}`}
        </span>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
ExpansionError.propTypes = {
  title: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  ),
  defaultExpanded: PropTypes.bool,
  disableExpansionPanel: PropTypes.bool
}

ExpansionError.defaulProps = {
  defaultExpanded: false
}

const MassiveCreateSellersAccountsErrors = (props) => {
  const {
    errors, defaultExpanded, className, disableExpansionPanels
  } = props

  const basicEmptyValues = errors.filter(error => error.message === 'Empty seller item list')
  let lineValuesNull = []
  lineValuesNull = errors.filter(error => error.field === 'seller.account.bank')
    .concat(errors.filter(error => error.field === 'seller.ecommerceId'))
    .concat(errors.filter(error => error.field === 'seller.account.branchNumber'))
    .concat(errors.filter(error => error.field === 'seller.account.accountNumber'))
    .concat(errors.filter(error => error.field === 'seller.account.accountCheckDigit'))
    .concat(errors.filter(error => error.message === 'Seller Account type is required'))
    .concat(errors.filter(error => error.message === 'Seller Account Holder Name is required'))

  lineValuesNull = uniqBy(lineValuesNull, 'identifier').map(error => error.identifier)

  const lineSellerIdNotFound = errors.filter(error => error.field === 'seller.sellerId').map(error => error.identifier)

  const lineAccountTypeInvalid = errors.filter(error => error.message === 'Seller Account type is invalid').map(error => error.identifier)

  const lineCreationPermissionDenied = errors
    .filter(error => error.message === 'User doesn\'t have permission to create an account for seller')
    .map(error => error.identifier)

  const lineHolderNameOutOfLength = errors
    .filter(error => error.message === 'Seller Account Holder Name has more than 30 characters')
    .map(error => error.identifier)

  const totalLineErrors = lineValuesNull.length
    + lineAccountTypeInvalid.length
    + lineSellerIdNotFound.length
    + lineCreationPermissionDenied.length
    + lineHolderNameOutOfLength.length

  const totalFileErrors = basicEmptyValues.length

  const lineErrorTypes = [
    {
      title: i18n.t('Required fields must not be empty'),
      lines: lineValuesNull,
      className: 'lineAccountValuesNull'
    },
    {
      title: i18n.t('Account type is invalid'),
      lines: lineAccountTypeInvalid,
      className: 'lineAccountTypeInvalid'
    },
    {
      title: i18n.t('seller id  not found'),
      lines: lineSellerIdNotFound,
      className: 'lineSellerIdNotFound'
    },
    {
      title: i18n.t('seller creation permission denied'),
      lines: lineCreationPermissionDenied,
      className: 'lineCreationPermissionDenied'
    },
    {
      title: i18n.t('Seller Account Holder Name has more than 30 characters'),
      lines: lineHolderNameOutOfLength,
      className: 'lineHolderNameOutOfLength'
    }
  ]

  const fileErrorTypes = [{
    title: i18n.t('The file is empty'),
    errors: basicEmptyValues,
    className: 'basicEmptyValues'
  }]

  return (
    <div className={className}>
      {totalFileErrors > 0 && (
        <section id="errors-file" className="error-group">
          <div className="error-text">
            {totalFileErrors > 1 ? i18n.t('Were found') : i18n.t('Was found')}
            {' '}
            <strong>
              {`${totalFileErrors} ${totalFileErrors > 1 ? i18n.t('problems') : i18n.t('problem')} `}
            </strong>
            {`${i18n.t('on sent file.')} ${i18n.t('Make the necessary adjustments and submit again the file, please')}`}
          </div>

          {fileErrorTypes.map(error => (
            <FileError
              key={error.title}
              title={error.title}
              errors={error.errors}
              className={error.className}
            />
          ))}
        </section>
      )}
      {totalLineErrors > 0 && (
        <section id="errors-lines" className="error-group">
          <div className="error-text">
            {totalLineErrors > 1 ? i18n.t('Were found') : i18n.t('Was found', { context: 'female' })}
            {' '}
            <strong>
              {totalLineErrors}
              {` ${totalLineErrors > 1 ? i18n.t('problems') : i18n.t('problem')} `}
            </strong>
            {`${i18n.t('on sent file.')} ${i18n.t('Make the necessary adjustments and submit again the file, please')}`}
          </div>
          {lineErrorTypes.map(error => (
            <ExpansionError
              key={error.title}
              title={error.title}
              lines={error.lines}
              className={error.className}
              defaultExpanded={defaultExpanded}
              disableExpansionPanel={disableExpansionPanels}
            />
          ))}
        </section>
      )}
    </div>)
}
MassiveCreateSellersAccountsErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    field: PropTypes.string.isRequired,
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    message: PropTypes.string
  })),
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
  disableExpansionPanels: PropTypes.bool
}

MassiveCreateSellersAccountsErrors.defaultProps = {
  defaultExpanded: false,
  className: '',
  disableExpansionPanels: false
}

export default MassiveCreateSellersAccountsErrors